<template>
  <div v-show="dynamicLinks.length" class="dynamicLinkArea">
    <textButtonCarousel
      class="dynamicLinkItem"
      v-for="(list, key) in dynamicLinks"
      :title="list.title"
      :textBunttonList="list.links"
      :carouselId="list.id"
      @btnclick="btnClickHandler"
      :btnStyle="btnStyle()"
      :key="`link-carousel-${key}`"
    />
  </div>
</template>

<script>
import textButtonCarousel from '@components/buttons/textButtonCarousel.vue';
import { mapState } from 'vuex';
import { facetMetaData } from '@util/facetHelper.js';

export default {
  name: 'DynamicLinks',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mmtFacet: {},
      expandedMake: null,
      expandedModel: null,
      dynamicLinks: [],
    };
  },
  components: {
    textButtonCarousel,
  },
  created() {
    this.buildDynamicLinks();
  },
  watch: {
    vehicleData(n, o) {
      if (n) {
        this.buildDynamicLinks();
      }
    },
  },
  computed: {
    ...mapState('srp', {
      filterSelected: (state) => state.filterSelected,
      bodyStyleFilterSelected: (state) => state.bodyStyleFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      facetFilters: (state) => state.facetFilters,
    }),
  },
  methods: {
    buildDynamicLinks() {
      const appState = this.$jss?.sitecoreContext();
      if (!appState) return;

      const baseUrl = appState.canonicalUrl?.split(appState.itemPath)[0];
      const srpUrl = `${baseUrl}${appState?.itemPath}`;
      const isSeoItem = appState?.seoItem ? true : false;
      const seoMetaTitle = appState?.locationDisplayName;
      const facetFilters = this.$jss?.sitecoreContext()?.appliedFacetFilters?.facetFilters;

      if (this.vehicleData && facetFilters) {
        this.dynamicLinks = [];

        this.TryAddDynamicLinksForNoFacetsSelectedScenario(
          facetFilters,
          srpUrl,
          isSeoItem,
          seoMetaTitle
        );
        this.TryAddDynamicLinksForMakeSelectedScenario(
          facetFilters,
          srpUrl,
          isSeoItem,
          seoMetaTitle
        );
        this.TryAddDynamicLinksForBodyStyleSelectedScenario(
          facetFilters,
          srpUrl,
          isSeoItem,
          seoMetaTitle
        );
      }
    },
    TryAddDynamicLinksForNoFacetsSelectedScenario(facetFilters, srpUrl, isSeoItem, seoMetaTitle) {
      if (!facetFilters || Object.keys(facetFilters).length === 0) {
        if (
          this.fields?.Type?.value === 'make' &&
          this.vehicleData?.facets?.[facetMetaData.mmt.key] &&
          Object.keys(this.vehicleData?.facets?.[facetMetaData.mmt.key] ?? {}).length > 0
        ) {
          this.dynamicLinks.push({
            id: facetMetaData.mmt.key,
            title:
              isSeoItem && seoMetaTitle
                ? this.fields?.SeoTitle?.value
                    .replace('##seoTitle##', seoMetaTitle)
                    .replace('##bodyStyle##', 'Cars')
                : this.fields?.DefaultTitle?.value.replace('##bodyStyle##', 'Cars'),
            links: Object.keys(this.vehicleData.facets[facetMetaData.mmt.key]).map((x) => {
              return {
                text:
                  isSeoItem && seoMetaTitle
                    ? this.fields?.SeoLinkText?.value
                        .replace('##seoTitle##', seoMetaTitle)
                        .replace('##make##', `${x}s`)
                    : this.fields?.DefaultLinkText?.value.replace('##make##', `${x}s`),
                link: `${srpUrl}/${x.replace(' ', '-')}`,
              };
            }),
          });
        }

        if (
          this.fields?.Type?.value == 'bodystyle' &&
          this.vehicleData.facets[facetMetaData.bodystyle.key]
        ) {
          this.dynamicLinks.push({
            id: facetMetaData.bodystyle.key,
            title:
              isSeoItem && seoMetaTitle
                ? this.fields?.SeoTitle?.value
                    .replace('##seoTitle##', seoMetaTitle)
                    .replace('##make##', 'Cars')
                : this.fields?.DefaultTitle?.value.replace('##make##', 'Cars'),
            links: Object.keys(this.vehicleData.facets[facetMetaData.bodystyle.key])
              .filter((x) => this.vehicleData.facets[facetMetaData.bodystyle.key][x] > 0)
              .map((x) => {
                return {
                  text:
                    isSeoItem && seoMetaTitle
                      ? this.fields?.SeoLinkText?.value
                          .replace('##seoTitle##', seoMetaTitle)
                          .replace('##bodyStyle##', x)
                      : this.fields?.DefaultLinkText?.value.replace('##bodyStyle##', x),
                  link: `${srpUrl}/${x.replace(' / ', '-').replace(' ', '-')}`,
                };
              }),
          });
        }
      }
    },
    TryAddDynamicLinksForMakeSelectedScenario(facetFilters, srpUrl, isSeoItem, seoMetaTitle) {
      if (
        Object.keys(facetFilters).length === 1 &&
        facetFilters[facetMetaData.mmt.key] &&
        Object.keys(facetFilters[facetMetaData.mmt.key].facetValue).length === 1
      ) {
        let selectedMake = Object.keys(facetFilters[facetMetaData.mmt.key].facetValue)[0];
        if (Object.keys(facetFilters[facetMetaData.mmt.key].facetValue[selectedMake]).length > 1)
          return;

        if (
          this.fields?.Type?.value === 'model' &&
          this.vehicleData.facets[facetMetaData.model.key] &&
          Object.keys(this.vehicleData.facets[facetMetaData.model.key]).length > 0
        ) {
          this.dynamicLinks.push({
            id: facetMetaData.model.key,
            title:
              isSeoItem && seoMetaTitle
                ? this.fields?.SeoTitle?.value
                    .replace('##seoTitle##', seoMetaTitle)
                    .replace('##make##', selectedMake)
                : this.fields?.DefaultTitle?.value.replace('##make##', selectedMake),
            links: Object.keys(this.vehicleData.facets[facetMetaData.model.key])
              .filter((x) => this.vehicleData.facets[facetMetaData.model.key][x] > 0)
              .map((x) => {
                return {
                  text:
                    isSeoItem && seoMetaTitle
                      ? this.fields?.SeoLinkText?.value
                          .replace('##seoTitle##', seoMetaTitle)
                          .replace('##model##', x)
                      : this.fields?.DefaultLinkText?.value.replace('##model##', x),
                  link: `${srpUrl}/${selectedMake.replace(' ', '-')}/${x}`,
                };
              }),
          });
        }

        if (
          this.fields?.Type?.value === 'bodystyle' &&
          this.vehicleData.facets[facetMetaData.bodystyle.key] &&
          Object.values(this.vehicleData.facets[facetMetaData.bodystyle.key]).some((x) => x > 0)
        ) {
          this.dynamicLinks.push({
            id: facetMetaData.bodystyle.key,
            title:
              isSeoItem && seoMetaTitle
                ? this.fields?.SeoTitle?.value
                    .replace('##seoTitle##', seoMetaTitle)
                    .replace('##make##', `${selectedMake}s`)
                : this.fields?.DefaultTitle?.value.replace('##make##', `${selectedMake}s`),
            links: Object.keys(this.vehicleData.facets[facetMetaData.bodystyle.key])
              .filter((x) => this.vehicleData.facets[facetMetaData.bodystyle.key][x] > 0)
              .map((x) => {
                return {
                  text:
                    isSeoItem && seoMetaTitle
                      ? this.fields?.SeoLinkText?.value
                          .replace('##seoTitle##', seoMetaTitle)
                          .replace('##bodyStyle##', `${selectedMake} ${x}`)
                      : this.fields?.DefaultLinkText?.value.replace(
                          '##bodyStyle##',
                          `${selectedMake} ${x}`
                        ),
                  link: `${srpUrl}/${selectedMake.replace(' ', '-')}/${x
                    .replace(' / ', '-')
                    .replace(' ', '-')}`,
                };
              }),
          });
        }
      }
    },
    TryAddDynamicLinksForBodyStyleSelectedScenario(facetFilters, srpUrl, isSeoItem, seoMetaTitle) {
      if (
        Object.keys(facetFilters).length === 1 &&
        facetFilters[facetMetaData.bodystyle.key]?.facetValue?.length === 1
      ) {
        let selectedBodyStyle = facetFilters[facetMetaData.bodystyle.key].facetValue[0];

        if (this.fields?.Type?.value === 'make' && this.vehicleData.facets[facetMetaData.mmt.key]) {
          this.dynamicLinks.push({
            id: facetMetaData.mmt.key,
            title:
              isSeoItem && seoMetaTitle
                ? this.fields?.SeoTitle?.value
                    .replace('##seoTitle##', seoMetaTitle)
                    .replace('##bodyStyle##', `${selectedBodyStyle}s`)
                : this.fields?.DefaultTitle?.value.replace(
                    '##bodyStyle##',
                    `${selectedBodyStyle}s`
                  ),
            links: Object.keys(this.vehicleData.facets[facetMetaData.mmt.key]).map((x) => {
              return {
                text:
                  isSeoItem && seoMetaTitle
                    ? this.fields?.SeoLinkText?.value
                        .replace('##seoTitle##', seoMetaTitle)
                        .replace('##make##', `${x} ${selectedBodyStyle}s`)
                    : this.fields?.DefaultLinkText?.value.replace(
                        '##make##',
                        `${x} ${selectedBodyStyle}s`
                      ),
                link: `${srpUrl}/${selectedBodyStyle
                  .replace(' / ', '-')
                  .replace(' ', '-')}/${x.replace(' ', '-')}`,
              };
            }),
          });
        }
      }
    },
    btnClickHandler(link, facet) {
      if (facet == facetMetaData.mmt.key) {
        let mmtFilters = link.split('|');
        this.$set(this.mmtFacet, mmtFilters[0], { selected: true }); //sets make

        if (mmtFilters[1]) {
          this.expandedMake = mmtFilters[0];
          this.$set(this.mmtFacet[this.expandedMake], mmtFilters[1], { selected: true }); // sets model
        }

        if (mmtFilters[2]) {
          this.expandedModel = mmtFilters[1];
          this.$set(this.mmtFacet[this.expandedMake][this.expandedModel], mmtFilters[2], {
            selected: true,
          }); // sets trim
        }

        const data = {
          name: facet,
          value: this.mmtFacet,
        };
        this.$store.commit('srp/setHierarchicalFacetFilter', data);
      } else {
        const data = {
          name: facet,
          value: [link],
        };
        this.$store.commit('srp/setStringFacetFilter', data);
      }

      this.$root.$emit('filter-updated-srp');
    },
    btnStyle() {
      if (typeof window !== 'undefined' && window.innerWidth > 992) return '';
      return 'small';
    },
  },
};
</script>

<style scoped>
.dynamicLinkArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: unset;
  gap: 40px;
  padding-top: 26px;
  padding: 14px 24px 10px 24px;
  max-width: 100vw !important;
}

@media only screen and (min-width: 1240px) {
  .dynamicLinkArea {
    max-width: calc(100vw - 306px) !important;
  }
}
.textButtonCarousel-container {
  overflow: hidden !important;
}

.dynamicLinkItem {
  width: 100%;
}
</style>
