<template>
  <!-- note .collapsed-container is unused -->
  <div
    class="filter-chip-container"
    ref="divFilterChipContainer"
    :style="cssVars"
    :class="{ 'collapsed-container': !isExpanded && isMobile }"
  >
    <FilterChipItem
      id="btnDistanceShipping"
      ref="btnDistanceShipping"
      @click="setFilter('storename')"
      class="button my-1"
      :isCloseable="false"
    >
      <template #value>
        <span class="location-chip-zip" tabindex="0">
          <img id="imgLocationIcon" class="mr-1" src="@icons/locationIcon.svg" />
          <b>{{ zip }}</b>
        </span>
        <span>|</span>
        <span
          id="lblRadius"
          v-if="!shopByStore && radius !== -1"
          class="location-chip-radius"
          tabindex="0"
          >{{ replaceText($t('Cars Within Miles'), ';xMiles;', radius) }}</span
        >
        <span v-if="!shopByStore && radius === -1" class="location-chip-radius" tabindex="0">{{
          $t('Nationwide')
        }}</span>
        <span v-if="shopByStore" class="location-chip-radius" tabindex="0">{{
          $t('Distance Shipping Toggle Text Right')
        }}</span>
      </template>
    </FilterChipItem>

    <FilterChipItem
      v-if="shipToStore && $t('Ship To Store Toggle Feature Flag') == 1"
      @click="setFilter('shiptostore')"
      @close="closeShipToStore()"
      id="btnShipToStore"
      class="button filterButton nonDistanceShipButton my-1 flex-container"
      :value="$t('Ship to store')"
    />

    <FilterChipItem
      v-for="(item, index) in orderedStoreNames"
      @click="setFilter('storename')"
      @close="removeFromIncludedDealerships(item)"
      class="button filterButton nonDistanceShipButton my-1 flex-container"
      :value="item"
    />

    <template v-for="(item, facetType) in orderedFacets">
      <FilterChipItem
        v-for="value in item.facetValue"
        v-show="item.type == 'string' && (showButton(`btn${facetType}${value}`) || isExpanded)"
        :id="`btn${facetType}${value}`"
        style="display: none"
        @click="setFilter(facetType)"
        @close="closeStringFacet(facetType, value)"
        class="button filterButton nonDistanceShipButton my-1 flex-container"
        :class="{ btnRemove: item.type != 'string' }"
      >
        <template #value>
          <StringData
            v-if="facetMetaData[facetType]"
            class="btnText"
            tabindex="0"
            :item="value"
            :postfix="facetMetaData[facetType].postfix"
          ></StringData>
        </template>
      </FilterChipItem>

      <FilterChipItem
        v-show="item.type == 'numericRange' && (showButton(`btn${facetType}`) || isExpanded)"
        :id="`btn${facetType}`"
        :class="{ btnRemove: item.type != 'numericRange' }"
        @close="closeNumericFacet(facetType)"
        @click="setFilter(facetType)"
        class="button filterButton nonDistanceShipButton my-1 flex-container"
      >
        <template #value>
          <RangeData
            class="btnText"
            tabindex="0"
            v-if="facetMetaData[facetType]"
            :max="facetType === 'year' ? item.max : Number(item.max).toLocaleString()"
            :min="facetType === 'year' ? item.min : Number(item.min).toLocaleString()"
            :prefix="facetMetaData[facetType].prefix"
            :postfix="facetMetaData[facetType].postfix"
          ></RangeData>
        </template>
      </FilterChipItem>

      <FilterChipItem
        v-show="
          item.type == 'hierarchical' && (showButton(`btn${facetType}${value}`) || isExpanded)
        "
        v-for="value in filteredHierarchicalFacets"
        :id="`btn${facetType}${value}`"
        @close="closeHierarchicalFacet(value)"
        @click="setFilter(facetType)"
        class="button filterButton nonDistanceShipButton my-1 flex-container"
        :value="value"
      />
    </template>
    <!-- note .mobile-only below likely can be removed b/c it doesn't reference anything -->

    <FilterChipItem
      v-show="isMobile && hiddenChips > 0"
      id="btnExpand"
      @click="expandButtons"
      class="button flex-container expandButton mobile-only nonDistanceShipButton my-1"
      :class="{ 'show-less': isExpanded }"
      :isCloseable="false"
    >
      <template #value>
        <span v-show="!isExpanded">+{{ hiddenChips }}</span>
        <span v-show="isExpanded"> {{ $t('Show less') }} <ChevronDownIconBlue /> </span>
      </template>
    </FilterChipItem>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import * as facetHelper from '@util/facetHelper.js';
import RangeData from '@components-vue/FilterChip/RangeData.vue';
import StringData from '@components-vue/FilterChip/StringData.vue';
import FilterChipItem from '@components-vue/FilterChip/FilterChipItem.vue';
import ChevronDownIconBlue from '@assets/icons/ChevronDownIcon/ChevronDownIconBlue';
import { resolveUrl } from '@util/routeBuilder.js';

export default {
  name: 'FilterChip',
  props: {
    hasBorderBottom: { type: Boolean, default: true },
    isInMobileFilterMenu: { type: Boolean, default: true },
    isFilterMenuOpen: { type: Boolean, default: false },
    fields: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      buttons: {},
      hiddenChips: 0,
      isMobile: false,
      isOverflow: false,
      sortOrder: facetHelper.filterChipSortOrder,
      facetMetaData: facetHelper.facetMetaData,
    };
  },
  components: { ChevronDownIconBlue, RangeData, StringData, FilterChipItem },
  computed: {
    ...mapState('srp', {
      facetFilters: (state) => state.facetFilters ?? {},
      selectedFacetFilters: (state) => state.selectedFacetFilters,
      shipToStore: (state) => state.shipToStore,
      shopByStore: (state) => state.shopByStore,
      radius: (state) => state.radius,
      zip: (state) => state.zip,
      isExpanded: (state) => state.facetChipsExpanded,
      includedDealerships: (state) => state.includedDealerships,
      dealerships: (state) => state.dealerships,
      queryParams: (state) => state.queryParams,
      sortType: (state) => state.sortType,
    }),
    layoutPath() {
      return this.$jss?.sitecoreContext()?.layoutPath;
    },
    orderedFacets() {
      return facetHelper.orderedFacets(this.facetFilters, this.sortOrder);
    },
    filteredStoreNames() {
      return (
        this.dealerships
          ?.filter((dealer) => this.includedDealerships?.includes(dealer.dealerId))
          .map((dealer) => dealer.storeName) || []
      );
    },
    orderedStoreNames() {
      return this.filteredStoreNames.sort();
    },
    hierarchicalFacets() {
      const hierarchicalFacets = Object.fromEntries(
        Object.entries(this.facetFilters).filter(([key, value]) => value.type === 'hierarchical')
      );
      return hierarchicalFacets;
    },
    filteredHierarchicalFacets() {
      return facetHelper.getMmtKeys(this.hierarchicalFacets);
    },
    cssVars() {
      return {
        '--border-bottom': this.hasBorderBottom ? '1px solid #E8E9EB' : 'unset',
        '--display-show-less': this.isInMobileFilterMenu ? 'block' : 'none',
      };
    },
    showButton() {
      return (key) =>
        !this.isMobile || (this.buttons.hasOwnProperty(key) ? this.buttons[key] : false);
    },
  },
  methods: {
    ...mapMutations('srp', [
      'setStringFacetFilter',
      'setNumericFacetFilter',
      'setRemoveFacet',
      'setShipToStore',
      'setSelectedFacetItems',
      'setHierarchicalFacetFilter',
      'setFacetChipsExpanded',
      'setHiddenChipCount',
      'setSpecificFilterSelected',
      'setFilterSelected',
      'setIncludedDealerships',
      'setRecentSelectedFilter',
    ]),
    closeStringFacet(filterType, filterValue) {
      this.removeFromFacets(filterType, filterValue);
      this.removeFromSelectedFacets(filterType, filterValue);
      this.setRecentSelectedFilter(filterType);
      this.$root.$emit('filter-updated-srp');
    },
    removeFromIncludedDealerships(storeName) {
      let dealerId = ((storeName) =>
        (this.dealerships.find((dealer) => dealer.storeName === storeName) || {}).dealerId)(
        storeName
      );
      let selectedDealerships = this.includedDealerships;
      if (selectedDealerships && selectedDealerships.includes(dealerId)) {
        const indexToRemove = selectedDealerships.indexOf(dealerId);
        if (indexToRemove !== -1) {
          selectedDealerships.splice(indexToRemove, 1);
        }
      }
      this.setIncludedDealerships(selectedDealerships);
      if (this.layoutPath) {
        const params = {
          facetFilters: this.facetFilters,
          queryParams: this.queryParams,
          includedDealerships: this.includedDealerships,
          ship: this.shipToStore,
          sortType: this.sortType,
          itemPath: this.layoutPath, // set itempath to layout path on reroute
          layoutPath: null,
          radius: this.radius,
          shopByStore: this.shopByStore,
          root: this.$root,
        };

        resolveUrl(this.$router, params);
        window.location.reload();
      }

      if (selectedDealerships.length === 0) {
        // If unselecting the last dealership, we want to switch back "shop by radius"
        this.$store.commit('srp/setShopByStore', false);
      } else {
        // There is a "watch" on shopByStore in ShopByStoreToggle that emits filter-updated-srp - must be emitted explicitly here
        this.$root.$emit('filter-updated-srp');
      }
    },
    removeFromFacets(filterType, filterValue) {
      let items = this.facetFilters[filterType].facetValue || [];
      if (items && items.includes(filterValue)) {
        const indexToRemove = items.indexOf(filterValue);
        if (indexToRemove !== -1) {
          items.splice(indexToRemove, 1);
        }
      }
      if (items.length === 0) {
        this.setRemoveFacet(filterType);
      } else {
        const data = {
          name: filterType,
          value: items,
        };
        this.setStringFacetFilter(data);
      }
    },
    removeFromSelectedFacets(filterType, filterValue) {
      let items = this.selectedFacetFilters[filterType] || [];
      if (items && items.includes(filterValue)) {
        const indexToRemove = items.indexOf(filterValue);
        if (indexToRemove !== -1) {
          items.splice(indexToRemove, 1);
        }
      }
      const data = {
        name: filterType,
        value: items,
      };
      this.setSelectedFacetItems(data);
    },
    closeNumericFacet(filterType) {
      this.setRemoveFacet(filterType);
      this.setRecentSelectedFilter(filterType);
      this.$root.$emit('filter-updated-srp');
    },
    closeShipToStore() {
      this.setShipToStore(false);
      this.$root.$emit('filter-updated-srp');
    },
    closeHierarchicalFacet(filterValue) {
      let items = this.facetFilters[this.facetMetaData.mmt.key].facetValue || [];

      for (const make in items) {
        if (filterValue === make) {
          delete items[make];
        }
        const models = items[make];
        for (const model in models) {
          if (filterValue === model) {
            delete models[model];
          }
          items[make] = models;
          const trims = models[model];
          for (const trim in trims) {
            if (filterValue === trim) {
              delete trims[trim];
            }
            models[model] = trims;
            items[make] = models;
          }
        }
      }

      if (Object.keys(items).length === 0) {
        this.setRemoveFacet(this.facetMetaData.mmt.key);
      } else {
        const data = {
          name: this.facetMetaData.mmt.key,
          value: items,
        };
        this.setHierarchicalFacetFilter(data);
      }

      this.setRecentSelectedFilter(facetHelper.facetMetaData.mmt.key);
      this.$root.$emit('filter-updated-srp');
    },
    updateDimensions() {
      if (!this.isExpanded) {
        this.clearButtons();
        let btnExpandWidth = 46;
        let btnPadding = 12;
        let totalAvailableWidth = this.getFilterContainerWidth() - btnExpandWidth;
        let takenWidth = 0;
        let container = this.$refs.divFilterChipContainer;

        let childElements = container.getElementsByClassName('button');
        let btnRemove = container.getElementsByClassName('btnRemove');
        let btnsToRemove = 0;

        for (let i = 0; i < childElements.length; i++) {
          let element = childElements[i];
          if (element.id === 'btnShipToStore' && !this.shipToStore) {
            btnsToRemove++;
            continue;
          }

          let classes = Array.from(element.classList);
          if (classes.includes('btnRemove')) {
            continue;
          }

          let elementCss = getComputedStyle(element);
          let elementWidth = element.offsetWidth + btnPadding;
          if (elementCss.display === 'none') {
            element.style.display = 'flex';
            elementWidth = element.offsetWidth + btnPadding;
            element.style.display = 'none';
          }

          if (elementWidth > totalAvailableWidth - takenWidth) {
            this.$set(this.buttons, 'btnExpand', true);
            break;
          } else {
            takenWidth += elementWidth;
            btnsToRemove++;
            this.$set(this.buttons, element.id, true);
          }

          let btnExpandCount = 1;
          let totalButtonsToRemove = btnsToRemove + btnRemove.length + btnExpandCount;
          this.hiddenChips = childElements.length - totalButtonsToRemove;
          this.setHiddenChipCount(this.hiddenChips);
        }
      }
    },
    clearButtons() {
      Object.keys(this.buttons).forEach((key) => {
        this.$delete(this.buttons, key);
      });
    },
    getFilterContainerWidth() {
      const container = this.$refs.divFilterChipContainer;
      const containerCss = window.getComputedStyle(container);

      let totalWidth = container.offsetWidth * 2;
      let paddingLeft = parseFloat(containerCss.paddingLeft);
      let paddingRight = parseFloat(containerCss.paddingRight);
      let totalPadding = (paddingLeft + paddingRight) * 2;
      let totalWidthMinusPadding = totalWidth - totalPadding;

      return totalWidthMinusPadding;
    },
    handleResize() {
      this.checkIsMobile();
      this.updateDimensions();
    },
    expandButtons() {
      if (!this.isExpanded) {
        let container = this.$refs.divFilterChipContainer;
        let childElements = container.getElementsByClassName('button');

        for (let i = 0; i < childElements.length; i++) {
          this.$set(this.buttons, childElements[i].id, true);
        }
        this.setFacetChipsExpanded(true);
      } else {
        this.setFacetChipsExpanded(false);
        this.updateDimensions();
      }
    },
    checkIsMobile() {
      if (typeof window !== 'undefined') {
        this.isMobile = window.innerWidth < 1240;
      }
    },
    setFilter(index) {
      this.setFilterSelected(false);
      this.setSpecificFilterSelected(index);

      if (this.isMobile && !this.isFilterMenuOpen) {
        this.$root.$emit('toggle-filter-menu');
      }
    },
    replaceText(text, search, replacement) {
      return text.replace(search, replacement);
    },
  },
  mounted() {
    this.checkIsMobile();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    facetFilters: {
      handler: function () {
        setTimeout(() => {
          if (this.isMobile) {
            this.updateDimensions();
          }
        }, 500);
      },
      deep: true,
    },
    shipToStore: function () {
      this.updateDimensions();
    },
    isExpanded: function () {
      this.updateDimensions();
    },
  },
};
</script>
<style scoped>
#imgLocationIcon {
  width: 13.25px;
  height: 16px;
}

.location-chip-zip {
  margin-left: -1px;
  padding: 10px 7px 9px 10px;
  border-radius: 36px;
}

.location-chip-zip:active {
  background: #047fbd;
}

.location-chip-zip:focus {
  background: #047fbd;
}

.location-chip-zip:focus,
.location-chip-zip:focus-visible,
.location-chip-radius:focus,
.location-chip-radius:focus-visible {
  outline-offset: -3px !important;
}

.location-chip-zip:hover {
  background: #0084c7;
}

.location-chip-radius {
  padding: 10px 10px 9px 7px;
  margin-right: -1px;
  border-radius: 0 18px 18px 0;
}

.location-chip-radius:active,
.location-chip-radius:focus,
.location-chip-radius:hover {
  text-decoration: underline;
}

#btnDistanceShipping {
  background: linear-gradient(0deg, #006fa6, #006fa6), linear-gradient(0deg, #006ea6, #006ea6);
  border: 1px solid #006ea6;
  width: auto;
  height: 36px;
  border-radius: 36px;
  display: inline;
  color: #ffffff;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  text-wrap: nowrap;
}

@media (min-width: 364px) and (max-width: 991px) {
  #btnDistanceShipping {
    padding-top: 1px;
  }
}

.btnText {
  padding: 7px 0;
  margin: 7px 4px 7px 12px;
}

.btnText:active,
.btnText:focus,
.btnText:hover {
  text-decoration: underline;
}

.btnText:focus-visible {
  outline: none;
}

.filterButton {
  background: linear-gradient(0deg, #e6f1f5, #e6f1f5), linear-gradient(0deg, #006ea6, #006ea6);
  padding: 0;
}

.filterButton:last-of-type {
  margin-right: 16px;
}

.expandButton {
  background: white;
  padding: 12px;
}

.expandButton.show-less {
  font-weight: 700;
  border: none;
  line-height: 1;
  display: var(--display-show-less);
}

.expandButton.show-less svg {
  transform: rotate(180deg);
}

.nonDistanceShipButton {
  line-height: 9px;
}

.button {
  width: auto;
  height: 36px;
  border-radius: 36px;
  border: 1px solid #006ea6;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  color: #006fa6;
  margin-right: 12px;
  vertical-align: middle;
}

.flex-container {
  display: flex;
  align-items: center;
}

.filter-chip-container {
  order: 3;
  padding: 0 24px;
  width: 100%;
  background: #fff;
  height: auto !important;
  border-bottom: var(--border-bottom);
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
  position: sticky;
  top: 52px;
}

.filter-chip-desktop .filter-chip-container {
  padding: 14px 24px;
}

/* Large devices (laptops/desktops, 1240px and up) */
@media only screen and (min-width: 1240px) {
  .filter-chip-container {
    order: 1;
    height: 76px;
    top: 0;
  }
}
</style>
