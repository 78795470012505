import VehicleSearchClient from '../clients/vehicleSearchClient';
import DealershipSearchClient from '../clients/dealershipSearchClient';
import CountSearchClient from '../clients/countSearchClient';
import conf from '../appConfig';
import axios from 'axios';

async function executeClientRequest(client, setDataCallback, setLoading) {
  let data = null;
  setLoading(true);
  try {
    const response = await client.execute();
    data = response.data;
    setDataCallback(data);
  } catch (error) {
    console.error(error);
  }
  setLoading(false);
}

function addCommonClientParameters(client, context) {
  const { zip, shopByStore, includedDealerships, shipToStore, radius } = context;

  client.addZip(zip);

  if (shopByStore) {
    client.addDealers(includedDealerships);
    client.setShippable(shipToStore);
  } else {
    client.addRadius(radius);
    client.setShippable(shipToStore ?? true);
  }
}

export async function fetchSrpVehicles(context) {
  const { facetFilters, take, page, sortType, pageTitle, pageDescription } = context;
  const client = VehicleSearchClient(axios, conf.vehicleApiEndpoint);

  Object.keys(facetFilters).forEach((facetFilter) => {
    client.addFacetFilter(facetFilter, facetFilters[facetFilter]);
  });

  client.setPageSize(take);
  client.setPageNumber(page);

  addCommonClientParameters(client, context);

  if (sortType.sortBy !== null && sortType.sortDirection !== null) {
    client.setSortBy(sortType.sortBy);
    client.setSortDirection(sortType.sortDirection);
  }

  await executeClientRequest(
    client,
    (data) => {
      if (data) {
        context.setSrpVehiclesData(data);
      }
    },
    context.setLoading
  );

  context.$root.$emit('header-data-changed', {
    title: pageTitle,
    description: pageDescription,
  });
}

export async function fetchSrpDealerships(context) {
  const { zip } = context;
  const client = DealershipSearchClient(axios, conf.dealershipApiEndpoint, zip);

  await executeClientRequest(
    client,
    (data) => {
      if (data?.items) {
        context.setDealerships(data.items);
        if (data.items[0]) {
          context.setSrpNearestDealership(data.items[0]);
        }
      }
    },
    context.setLoading
  );
}

export async function fetchFacetCounts(context, facetToRemove) {
  const { facetFilters } = context;
  const client = CountSearchClient(axios, conf.countApiEndpoint);

  Object.keys(facetFilters).forEach((facetFilter) => {
    if (facetFilter !== facetToRemove) {
      client.addFacetFilter(facetFilter, facetFilters[facetFilter]);
    }
  });

  addCommonClientParameters(client, context);

  await executeClientRequest(
    client,
    (data) => {
      if (data?.facets) {
        context.setFacetCounts(data.facets);
      }
    },
    context.setLoading
  );
}

export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) return true;
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }
  if (Array.isArray(obj1) !== Array.isArray(obj2)) return false;
  if (obj1 instanceof RegExp && obj2 instanceof RegExp) {
    return obj1.toString() === obj2.toString();
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function arraysEqualShallow(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}
