<template>
  <div class="vehicle-grid-container">
    <AskUsModal :isOpen="isAskAboutCarOpen" :selectedVin="selectedVin" />
    <div v-if="showLoading" class="vehicleSearchResultsGrid">
      <SkeletonLoading v-for="index in 24" :key="index" />
    </div>
    <div v-else class="vehicleSearchResultsGrid">
      <template v-for="(item, index) in vehiclesWithPlaceholder">
        <sc-placeholder
          v-if="item.isGridPlaceholder"
          :isGrid="true"
          name="ctaBanner"
          :rendering="rendering"
        />
        <sc-placeholder
          v-if="item.isRowPlaceholder"
          name="ctaBanner"
          :rendering="rendering"
          class="row-placeholder"
        />
        <VehicleCard
          v-else-if="item.vehicle"
          :data-bodystyle="item.vehicle.bodystyle"
          :vehicle="item.vehicle"
          :isCarFavorite="getFavoriteCarStatus(item.vehicle.vin)"
          :shippableTitle="$t('CanShipCallOut')"
          :shippableDetails="$t('ShippingStoreDetails')"
          :pickUpTodayTitle="$t('PickupCallOut')"
          :pickUpOnlyTitle="$t('PickUpOnlyAt')"
          :pickUpDetails="$t('PickUpStoreDetails')"
          :showPaymentEstimates="paymentEstimates"
        />
      </template>
    </div>
  </div>
</template>

<script>
import VehicleCard from "./VehicleCard/VehicleCard";
import SkeletonLoading from "./SkeletonLoading/SkeletonLoading";
import { Text } from "@sitecore-jss/sitecore-jss-vue";
import eventHub from "@store/eventHub";
import favoriteService from "@services/favoriteService";
import AskUsModal from "@components/Modals/AskUsModal";
import Cookies from "js-cookie";
import NoResultsScreen from "./NoResultsScreen/NoResultsScreen";
import textButtonCarousel from "@components/buttons/textButtonCarousel";
import eventBus from "@assets/js/eventBus";
import { mapState, mapMutations } from "vuex";
import { Placeholder } from "@sitecore-jss/sitecore-jss-vue";

export default {
  name: "VehicleSearchResultsGrid",
  components: {
    VehicleCard,
    SkeletonLoading,
    ScText: Text,
    AskUsModal,
    NoResultsScreen,
    textButtonCarousel,
    ScPlaceholder: Placeholder,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      vehicleListFavorites: [],
      isAskAboutCarOpen: false,
      selectedVin: [],
      hasMounted: false,
      itemsPerRow: 4,
    };
  },
  computed: {
    ...mapState("srp", [
      "queryParams",
      "vehiclesByPage",
      "vehicleAmount",
      "srpVehiclesData",
      "loading",
      "zip",
    ]),
    gridPosition() {
      return this.params?.GridPosition;
    },
    rowPosition() {
      return this.params?.RowPosition;
    },
    vehicles() {
      return this.srpVehiclesData?.items ?? [];
    },
    showLoading() {
      return this.hasMounted && this.loading;
    },
    paymentEstimates() {
      return this.fields.showPriceEstimates?.value ?? false;
    },
    vehiclesWithPlaceholder() {
      const vehicles = this.vehicles.map((vehicle) => ({
        key: vehicle.vin,
        vehicle,
        isRowPlaceholder: false,
        isGridPlaceholder: false,
      }));

      const gridPosition = this.gridPosition ?? -1;
      const rowPosition = this.rowPosition ?? -1;

      if (gridPosition >= 0 && vehicles.length > 0) {
        vehicles.splice(gridPosition, 0, {
          key: `placeholder-grid-${gridPosition}`,
          isRowPlaceholder: false,
          isGridPlaceholder: true,
        });
      }

      const rowIndex = rowPosition >= 0 ? rowPosition * this.itemsPerRow : -1;
      if (rowIndex >= 0 && vehicles.length > 0) {
        vehicles.splice(rowIndex, 0, {
          key: `placeholder-row-${rowIndex}`,
          vehicle: vehicles[rowIndex]?.vehicle ?? null,
          isRowPlaceholder: true,
          isGridPlaceholder: false,
        });
      }

      return vehicles;
    },
  },
  methods: {
    ...mapMutations("srp", ["setVehicleAmout"]),
    getFavoriteCarStatus(vin) {
      return this.vehicleListFavorites.some((vehicle) => vehicle.ProductId === vin);
    },
    async loadVehicleListFavorites() {
      const response = await favoriteService.getFavoritesLines();
      this.vehicleListFavorites = response?.Lines ?? [];
    },
    updateItemsPerRow() {
      /* itemsPerRow is hardcoded to match hardcoded
      grid column widths defined in the @media for 
      responsive sizing, np 6/21/2024*/
      if (typeof window !== "undefined") {
        const width = window.innerWidth;
        if (width <= 328) {
          this.itemsPerRow = 1;
        } else if (width <= 625) {
          this.itemsPerRow = 1;
        } else if (width <= 980) {
          this.itemsPerRow = 2;
        } else if (width <= 1535) {
          this.itemsPerRow = 3;
        } else {
          this.itemsPerRow = 4;
        }
      }
    },
  },
  async mounted() {
    this.hasMounted = true;
    this.updateItemsPerRow();
    window.addEventListener("resize", this.updateItemsPerRow);
    await this.loadVehicleListFavorites();

    eventHub.$on("openAskAboutUsModal", (payload) => {
      this.selectedVin = payload.vin;
      this.isAskAboutCarOpen = payload.isOpen;
      Cookies.set("ep_selectedZipcode", this.zip);
    });

    let pageType = "";
    let pageSubType = "";

    if (typeof window !== "undefined") {
      const url = window.location.pathname.split("/").filter(Boolean);
      pageType = url.length > 0 ? `/${url[0]}` : "";
      pageSubType = url.length > 1 ? `/${url.slice(1).join("/")}` : "";
    }

    const obj = {
      event: "page load",
      LoggedinID: $("#hdIsLoggedInID").val(),
      Pagetype: pageType,
      "Page subtype": pageSubType,
      Location: $("#hdndefaultLocationName").val(),
      zip: $("#ZipcodeInformationHeader").text(),
    };
    eventBus.$emit("event", obj);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateItemsPerRow);
  },
};
</script>

<style scoped>
.vehicle-grid-container {
  order: 5;
  width: 100%;
  block-size: fit-content;
}

.vehicleSearchResultsGrid {
  display: grid;
  gap: 16px;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: center;
}

/* Ultra small devices, 328px and down) */
@media only screen and (max-width: 328px) {
  .vehicleSearchResultsGrid {
    grid-template-columns: repeat(1, 1fr);
    padding-left: max(20px, calc(50% - 140px));
    padding-right: max(20px, calc(50% - 140px));
    justify-items: center;
  }
}

/* Extra small devices (phones, 328px to 625px) */
@media only screen and (min-width: 328px) and (max-width: 625px) {
  .vehicleSearchResultsGrid {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
}

/* Small devices (portrait tablets and large phones, 625px to 979px) */
@media only screen and (min-width: 625px) and (max-width: 980px) {
  .vehicleSearchResultsGrid {
    grid-template-columns: repeat(2, minmax(280px, 420px));
  }
}

/* Medium devices (landscape tablets, 980px to 1534px) */
@media only screen and (min-width: 980px) and (max-width: 1535px) {
  .vehicleSearchResultsGrid {
    grid-template-columns: repeat(3, minmax(280px, 420px));
  }
}

/* Large devices (laptops/desktops, 1535px and up) */
@media only screen and (min-width: 1535px) {
  .vehicleSearchResultsGrid {
    grid-template-columns: repeat(4, minmax(280px, 420px));
    justify-content: start;
  }
}

.row-placeholder {
  display: block !important;
  grid-column: 1 / -1;
  width: 100%;
}
</style>
