<template>
  <transition name="slide">
    <div data-component-name="price-and-payment-filter">
      <div v-show="!priceFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="
            this.facetFilters[filterTypePrice] || this.facetFilters[filterTypePayment]
              ? true
              : false
          "
          :title="title"
        />
      </div>
      <div v-show="priceFilterSelected">
        <span id="lblTitle">{{ title }}</span>
        <div class="text-toggle d-flex justify-content-center">
          <TextToggle
            id="price-payment-toggle"
            :title="title"
            :options="toggleOptions"
            v-bind:value="priceSelected"
            @input="updateToggle"
          />
        </div>
        <div id="pp-range-input" data-for="price-and-payment" v-show="priceSelected">
          <RangeInput
            :range="priceRange"
            @input="updateStorePrice"
            @focus="setUpdateStorePrice = true"
            :step="priceIncrement"
            :currency="true"
            :minLabel="minLabel"
            :maxLabel="maxLabel"
            :currencyIcon="currencyIcon"
          ></RangeInput>
        </div>
        <div id="pp-range-input" v-show="!priceSelected">
          <RangeInput
            :range="paymentRange"
            @input="updateStorePayment"
            @focus="setUpdateStorePayment = true"
            :step="paymentIncrement"
            :currency="true"
            :minLabel="minLabel"
            :maxLabel="maxLabel"
            :currencyIcon="currencyIcon"
          ></RangeInput>
        </div>
        <div id="pp-range-slider" v-show="priceSelected">
          <RangeSlider
            :step="priceIncrement"
            :range="priceRange"
            :setRange="setPriceRange"
            v-model="priceModel"
            @sliderValueUpdated="updatePriceRange"
            @sliderValueSet="updateStorePrice"
          ></RangeSlider>
        </div>
        <div id="pp-range-slider" v-show="!priceSelected">
          <RangeSlider
            :step="paymentIncrement"
            :range="paymentRange"
            :setRange="setPaymentRange"
            v-model="paymentModel"
            @sliderValueUpdated="updatePaymentRange"
            @sliderValueSet="updateStorePayment"
          >
          </RangeSlider>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import RangeInput from '@components-vue/Range/RangeInput.vue';
import RangeSlider from '@components-vue/Range/RangeSlider.vue';
import TextToggle from '@components-vue/Toggle/TextToggle.vue';
import FilterMenuLineItem from '../FilterMenuLineItem.vue';
import { facetMetaData } from '@util/facetHelper.js';

export default {
  name: 'PriceFilter',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    RangeInput,
    RangeSlider,
    TextToggle,
    FilterMenuLineItem,
  },
  data() {
    return {
      priceRange: [],
      paymentRange: [],
      setPriceRange: [],
      setPaymentRange: [],
      priceIncrement: 1000,
      paymentIncrement: 50,
      title: this.$t('Price Payment Filter Title'),
      minLabel: this.$t('Min Label'),
      maxLabel: this.$t('Max Label'),
      currencyIcon: this.$t('Currency Icon'),
      priceToggle: this.$t('Price Payment Toggle Text Left'),
      paymentToggle: this.$t('Price Payment Toggle Text Right'),
      filterTypePrice: facetMetaData.price.key,
      filterTypePayment: facetMetaData.payment.key,
      setUpdateStorePrice: true,
      setUpdateStorePayment: true,
    };
  },
  computed: {
    ...mapState('srp', {
      filterSelected: (state) => state.filterSelected,
      priceSelected: (state) => state.priceToggleSelected,
      priceFilterSelected: (state) => state.priceFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      facetFilters: (state) => state.facetFilters ?? {},
      vehiclePriceMax: (state) => state.srpVehiclesData?.facets?.price?.max,
      vehiclePriceMin: (state) => state.srpVehiclesData?.facets?.price?.min,
      vehiclePaymentMax: (state) => state.srpVehiclesData?.facets?.payment?.max,
      vehiclePaymentMin: (state) => state.srpVehiclesData?.facets?.payment?.min,
    }),
    toggleOptions: function () {
      return [
        { text: 'Price', value: true },
        { text: 'Payment', value: false },
      ];
    },
    priceModel: {
      get() {
        return this.priceRange;
      },
      set(value) {
        this.updateStorePrice(value);
      },
    },
    paymentModel: {
      get() {
        return this.paymentRange;
      },
      set(value) {
        this.updateStorePayment(value);
      },
    },
  },
  created() {
    this.setUpdateStorePrice = false;
    this.setUpdateStorePayment = false;
    this.setInitialPriceRanges();
    this.setInitialPaymentRanges();
  },
  methods: {
    ...mapMutations('srp', [
      'setPriceToggleSelected',
      'setFilterSelected',
      'setPriceFilterSelected',
      'setResetSelectedFacetItems',
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setPriceFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    updatePriceRange(values) {
      this.setUpdateStorePrice = true;
      let minInt = parseInt(values[0]);
      let maxInt = parseInt(values[1]);
      this.priceRange = [minInt, maxInt];
    },
    updatePaymentRange(values) {
      this.setUpdateStorePayment = true;
      let minInt = parseInt(values[0]);
      let maxInt = parseInt(values[1]);
      this.paymentRange = [minInt, maxInt];
    },
    updateStorePrice(values) {
      if (this.setUpdateStorePrice) {
        let minInt = parseInt(values[0]);
        let maxInt = parseInt(values[1]);

        const data = {
          name: this.filterTypePrice,
          value: { min: minInt, max: maxInt },
        };

        clearTimeout(this.updateTimer);
        this.updateTimer = setTimeout(() => {
          this.priceRange = [minInt, maxInt];

          this.$store.commit('srp/setNumericFacetFilter', data);
          this.$root.$emit('filter-updated-srp');
        }, 2000);
      }
    },
    updateStorePayment(values) {
      if (this.setUpdateStorePayment) {
        let minInt = parseInt(values[0]);
        let maxInt = parseInt(values[1]);

        const data = {
          name: this.filterTypePayment,
          value: { min: minInt, max: maxInt },
        };

        clearTimeout(this.updateTimer);
        this.updateTimer = setTimeout(() => {
          this.paymentRange = [minInt, maxInt];

          this.$store.commit('srp/setNumericFacetFilter', data);
          this.$root.$emit('filter-updated-srp');
        }, 2000);
      }
    },
    setInitialPriceRanges() {
      let lowestMinPrice = parseInt(this.roundNumberToLowestThousand(this.vehiclePriceMin ?? 5000));
      let highestMaxPrice = parseInt(
        this.roundNumberToHighestTenThousand(this.vehiclePriceMax ?? 100000)
      );

      this.setPriceRange = [lowestMinPrice, highestMaxPrice];
      this.priceRange = [lowestMinPrice, highestMaxPrice];
    },
    setInitialPaymentRanges() {
      let lowestMinPayment = parseInt(this.roundNumberToLowestFifty(this.vehiclePaymentMin ?? 50));
      let highestMaxPayment = parseInt(
        this.roundNumberToHighestOneHundred(this.vehiclePaymentMax ?? 750)
      );

      this.setPaymentRange = [lowestMinPayment, highestMaxPayment];
      this.paymentRange = [lowestMinPayment, highestMaxPayment];
    },
    roundNumberToLowestThousand(number) {
      return Math.floor(number / 1000) * 1000;
    },
    roundNumberToHighestTenThousand(number) {
      return Math.ceil(number / 10000) * 10000;
    },
    roundNumberToLowestFifty(number) {
      return Math.floor(number / 50) * 50;
    },
    roundNumberToHighestOneHundred(number) {
      return Math.ceil(number / 100) * 100;
    },
    updateToggle(newValue) {
      this.setPriceToggleSelected(newValue);
    },
  },
  watch: {
    facetFilters: {
      handler: async function (newValue) {
        if (!newValue.hasOwnProperty(this.filterTypePrice)) {
          this.priceRange = [this.setPriceRange[0], this.setPriceRange[1]];
          this.setUpdateStorePrice = false;
        } else {
          this.priceRange = [
            this.facetFilters[this.filterTypePrice].min,
            this.facetFilters[this.filterTypePrice].max,
          ];
        }

        if (!newValue.hasOwnProperty(this.filterTypePayment)) {
          this.paymentRange = [this.setPaymentRange[0], this.setPaymentRange[1]];
          this.setUpdateStorePayment = false;
        } else {
          this.paymentRange = [
            this.facetFilters[this.filterTypePayment].min,
            this.facetFilters[this.filterTypePayment].max,
          ];
        }
      },
      deep: true,
    },
    vehiclePriceMax: 'setInitialPriceRanges',
    vehiclePaymentMax: 'setInitialPaymentRanges',
  },
};
</script>

<style scoped>
.slide-enter {
  transform: translateX(-100%);
}

.slide-enter-active {
  transition: all 0.3s ease-in;
}

.slide-leave-active {
  transition: all 0.1s ease-in;
}

.slide-leave-to {
  transform: translateX(-100%);
}

#lblTitle {
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  height: auto;
  color: #242c33;
  padding: 16px 24px 0;
}

.text-toggle {
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

#price-payment-toggle .toggleBtn {
  width: 88px;
}

#pp-range-input #lblTitle {
  display: none;
}

#pp-range-input #inputMaxLbl {
  padding-right: 73px;
}

#pp-range-slider #divRangeSlider {
  width: 100%;
  padding-left: 24px;
  padding-right: 20px;
}
</style>
