<template>
  <transition name="slide">
    <div>
      <div v-show="!colorFilterSelected && !filterSelected" data-role="filter-toggle">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="
            this.facetFilters[extColorFilterType] ||
            this.facetFilters[intColorFilterType]
              ? true
              : false
          "
          :title="title"
          :data-for="title"
        />
      </div>
      <div v-show="colorFilterSelected">
        <span id="lblTitle">{{ title }}</span>
        <div class="text-toggle d-flex justify-content-center">
          <TextToggle
            :options="toggleOptions"
            v-bind:value="exteriorSelected"
            @input="updateToggle"
          />
        </div>
        <div v-show="exteriorSelected" class="colorFilter">
          <FacetList
            class="facet-list"
            v-model="facetModelExt"
            :data="facetListExtColor"
            :filterType="extColorFilterType"
          ></FacetList>
        </div>
        <div v-show="!exteriorSelected" class="colorFilter">
          <FacetList
            class="facet-list"
            v-model="facetModelInt"
            :data="facetListIntColor"
            :filterType="intColorFilterType"
          ></FacetList>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import FacetList from "@components-vue/List/FacetList.vue";
import TextToggle from "@components-vue/Toggle/TextToggle.vue";
import FilterMenuLineItem from "../FilterMenuLineItem.vue";
import { facetMetaData } from "@util/facetHelper.js";

export default {
  name: "ColorFilter",
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    TextToggle,
    FacetList,
    FilterMenuLineItem,
  },
  data() {
    return {
      title: this.$t("Color Filter Title"),
      exteriorToggleText: this.$t("Color Filter Toggle Text Left"),
      interiorToggleText: this.$t("Color Filter Toggle Text Right"),
      extColorFilterType: facetMetaData.extcolor.key,
      intColorFilterType: facetMetaData.intcolor.key,
      cachedCountExtColor: {},
      cachedCountIntColor: {},
      updateCachedCountExt: true,
      updateCachedCountInt: true,
    };
  },
  computed: {
    ...mapState("srp", {
      facetFilters: (state) => state.facetFilters ?? {},
      filterSelected: (state) => state.filterSelected,
      colorFilterSelected: (state) => state.colorFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      exteriorSelected: (state) => state.extColorToggleSelected,
      extColorCount: (state) => state.facetCounts?.extcolor || {},
      intColorCount: (state) => state.facetCounts?.intcolor || {},
      recentSelectedFilter: (state) => state.recentSelectedFilter,
    }),
    toggleOptions: function () {
      return [
        { text: this.exteriorToggleText, value: true },
        { text: this.interiorToggleText, value: false },
      ];
    },
    facetListExtColor() {
      return this.fields?.ExteriorColors?.map((x, index) => ({
        name: x.displayName,
        value: this.getExtCount(x),
        scImage: x.fields?.ColorIcon,
      }));
    },
    facetListIntColor() {
      return this.fields?.InteriorColors?.map((x, index) => ({
        name: x.displayName,
        value: this.getIntCount(x),
        scImage: x.fields?.ColorIcon,
      }));
    },
    facetModelExt() {
      return this.facetListExtColor;
    },
    facetModelInt() {
      return this.facetListIntColor;
    },
    getIntCount() {
      return (x) =>
        this.cachedCountIntColor.hasOwnProperty(x.displayName)
          ? this.cachedCountIntColor[x.displayName]
          : 0;
    },
    getExtCount() {
      return (x) =>
        this.cachedCountExtColor.hasOwnProperty(x.displayName)
          ? this.cachedCountExtColor[x.displayName]
          : 0;
    },
  },
  mounted() {},
  methods: {
    ...mapMutations("srp", [
      "setExtColorToggleSelected",
      "setFilterSelected",
      "setColorFilterSelected",
      "setResetSelectedFacetItems",
    ]),
    updateStore(data) {
      this.$store.commit("srp/setStringFacetFilter", data);
      this.$root.$emit("filter-updated-srp");
    },
    updateToggle(newValue) {
      this.setExtColorToggleSelected(newValue);
    },
    expandFilter() {
      this.setFilterSelected(true);
      this.setColorFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
  },
  watch: {
    recentSelectedFilter(newValue, oldValue) {
      if (
        newValue === this.extColorFilterType &&
        oldValue !== this.extColorFilterType
      )
        this.updateCachedCountExt = false;
      else this.updateCachedCountExt = true;

      if (
        newValue === this.intColorFilterType &&
        oldValue !== this.intColorFilterType
      )
        this.updateCachedCountInt = false;
      else this.updateCachedCountInt = true;
    },
    extColorCount() {
      this.cachedCountExtColor = this.extColorCount;
    },
    intColorCount() {
      this.cachedCountIntColor = this.intColorCount;
    },
    exteriorSelected(isSelected) {
      if (this.colorFilterSelected && isSelected) {
        this.$root.$emit("fetch-facet-count", this.extColorFilterType);
      } else if (this.colorFilterSelected && !isSelected) {
        this.$root.$emit("fetch-facet-count", this.intColorFilterType);
      }
    },
    colorFilterSelected(isSelected) {
      if (isSelected && this.exteriorSelected) {
        this.$root.$emit("fetch-facet-count", this.extColorFilterType);
      } else if (isSelected && !this.exteriorSelected) {
        this.$root.$emit("fetch-facet-count", this.intColorFilterType);
      }
    },
    facetFilters: {
      handler: async function () {
        if (
          this.colorFilterSelected &&
          this.updateCachedCountExt &&
          this.exteriorSelected
        ) {
          this.$root.$emit("fetch-facet-count", this.extColorFilterType);
          this.cachedCountExtColor = this.extColorCount;
        } else if (
          this.colorFilterSelected &&
          this.updateCachedCountInt &&
          !this.exteriorSelected
        ) {
          this.$root.$emit("fetch-facet-count", this.intColorFilterType);
          this.cachedCountIntColor = this.intColorCount;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
#lblTitle {
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  height: auto;
  color: #242c33;
  padding: 16px 24px;
}

.colorFilter {
  margin-top: 24px;
  padding-bottom: 40px;
}

.facet-list {
  width: 100%;
  max-height: calc(100vh - 215px);
}

.text-toggle {
  padding: 8px 0 16px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 8px;
  border: 1px solid #ffffff;
}
</style>
