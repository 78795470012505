<template>
  <div id="divRangeInput">
    <span id="lblTitle" v-if="title">{{ title }}</span>
    <div id="divLblMinMaxOuter">
      <label id="inputMinLbl" for="textbox">{{ minLabel }}</label>
      <label id="inputMaxLbl" for="textbox">{{ maxLabel }}</label>
    </div>
    <div id="divMinMaxInputContainer">
      <div id="divMinInputContainer">
        <CurrencyInput
          v-if="currency"
          v-model="range[0]"
          @input="emitUpdate($event)"
          @focus="handleFocus"
        />

        <input
          v-else
          id="inputMin"
          v-model="range[0]"
          @input="emitUpdate($event)"
          @focus="handleFocus"
          type="number"
          :step="step"
        />
      </div>
      <div id="divMaxInputContainer">
        <CurrencyInput
          v-if="currency"
          v-model="range[1]"
          @input="emitUpdate($event)"
          @focus="handleFocus"
        />

        <input
          v-else
          id="inputMax"
          v-model="range[1]"
          @input="emitUpdate($event)"
          @focus="handleFocus"
          type="number"
          :step="step"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyInput from './CurrencyInput.vue';

export default {
  name: 'RangeInput',
  components: {
    CurrencyInput,
  },
  props: {
    title: {
      type: String,
    },
    minLabel: {
      type: String,
    },
    maxLabel: {
      type: String,
    },
    currencyIcon: {
      type: String,
    },
    currency: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Array,
      default: function () {
        [0, 100];
      },
    },
    setRange: {
      type: Array,
      default: function () {
        return [0, 100];
      },
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  emits: ['focus', 'input'],
  data() {
    return {
      inputValue: 0,
    };
  },
  methods: {
    emitUpdate() {
      this.$emit('input', this.range);
    },
    handleFocus() {
      this.$emit('focus');
    },
  },
};
</script>

<style scoped>
#divRangeInput #divLblMinMaxOuter,
#divRangeInput #divMinMaxInputContainer {
  padding: 0 24px 4px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#lblTitle {
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  height: auto;
  color: var(--park-gray);
  padding: 16px 24px;
}

#divRangeInput #inputMinLbl,
#divRangeInput #inputMaxLbl {
  display: inline-block;
  width: 100px;
  color: var(--park-gray-20);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

#divRangeInput #divMinMaxInputContainer {
  padding-bottom: 30px;
}

#divRangeInput #divMinInputContainer,
#divRangeInput #divMaxInputContainer {
  width: 100px;
}

#divRangeInput #inputMin,
#divRangeInput #inputMax {
  border-radius: 8px;
  border: 1px solid var(--park-gray-80);
  padding: 8px;
  width: 100%;
  height: 40px;
}
</style>
