<template>
  <div class="vehicleImagecarousel">
    <b-carousel
      ref="vehicleImageCarousel"
      class="min-h-200"
      :interval="0"
      indicators
    >
      <b-carousel-slide v-for="image in imageList" :key="image">
        <template #img>
          <b-img-lazy
            class="d-block img-fluid ar-4-3 w-100"
            :src="image"
            :alt="imageAltText"
            width="400"
            height="300"
          />
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <b-img-lazy
            class="d-block img-fluid w-100 ar-4-3 black-blur-image"
            :src="lowRezImage || imageList[0]"
            :alt="imageAltText"
            width="400"
            height="300"
          />
        </template>
        <SrpButton carousel :buttonText="$t('See car details')" />
      </b-carousel-slide>
      <span
        @click.prevent.stop="prev"
        @keyup.prevent.stop.enter="prev"
        role="button"
        class="carousel-control-prev"
      >
        <span aria-hidden="true" class="carousel-control-prev-icon"></span>
        <span class="sr-only">Previous slide</span>
      </span>
      <span
        @click.prevent.stop="next"
        @keyup.prevent.stop.enter="next"
        role="button"
        class="carousel-control-next"
      >
        <span aria-hidden="true" class="carousel-control-next-icon"></span>
        <span class="sr-only">Next slide</span>
      </span>
    </b-carousel>
  </div>
</template>

<script>
import SrpButton from "@components/buttons/SrpButton.vue";
import { adjustCarouselFocus } from "@assets/js/carouselAdjust";

export default {
  props: {
    imageList: Array,
    lowRezImage: String,
    imageAltText: String,
    seeMoreOnClick: Function,
  },
  name: "VehicleCardCarousel",
  components: { SrpButton },
  mounted() {
    adjustCarouselFocus();
  },
  methods: {
    prev() {
      this.$refs.vehicleImageCarousel.prev();
    },
    next() {
      this.$refs.vehicleImageCarousel.next();
    },
  },
};
</script>

<style>
/* BLUR IMAGE */
.black-blur-image {
  filter: brightness(45%) blur(7px);
  transform: scale(1.03);
}

/* BLUR IMAGE */
.grayBackground {
  filter: brightness(45%) blur(7px);
  transform: scale(1.03);
}

/* INDICATORS STYLES */
.carousel-indicators {
  margin: 0;
  bottom: -6.43px;
  align-items: center;
  z-index: 1;

  .active {
    width: 10.714px;
    height: 10.714px;
    border-radius: 10.714px;
    border: 1px solid var(--park-gray-100);
    background: var(--park-gray-10);
  }
}

.carousel-indicators li {
  opacity: 1;
  width: 6.43px;
  height: 6.43px;
  border-radius: 100%;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid var(--park-gray-100);
  background: var(--park-gray-10);
}

/* ARROW CONTROLS */
.carousel-control-prev,
.carousel-control-next {
  z-index: 1;
  display: none;
  opacity: 1;
}

.vehicleImagecarousel:hover .carousel-control-prev,
.vehicleImagecarousel:hover .carousel-control-next {
  display: flex;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: 24px;
  background-color: rgba(36, 44, 51, 0.3);
  /* --base-park-gray-0 with 0.3 opacity */
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  background-color: rgba(36, 44, 51, 0.5);
}

.carousel-control-prev-icon {
  background-image: url("~@icons/ArrowIcons/ArrowIconCarousel/ArrowIconCarouselLeft.svg");
}

.carousel-control-next-icon {
  background-image: url("~@icons/ArrowIcons/ArrowIconCarousel/ArrowIconCarouselRight.svg");
}

/* SEE CAR DETAILS CONTROLS */
.carousel-caption {
  z-index: 1;
  right: auto;
  left: auto;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.min-h-200 {
  min-height: 200px;
}

.ar-4-3 {
  aspect-ratio: 4/3;
}

@media only screen and (max-width: 480px) {
  .vehicleImagecarousel:hover .carousel-control-prev,
  .vehicleImagecarousel:hover .carousel-control-next {
    display: none;
  }
}
</style>
