<template>
    <div class="signInOuterContainer">
        <div class="account_overlay"></div>
        <div id="signInContainer" class="create_account">
            <sc-rich-text :field="fields.html" />
        </div>
    </div>
</template>

<script>

import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { onLoginMount } from "../../assets/js/login";
import { onSignInMount } from "../../assets/js/signIn";


export default {
    name: 'EchoParkLogin',
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        ScRichText: RichText,
    },
    mounted() {
        onLoginMount();
        onSignInMount();
    },
    updated() {
        onLoginMount();
        onSignInMount();
    }
};
</script>

<style>
#signInContainer {
    z-index: 81;
}

.signInOuterContainer{ 
    position: fixed;
    z-index: 81; 
}

.signInOuterContainer .btn {
    background-color: var(--green-50);
    color: var(--base-white-100);
}

.account_overlay,
.calculator_overlay,
.car_flyout_overlay,
.create_account_overlay {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .4;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 75;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out
}

.account_overlay.show_panel,
.calculator_overlay.show_panel,
.car_flyout_overlay.show_panel,
.create_account_overlay.show_panel {
    opacity: .1;
    visibility: visible
}

.open_create_account {
    overflow: hidden
}

.create_account.show-signIn .signIn-content {
    display: block
}

.create_account .form-content {
    display: none
}

.create_account .create_account_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    min-height: 24px;
    position: relative
}

.create_account .ParagraphParkGray-BodySM .SystemLInk-Body-SM {
    text-decoration: none
}

.create_account .ParagraphParkGray-BodySM {
    text-align: center
}

.create_account .ParagraphParkGray-BodySM .SystemLInk-Body-SM {
    text-decoration: none
}

.create_account {
    width: 100%;
    height: 100%;
    background: var(--base-white-100);
    padding: 24px;
    overflow: auto;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 12;
    transition: all .3s ease-in-out;
    scrollbar-width: thin;
    scrollbar-color: var(--green-green-60) var(--accent-warm-gray-warm-gray-80)
}

.create_account::-webkit-scrollbar {
    width: 8px
}

.create_account::-webkit-scrollbar-track {
    background: var(--accent-warm-gray-warm-gray-80)
}

.create_account::-webkit-scrollbar-thumb {
    background: var(--green-green-60)
}

.create_account::-webkit-scrollbar-thumb:hover {
    background: var(--green-green-60)
}

@media(min-width:576px) {
    .create_account {
        max-width: 380px
    }
}

.create_account .formCenter {
    max-width: 375px;
    margin: 0 auto
}

@media(min-width:576px) {
    .create_account .formCenter {
        max-width: inherit
    }
}

.create_account .create_account_close,
.create_account .goback {
    height: 24px;
    cursor: pointer;
    min-width: 24px
}

.create_account .create_account_close {
    min-width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background: 0
}

.welcome_title {
    margin-bottom: 24px
}

.welcome_title .HeadingParkGray-H4 {
    margin-bottom: 8px
}

.create_account .goback {
    min-width: 24px;
    height: 24px;
    cursor: pointer
}

.create_account .account_infobox {
    width: 100%;
    max-width: 286px;
    margin: 48px auto 24px;
    text-align: center
}

.create_account .account_infobox .status_icon {
    margin-bottom: 24px
}

.create_account .account_infobox .HeadingParkGray-H4 {
    margin-bottom: 12px
}

.create_account .ParagraphParkGray-BodySM {
    text-align: center
}

.create_account .ParagraphParkGray-BodySM .SystemLInk-Body-SM {
    text-decoration: none
}

.create_account.show_panel {
    right: -5px;
    width: calc(100% + 5px)
}

.create_account .form-content {
    display: block
}

.create_account.show-forgotpassword .forgotpassword-content {
    display: block
}

.create_account.show-signIn .signIn-content {
    display: block
}

.create_account.show-forgotpasswordSentEmail .forgotpasswordSentEmail-content {
    display: block
}

.create_account.show-accountCreated .accountCreated-content {
    display: block
}

#createAccountHeaderContainer {
    z-index: 13;
    background: transparent;
    right: -5px;
    width: calc(100% + 5px);
    pointer-events: none;
}
</style>