<template>
  <div>
    <footer class="footer">
      <div class="content">
        <p>&copy; 2023 EchoPark. All Rights Reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Copyright",
};
</script>

<style scoped>
.footer {
  background-color: var(--dark-charcoal);
  color: var(--base-white-100);
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer .content {
  text-align: center;
  font-size: 0.8em;
}
</style>
=