import axios from "axios";
import appConfig from "../appConfig";

export default{
    getDealerships : async function(zipCode) {
        let apiUrl = appConfig.dealershipApiEndpoint;
        let result = null;
        (await axios.get(apiUrl, {params : {zip : zipCode}})
        .then(resp =>{
            result = resp.data.items;
        })
        .catch(e => {
            console.log(e)
        }));
        return result;
    }
}