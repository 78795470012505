<template>
  <div>
    <h1>HTTP Requests with Fetch</h1>
    <button @click="makePostRequest">Make POST Request</button>
    <p>{{ postResponse }}</p>
  </div>
</template>

<script>
import requestExampleService from "../../../services/requestExampleService";

export default {
  name: "RequestExample",
  data() {
    return {
      postResponse: "",
    };
  },
  created() {
    this.makeGetRequest();
  },
  methods: {
    makeGetRequest() {
      requestExampleService.getPost(1).then((data) => {
        console.log(data);
      });
    },
    makePostRequest() {
      const data = {
        title: "foo",
        body: "bar",
        userId: 1,
      };

      requestExampleService.createPost(data).then((data) => {
        this.postResponse = JSON.stringify(data);
      });
    },
  },
};
</script>
