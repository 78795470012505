<template>
  <div
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
    class="d-flex justify-content-between border-bottom filter-list-item align-items-center"
    tabindex="0"
  >
    <label class="facet-name" :class="{ 'selected-filter': isSelected }" :data-action-select-filter="title">{{
      title
    }}</label>
    <button class="btn btn-sm px-3" tabindex="-1">
      <img
        src="@icons/Path_filtermain.svg"
        class="arrow-icon"
        :alt="$t('Right arrow icon')"
      />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ["click"],
  name: "FilterMenuLineItem",
};
</script>

<style scoped>
.arrow-icon {
  fill: var(--park-gray-0);
  height: 14px;
}

.facet-name {
  cursor: pointer;
}

.selected-filter {
  font-weight: bold;
}

.filter-list-item {
  color: #242c33;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  padding: 16px 6px 15px 24px;
}

.filter-list-item:hover {
  transition: 0.3s;
  background-color: rgb(242, 242, 242);
}

.filter-list-item:focus-visible {
  outline-offset: -3px;
}

.filter-list-item .btn {
  margin: -10px 0;
  padding: 11px 14px !important;
}
</style>
