<template>
  <SearchController>
    <div class="contentBlock">
      <placeholder
        name="filter-menu"
        :rendering="rendering"
        class="filter"
        :class="{ filterExpanded: showOverlay }"
      />

      <div id="divChipContentContainer" class="srp-main-content">
        <placeholder
          name="sort-menu"
          :rendering="rendering"
          class="filter-and-sort-menu-container"
        />
        <placeholder
          name="filter-chip"
          :rendering="rendering"
          class="filter-chip-desktop"
        />
        <placeholder
          name="srp-content"
          :rendering="rendering"
          class="srp-content"
        />
      </div>

      <Overlay :showOverlay="showOverlay" :clickEvent="'toggle-filter-menu'" />
    </div>
  </SearchController>
</template>

<script>
import { mapMutations } from "vuex";
import Overlay from "@components-vue/Overlay/Overlay";
import SearchController from "@components-vue/Listeners/SearchController";
import { Placeholder } from "@sitecore-jss/sitecore-jss-vue";

export default {
  name: "VehicleSearchContainer",
  data() {
    return {
      showOverlay: false,
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Overlay,
    Placeholder,
    SearchController,
  },
  methods: {
    ...mapMutations("srp", ["setQueryParams"]),
    handleResize() {
      if (typeof window !== "undefined" && window.innerWidth >= 1240) {
        this.showOverlay = false;
        document.body.classList.remove("freeze-background");
      }
    },
  },
  mounted() {
    this.$root.$on("toggle-filter-menu", () => {
      this.showOverlay = !this.showOverlay;
      if (this.showOverlay) {
        document.body.classList.add("freeze-background");
      } else {
        document.body.classList.remove("freeze-background");
      }
    });

    this.setQueryParams(this.$route.query);
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.srp-main-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.contentBlock {
  background-color: var(--whitesmoke);
  display: flex;
  flex-direction: row;
  gap: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.filter-chip-desktop {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 70;
}

.scrollUp .filter-chip-desktop {
  top: 72px;
  z-index: 99;
}

@media only screen and (min-width: 1240px) {
  .filter-and-sort-menu-container {
    order: 2;
  }
  .filter-chip-desktop {
    order: 1;
  }
  .srp-content {
    order: 3;
  }
}

.filterExpanded {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  flex: none;
  width: 100%;
}

@media only screen and (min-width: 625px) {
  .filterExpanded {
    width: auto;
  }
}

@media only screen and (min-width: 1240px) {
  .filterExpanded {
    flex: unset;
  }
}
</style>
