import axios from "axios";

const LOCAL_HOST = "/";

const api = axios.create({
  baseURL: LOCAL_HOST + "api/sitecore",
});

export default {
  async CheckAvailability(
    FirstName,
    LastName,
    Email,
    PhoneNumber,
    Comments,
    ZipCode,
    vin
  ) {
    var dataModel = {};
    dataModel.availability = {};
    dataModel.availability.name = {};
    dataModel.availability.name.first = FirstName;
    dataModel.availability.name.last = LastName;
    dataModel.availability.name.middle = " ";
    var phone = PhoneNumber;
    phone = phone
      .toString()
      .replace("(", "")
      .replace(")", "")
      .replace("-", "")
      .replace(" ", "");
    dataModel.availability.phone = phone;
    dataModel.availability.email = Email;
    dataModel.availability.comment = Comments;
    dataModel.availability.zip = ZipCode;
    dataModel.vin = vin;
    dataModel.PageSource = "VDP";

    api
      .post("/VehicleDetails/SubmitCheckAvailability", {
        questionCheckAvailability: dataModel,
      })
      .then((response) => {
        const data = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
