let resetDefault = false;

export function onCalculatorMount() {
  const $body = $('body');

  //calculator open logic
  $body.on('click', '.calculator-button', function (e) {
    e.preventDefault();
    e.stopPropagation();
    ResolveCalculatorClick(this);
  });

  $body.on('keypress', '.calculator-button', function (e) {
    if (e.keycode == 32 || e.keycode == 13) {
      ResolveCalculatorClick(this);
    }
  });

  //calculator Tab change
  $body.on('click', '#cashPayment', function () {
    GetCashPriceSRP();
  });
  $body.on('click', '#sellingprice', function () {
    GetFinancePriceSRP();
  });

  //Calculator sticky header logic
  $body.on('scroll', '.calculator_flyout--container', function () {
    let $payment_card_offset_top;
    let $payment_card;
    let calscrolled_val = window.scrollY;

    $payment_card = $('.calculator_flyout--allInPrice_details:visible');
    if ($payment_card.length == 0) {
      $payment_card = $('.calculator_flyout--allInPrice_details_cash');
    }

    if ($calculatorContainer.length > 0) {
      $payment_card_offset_top = $payment_card.offset().top;
    }

    if (
      $('.calculator_flyout--container').scrollTop() >=
      $payment_card_offset_top - calscrolled_val
    ) {
      $('.calculator_flyout_title').addClass('sticky');
    } else {
      $('.calculator_flyout_title').removeClass('sticky');
    }

    $('#tooltip').remove();
  });

  // Calculator close logic
  $body.on('click', '.calculator_flyout_close', function () {
    $('html').removeClass('calculator_flyout_open');
    $('.calculator_overlay').toggleClass('show_panel');
    $('.calculator_flyout_title').removeClass('sticky');
  });
  $body.on('click', '.calculator_overlay', function () {
    $('.calculator_flyout_close').trigger('click');
  });

  // Price breakdown logic (see/hide details)
  $('.changeTxt .seeAllDetails').click(function () {
    $(this).text($(this).text() == 'See details' ? 'Hide details' : 'See details');
  });

  $body.on('click', '.seeAllDetails, .seeAllDetails-cash', function (e) {
    e.preventDefault();

    if ($('.seeAllDetails').hasClass('active')) {
      $('.seeAllDetails').html($('.seeAllDetails').attr('data-show'));
      $('.seeAllDetails-cash').html($('.seeAllDetails-cash').attr('data-show'));
    } else {
      $('.seeAllDetails').html($('.seeAllDetails').attr('data-hide'));
      $('.seeAllDetails-cash').html($('.seeAllDetails-cash').attr('data-hide'));
    }

    $('.seeAllDetails').toggleClass('active');
    $('.seeAllDetails-cash').toggleClass('active');
    $('.calculator_flyout').find('.calculator_flyout--moredetails_breakdown').slideToggle();
  });

  //Trade in payoff field logic
  $body.on('click', '#calculator_flyout_still_owe_finance', function (e) {
    e.preventDefault();
    $('#calculator_flyout_still_owe_finance').hide();
    $('#calculator_flyout_payoff_finance').addClass('active');
  });
  $body.on('click', '#calculator_flyout_still_owe_cash', function (e) {
    e.preventDefault();
    $('#calculator_flyout_still_owe_cash').hide();
    $('#calculator_flyout_payoff_cash').addClass('active');
  });

  //changing tabs on calculator
  $('.filter_tabs .filter_tab:first-child').addClass('active');
  $body.on('click', '.filter_tabs .filter_tab', function () {
    if (!$(this).hasClass('active')) {
      $(this).addClass('active').siblings().removeClass('active');
      var target = $(this).attr('id');
      $('.' + target)
        .show()
        .siblings()
        .hide();
    }
  });

  //Terms and credit tier click logic
  $body.on('click', '.input_check_list input', function () {
    if ($(this).parents('.termSlider')) {
      let __getVal = $(this).val();
      if (__getVal != null) {
        $(this)
          .parents('.termSlider')
          .find('.slideBox-selectedItem')
          .text(`${__getVal} ${calcObjects.hiddenMonthlyLabel}`);
      }
      if (__getVal != null) {
        $(this).parents('.creditTierSlider').find('.slideBox-selectedItem').text(`${__getVal}`);
      }
    }
    GetFinancePriceSRP();
  });

  let terms = readCookie('calculatorTerms');
  if (terms !== null) {
    $('.calculator_flyout--slideBox').ready(function () {
      $('input[value=' + terms + ']').click();
    });
  }

  let creditTier = readCookie('calculatorCreditTier');
  if (creditTier !== null) {
    $('.calculator_flyout--slideBox').ready(function () {
      $('input[value=' + '"' + creditTier + '"' + ']').click();
    });
  }

  $('.calculator_flyout--slideBox-vdp-srp').ready(function () {
    if (readCookie('calculatorDownPayment') !== null) {
      $('#downPayment-min-input-finance').val(readCookie('calculatorDownPayment'));
    }

    if (readCookie('calculatorTradeIn') !== null) {
      $('#calculator-trade-in-finance').val(readCookie('calculatorTradeIn'));
    }

    if (readCookie('calculatorTradeIn') !== null) {
      $('#calculator-trade-in-cash').val(readCookie('calculatorTradeIn'));
    }

    if (readCookie('calculatorPayoff') != null && readCookie('calculatorPayoff') != 0) {
      $('#calculator_flyout_add_loan_finance').click();
      $('#calculator-payoff-finance').val(readCookie('calculatorPayoff'));
    }

    if (readCookie('calculatorPayoff') != null && readCookie('calculatorPayoffCash') != 0) {
      $('#calculator_flyout_add_loan_cash').click();
      $('#calculator-payoff-cash').val(readCookie('calculatorPayoff'));
    }

    $('#downPayment-min-input-finance').on('change', function () {
      setCookie('calculatorDownPayment', $('#downPayment-min-input-finance').val());
    });

    $('#calculator-trade-in-finance').on('change', function () {
      setCookie('calculatorTradeIn', $('#calculator-trade-in-finance').val());
      $('#calculator-trade-in-cash').val(readCookie('calculatorTradeIn'));
    });

    $('#calculator-trade-in-cash').on('change', function () {
      setCookie('calculatorTradeIn', $('#calculator-trade-in-cash').val());
      $('#calculator-trade-in-finance').val(readCookie('calculatorTradeIn'));
    });

    $('#calculator-payoff-finance').on('change', function () {
      setCookie('calculatorPayoff', $('#calculator-payoff-finance').val());
      $('#calculator_flyout_add_loan_cash').click();
      $('#calculator-payoff-cash').val(readCookie('calculatorPayoff'));
    });

    $('#calculator-payoff-cash').on('change', function () {
      setCookie('calculatorPayoff', $('#calculator-payoff-cash').val());
      $('#calculator_flyout_add_loan_finance').click();
      $('#calculator-payoff-finance').val(readCookie('calculatorPayoff'));
    });

    $('#calculator_terms_1').click(function () {
      setCookie('calculatorTerms', $('#calculator_terms_1').attr('value'));
    });

    $('#calculator_terms_2').click(function () {
      setCookie('calculatorTerms', $('#calculator_terms_2').attr('value'));
    });

    $('#calculator_terms_3').click(function () {
      setCookie('calculatorTerms', $('#calculator_terms_3').attr('value'));
    });

    $('#calculator_terms_4').click(function () {
      setCookie('calculatorTerms', $('#calculator_terms_4').attr('value'));
    });

    $('#creditTier_terms_1').click(function () {
      setCookie('calculatorCreditTier', $('#creditTier_terms_1').attr('value'));
    });

    $('#creditTier_terms_2').click(function () {
      setCookie('calculatorCreditTier', $('#creditTier_terms_2').attr('value'));
    });

    $('#creditTier_terms_3').click(function () {
      setCookie('calculatorCreditTier', $('#creditTier_terms_3').attr('value'));
    });

    $('#creditTier_terms_4').click(function () {
      setCookie('calculatorCreditTier', $('#creditTier_terms_4').attr('value'));
    });
  });

  //input field bindings
  $body.on('blur', '#downPayment-min-input-finance', function (e) {
    e.preventDefault();
    CalculatePrice(this);
  });
  $body.on('focusout', '#calculator-trade-in-finance', function (e) {
    e.preventDefault();
    CalculatePrice(this);
  });
  $body.on('focusout', '#calculator-payoff-finance', function (e) {
    e.preventDefault();
    CalculatePrice(this);
  });
  $body.on('blur', '#calculator-trade-in-cash', function () {
    let tradeIn = parseInt($(this).val().replace(',', ''));
    if (!isNaN(tradeIn)) {
      GetCashPriceSRP(tradeIn);
    }
  });
  $body.on('blur', '#calculator-payoff-cash', function () {
    let tradeIn = parseInt($(this).val().replace(',', ''));
    if (!isNaN(tradeIn)) {
      GetCashPriceSRP(tradeIn);
    }
  });

  //Logic that allows only numbers on the calculator inputs
  $body.on('keyup', '.numberInput', function () {
    $(this).val(function (index, value) {
      return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    });
  });
}

function CalculatePrice(element) {
  let numberFromInput = $(element).val().replace(/,/g, '');

  let userVal = Number(numberFromInput);
  let errspan = $('#downPayment-min-input-finance').parents('.range-slider').find('.errormsg');
  if (isNaN(userVal) || userVal < 0) {
    errspan.removeClass('hide');
  } else {
    errspan.addClass('hide');
    GetFinancePriceSRP();
  }
}

let vin;

// Calculator open logic
function ResolveCalculatorClick(element) {
  resetDefault = true;
  vin = $(element).parents('.vehicleCardPaper').data('vin');
  $('#downPayment-min-input').val(calcObjects.defaultDownPayment || 0);
  $('html').toggleClass('calculator_flyout_open').removeClass('menu-open');
  $('.calculator_overlay').toggleClass('show_panel');
  $('.calculator_flyout--container').scrollTop(0);
  GetFinancePriceSRP();
  $('#vin').val(vin);
}

function GetFinancePriceSRP() {
  let errspan = $('#downPayment-min-input').parents('.range-slider').find('.errormsg');
  errspan.addClass('hide');
  let section = $('.sellingprice');
  //read the data from the controls and prepare the priceRequest
  let priceRequest = {};
  //downpayment

  priceRequest.DownPayment = Number($('#downPayment-min-input-finance').val().replace(',', ''));
  priceRequest.TradeIn = Number($('#calculator-trade-in-finance').val().replace(',', ''));
  priceRequest.Payoff = Number($('#calculator-payoff-finance').val().replace(',', ''));

  let selectedCredit = $("input[type='radio'][name='calculator_creditTier']:checked").val();
  let credit = calcObjects.creditSettings.find(
    (o) => o.creditDisplay === selectedCredit.toString()
  );
  //term
  priceRequest.Term = $("input[type='radio'][name='calculator_terms']:checked").val();
  //credit tier
  priceRequest.CreditTier = credit.creditName;
  //apr
  priceRequest.APR = Number(credit.APR);
  UpdateAppliedToPurchase(priceRequest, true);

  PopulatePriceSRP(priceRequest, section, true);
}

function UpdateAppliedToPurchase(priceRequest, isFinance) {
  let downPayment = 0;
  let tradeIn = 0;
  let payoff = 0;
  if (priceRequest.DownPayment) {
    downPayment = parseInt(priceRequest.DownPayment);
  }
  if (priceRequest.TradeIn) {
    tradeIn = parseInt(priceRequest.TradeIn);
  }
  if (priceRequest.Payoff) {
    payoff = parseInt(priceRequest.Payoff);
  }

  let totalValue = downPayment + tradeIn - payoff;
  // Create our number formatter.
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  if (isFinance) {
    $('#calculator_flyout_total_value_finance').val(formatter.format(totalValue));
    $('#calculator_flyout_total_value_finance').text(formatter.format(totalValue));
  } else {
    $('#calculator_flyout_total_value_cash').val(formatter.format(totalValue));
    $('#calculator_flyout_total_value_cash').text(formatter.format(totalValue));
  }
}

function GetCashPriceSRP(tradeIn) {
  //read the data from the controls and prepare the priceRequest
  let priceRequest = { TradeIn: tradeIn ? tradeIn : 0 };

  priceRequest.TradeIn = Number($('#calculator-trade-in-cash').val().replace(',', ''));
  priceRequest.Payoff = Number($('#calculator-payoff-cash').val().replace(',', ''));

  UpdateAppliedToPurchase(priceRequest, false);

  let section = $('.cashPayment');
  PopulatePriceSRP(priceRequest, section, false);
}

let cancelPriceSRP;

function PopulatePriceSRP(priceRequest, section, isfinance) {
  if (typeof vin === 'string') {
    priceRequest.VIN = vin;
  } else {
    priceRequest.VIN = $('#productId').val();
  }

  // Create our number formatter.
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  if (!vin) return;

  priceRequest.UseDefaultDownPayment = resetDefault;

  if (cancelPriceSRP) cancelPriceSRP.abort();

  cancelPriceSRP = $.ajax({
    type: 'POST',
    url: '/api/sitecore/VehicleDetails/GetPrice',
    data: JSON.stringify(priceRequest),
    contentType: 'application/json; charset=utf-8',
    beforeSend: function () {
      $('.calculator_icon').css('visibility', 'visible');
      $('.payment_card').show();
      $('.payment_card').addClass('active');
    },
    success: function (result_data) {
      if (result_data && result_data.IsSuccess) {
        if (!result_data.DefaultDownPayment) result_data.DefaultDownPayment = '0';
        if (isfinance) {
          section
            .find('.payment_card--price')
            .html(
              formatter.format(result_data.MonthlyPayment) +
                `<sub>${calcObjects.priceSuffixText}<sub>`
            );
          resetDefault = false;
        } else {
          //top price
          section.find('.payment_card--price').text(formatter.format(result_data.EffectivePrice));
          resetDefault = false;
        }

        if (isfinance) {
          //all in price
          section.find('.allInPrice span').text(formatter.format(result_data.AllInPrice));
          //remove section selector
          $('.allInPrice span').text(formatter.format(result_data.EffectivePrice));
          //setting formated downpaymet
          if (
            readCookie('calculatorDownPayment') != null &&
            readCookie('calculatorDownPayment') != 0
          )
            $('#downPayment-min-input-finance').val(readCookie('calculatorDownPayment'));
          else $('#downPayment-min-input-finance').val(result_data.DefaultDownPayment);
          //vechile price
          $('#calculator_flyout_vehicle_price_finance')
            .find('.vehicle-price')
            .text(formatter.format(result_data.VehiclePrice));
          //taxes
          $('#calculator_flyout_taxes_finance')
            .find('.taxes')
            .text(formatter.format(result_data.TotalTaxes));
          //Tag fee
          $('#calculator_flyout_tag_fee_finance')
            .find('.tag-fee')
            .text(formatter.format(result_data.TotalFees));
          //Document fee
          $('#calculator_flyout_doc_fee_finance')
            .find('.doc-fee')
            .text(formatter.format(result_data.DocumentFee));
          //Total cost
          $('#calculator_flyout_total_cost_finance')
            .find('.total-cost')
            .text(formatter.format(result_data.AllInPrice));
          //Down payment and trade
          let downPayAndTrade =
            (Number(result_data.DefaultDownPayment.replace(',', '')) + result_data.TradeIn) * -1;
          $('#calculator_flyout_downpayment_trade_finance')
            .find('.down-pay-trade')
            .text(formatter.format(downPayAndTrade));
          //Total value calculator
          $('#calculator_flyout_total_finance')
            .find('.calculator-total')
            .text(formatter.format(result_data.EffectivePrice));
          $('.liIsShippable').show();
        } else {
          //remove section selector
          $('.allInPrice span').text(formatter.format(result_data.EffectivePrice));
          //vechile price
          $('#calculator_flyout_vehicle_price_cash')
            .find('.vehicle-price')
            .text(formatter.format(result_data.VehiclePrice));
          //taxes
          $('#calculator_flyout_taxes_cash')
            .find('.taxes')
            .text(formatter.format(result_data.TotalTaxes));
          //Tag fee
          $('#calculator_flyout_tag_fee_cash')
            .find('.tag-fee')
            .text(formatter.format(result_data.TotalFees));
          //Document fee
          $('#calculator_flyout_doc_fee_cash')
            .find('.doc-fee')
            .text(formatter.format(result_data.DocumentFee));
          //Total cost
          $('#calculator_flyout_total_cost_cash')
            .find('.total-cost')
            .text(formatter.format(result_data.AllInPrice));
          //Down payment and trade
          let downPayAndTrade =
            (Number(result_data.DefaultDownPayment.replace(',', '')) + result_data.TradeIn) * -1;
          $('#calculator_flyout_downpayment_trade_cash')
            .find('.down-pay-trade')
            .text(formatter.format(downPayAndTrade));
          //Total value calculator
          $('#calculator_flyout_total_cash')
            .find('.calculator-total')
            .text(formatter.format(result_data.EffectivePrice));
          $('.liIsShippable').show();
        }

        //SAECHO-785 changes
        $('.calculator_flyout--footer a').attr('href', '/car/' + priceRequest.VIN + '?cf=1');
        //APR
        if (result_data.APR != null) {
          document.getElementById('apr-rate').innerText = result_data.APR;
        }
      } else {
        section
          .find('.payment_card--price')
          .html(formatter.format(0) + `<sub>${calcObjects.priceSuffixText}<sub>`);
        console.log('No results from API');
      }
    },
    error: function (error) {
      console.log(error);
    },
    complete: function (xhr) {
      $('.calculator_icon').css('visibility', 'hidden');
      $('.payment_card').show();
      $('.payment_card').removeClass('active');
    },
  });
}

function readCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function setCookie(name, value, days) {
  if (readCookie(name) == null) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    }
    document.cookie = name + '=' + value + expires + '; path=/';
  } else {
    document.cookie = name + '=' + value + '; path=/';
  }
}
