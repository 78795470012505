<template>
    <div>
        <h6 class="textButtonCarousel-title">
            {{title}}
        </h6>
        <b-navbar class="textButtonCarousel-container">
            <b-navbar-nav ref="carouselScrollArea" :id="carouselScrollAreaId" class="textButtonCarousel-scrollArea">
                <a v-for="txtButton in textBunttonList" :key="txtButton.link" :href="txtButton.link">{{txtButton.text}}</a>
            </b-navbar-nav>
            <b-navbar-nav class="textButtonCarousel-navigation">
                <b-nav-item>
                    <button ref="btnNavLeft" :id="btnNavLeftId" type="button" v-on:click="slideLeft()" class="navLeft navButton" disabled>
                        <svg :width="buttonSize" :height="buttonSize" :viewBox="buttonViewboxSize" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.1722 12L8.22217 7.04999L9.63617 5.63599L16.0002 12L9.63617 18.364L8.22217 16.95L13.1722 12Z" fill="#242C33" />
                        </svg>
                    </button>
                </b-nav-item>
                <b-nav-item>
                    <button prefix="btnNavRight" :id="btnNavRightId" type="button" v-on:click="slideRight()" class="navButton">
                        <div>
                            <svg :width="buttonSize" :height="buttonSize" :viewBox="buttonViewboxSize" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.1722 12L8.22217 7.04999L9.63617 5.63599L16.0002 12L9.63617 18.364L8.22217 16.95L13.1722 12Z" fill="#242C33"/>
                            </svg>
                        </div>
                    </button>
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>

<script>
    import textButton from './textButton.vue';
    export default {
        name: "textButtonCarousel",
        props: {
            textBunttonList: Array,
            carouselId: String,
            title: String,
            btnStyle: String
        },
        data() {
            return {
                buttonSize : 25,
            }
        },
        computed: {
            buttonViewboxSize() {
                return `0 0 ${this.buttonSize} ${this.buttonSize}`;
            },
            carouselScrollAreaId(){
                return this.carouselId+"ScrollArea";
            },
            btnNavRightId(){
                return this.carouselId+"NavRight";
            },
            btnNavLeftId(){
                return this.carouselId+"NavLeft";
            },

        },
        components: {
            textButton
        },
        methods: {
            slideLeft() {
                const carousel = this.$refs.carouselScrollArea;
                let firstHidden = getFirstHiddenToLeft(carousel);
                if(firstHidden) {
                    let parent = carousel;
                
                    carousel.scrollLeft = carousel.scrollLeft - carousel.getBoundingClientRect().width - 12;
                    if (this.$refs.btnNavRight) {
                        this.$refs.btnNavRight.disabled = false;
                    }
                    
                    if (parent.scrollLeft == 0 && this.$refs.btnNavLeft){
                        this.$refs.btnNavLeft.disabled = true;
                    }
                }
                else if (this.$refs.btnNavLeft) {
                    this.$refs.btnNavLeft.disabled = true;
                }
            },
            slideRight()
            {
                const carousel = this.$refs.carouselScrollArea;
                let firstHidden = getFirstHiddenToRight(carousel);
                if(firstHidden) {
                    
                    let scrollWidth = carousel.scrollWidth;                
                
                    let parentRect = carousel.getBoundingClientRect();
                    let childRect = firstHidden.getBoundingClientRect();
                    
                    carousel.scrollLeft += childRect.left-parentRect.left+24;
                    if (this.$refs.btnNavLeft) {
                        this.$refs.btnNavLeft.disabled = false
                    }
                
                    if (Math.floor(carousel.scrollLeft + childRect.right) > scrollWidth && this.$refs.btnNavRight) {
                        this.$refs.btnNavRight.disabled = true;
                    }
                }
                else if (this.$refs.btnNavRight) {
                    this.$refs.btnNavRight.disabled = true;
                }
            },
            validateAndDisableRightArrow() {
                const carousel = this.$refs.carouselScrollArea;
                let scrollWidth = carousel.scrollWidth;
                let elemWidth = carousel.clientWidth;

                if (Math.floor(scrollWidth - carousel.scrollLeft - elemWidth) === 0 && this.$refs.btnNavRight)
                {
                    document.getElementById(this.btnNavRightId).disabled = true;
                }
            },
        },
        mounted(){
            this.validateAndDisableRightArrow();
        },
        watch:{
            textBunttonList: {
                handler () {
                    setTimeout(()=> {
                        this.slideLeft();
                        this.validateAndDisableRightArrow();
                    }, 500)
                }
            },
        }
    };

    function getFirstHiddenToRight(parent)
    {   
        let lastChildTestedVisibility = true;
        let currentChildTested = true;
        let firstHidden;
        let lastChildTested;
    
        Array.prototype.slice.call(parent.childNodes).reverse().forEach(child => {
        
            currentChildTested = isInViewRight(child, parent.getBoundingClientRect().right);
            if(!lastChildTestedVisibility && currentChildTested)
            {
                firstHidden = lastChildTested;
            }
            lastChildTestedVisibility = currentChildTested;
            lastChildTested = child;
        });
        return firstHidden;
    }

    function getFirstHiddenToLeft(parent)
    {    
        let lastChildTestedVisibility = true;
        let currentChildTested = true;
        let firstHidden;
        let lastChildTested;
    
        Array.prototype.slice.call(parent.childNodes).forEach(child => {
            currentChildTested = isInViewLeft(child, parent.getBoundingClientRect().left);
        
            if(!lastChildTestedVisibility && currentChildTested)
            {
                firstHidden = lastChildTested;
            }
            lastChildTestedVisibility = currentChildTested;
            lastChildTested = child;
        });
        return firstHidden;
    }

    function isInViewRight(element, parent) {
        const rect = element.getBoundingClientRect();
        return (
        rect.right < parent
        );
    }

    function isInViewLeft(element, parent) {
        const rect = element.getBoundingClientRect();
        return (
        rect.left > parent
        );
    }
</script>

<style scoped>
.textButtonCarousel-title{
    color: var(--park-gray-0);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-bottom: 24px;
}
.textButtonCarousel-container{
    width: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
    background-color: var(--base-white-100);
    border: 1px solid var(--park-gray-80);
    border-radius: 12px;
    margin-bottom: 0;
    position: relative;
}

.textButtonCarousel-scrollArea {
    white-space: nowrap;
    overflow: hidden; 
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth !important;
    -webkit-scroll-behavior: smooth;
    width: 95%;
}

@media (min-width:992px){
    .textButtonCarousel-scrollArea{
        box-shadow: inset 15px 0 9px -7px var(--base-white-100);
    }
}

.textButtonCarousel-scrollArea::-webkit-scrollbar{
    display: none;
}

.textButtonCarousel-scrollArea a{
    padding-left: 24px;
    padding-right: 24px;
}

.textButtonCarousel-scrollArea:not(:first-child):not(:last-child){
    padding-left: 24px;
    padding-right: 24px;
}

.textButtonCarousel-scrollArea li:first-child{
    padding-right: 24px;
}

.textButtonCarousel-scrollArea li:last-child{
    padding-left: 24px;
}

.textButtonCarousel-navigation{
    position: absolute;
    right: 0;
    padding: 0 16px;
    display: flex;
    border-left: 1px solid var(--park-gray-80);
    background-color: var(--base-white-100);
}

@media (min-width:992px){
    .textButtonCarousel-navigation{
        box-shadow: -15px 0px 15px 0px var(--base-white-100);
    }
}


.navLeft{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.navButton{
    background-color: transparent;
    border: 0;
    width: fit-content;
}
.navButton:disabled svg {
    opacity:0.5;
}
</style>