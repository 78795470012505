<template>
  <div>
    <label
      class="switch"
      :for="componentName"
      @click.prevent="updateToggle"
      @keyup.enter="updateToggle"
      tabindex="0"
    >
      <input
        type="checkbox"
        :checked="value"
        :disabled="disabled"
        :id="componentName"
      />
      <span
        class="slider round"
        :class="{ 'toggle-disabled': disabled }"
      ></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "RoundCheckToggleSwitch",
  props: {
    value: Boolean,
    disabled: false,
    componentName: String,
  },
  data() {
    return { model: this.value };
  },
  methods: {
    updateToggle() {
      if (!this.disabled) {
        this.$emit("update", !this.value);
      }
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 27px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.toggle-disabled {
  background-color: #d4d7d9;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 3px;
  bottom: 3.2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5c9c08;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  background-image: url(../../assets/icons/toggleOn.svg);
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
