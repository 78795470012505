<template>
  <div v-if="toggleComponentVisible" data-role="toggle-shopping-options">
    <div class="center-options">
      <TextToggle :options="toggleOptions" v-model="shopByStore" />
    </div>
    <div class="shoppingRadius w-100">
      <div v-if="!shopByStore" class="dropdown">
        <div class="mb-4 mx-4">
          <label class="shopByRadiusLabel">{{ $t("Shopping radius") }}</label>
          <Dropdown
            class="custom-select mt-0"
            :options="radiusOptions"
            v-model="selectedRadiusModel"
            data-action="toggle-shop-by-radius"
          />
        </div>
        <div class="storesInRadiusLabel text-center">{{ getText() }}</div>
      </div>
      <FacetDealershipPicker
        v-if="shopByStore"
        label="Select your stores"
        showMoreText="Show more"
        showLessText="Show less"
        data-action="select-dealership"
      >
      </FacetDealershipPicker>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import FacetDealershipPicker from "@components-vue/Dealerships/FacetDealershipPicker.vue";
import TextToggle from "@components-vue/Toggle/TextToggle.vue";
import Dropdown from "@components-vue/Select/Dropdown.vue";

export default {
  name: "ShopByStoreToggle",
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FacetDealershipPicker,
    TextToggle,
    Dropdown,
  },
  data() {
    return {
      shopOptions: [
        this.$t("Distance Shipping Toggle Text Left"),
        this.$t("Distance Shipping Toggle Text Right"),
      ],
      currentShopOptionIndex: 1,
      toggleComponentVisible: true,
      IsShowZipFlyout: false,
      storesInRadius: 0,
    };
  },
  computed: {
    ...mapState("srp", {
      radius: (state) => state.radius,
      dealerships: (state) => state.dealerships || [],
      zip: (state) => state.zip,
      nearestDealership: (state) => state.nearestDealership,
      shipToStoreRadius: (state) => state.shipToStoreRadius,
    }),
    shopByStore: {
      get() {
        return this.$store.state.srp.shopByStore;
      },
      set(value) {
        this.$store.commit("srp/setShopByStore", value);
      },
    },
    selectedRadiusModel: {
      get() {
        return this.radius;
      },
      set(value) {
        let radius = parseInt(value);
        this.$store.commit("srp/setRadius", radius);
        this.$root.$emit("filter-updated-srp");
      },
    },
    radiusOptions() {
      return this.fields?.zipRadiusOptions?.map((x) => {
        const zipRadiusValue = parseInt(x.fields?.RadiusValue.value);
        const zipRadiusDisplay = x.fields?.DisplayText.value;
        return { label: zipRadiusDisplay, value: zipRadiusValue };
      });
    },
    storeCount() {
      return this.radius == -1
        ? this.dealerships.length
        : this.dealerships.filter(
            (dealership) => dealership.distance <= this.radius
          ).length;
    },
    toggleOptions: function () {
      return [
        { text: this.$t("Distance Shipping Toggle Text Left"), value: false },
        { text: this.$t("Distance Shipping Toggle Text Right"), value: true },
      ];
    },
  },
  methods: {
    ...mapMutations("srp", ["setIncludedDealerships", "setShipToStore"]),
    getText() {
      if (this.storeCount == 1 && this.radius != -1) {
        return this.replaceText(
          this.$t("There Is Store Within Miles"),
          [";xMiles;"],
          [this.radius]
        );
      } else if (this.storeCount > 1 && this.radius != -1) {
        return this.replaceText(
          this.$t("There Are Stores Within Miles"),
          [";nStores;", ";xMiles;"],
          [this.storeCount, this.radius]
        );
      } else if (this.radius == -1) {
        return this.replaceText(
          this.$t("There Are Stores Nationwide"),
          [";nStores;"],
          [this.storeCount]
        );
      } else if (this.storeCount == 0) {
        return "There are 0 stores within " + this.radius + " mi";
      }
    },
    replaceText(text, search, replacements) {
      let modifiedText = text;
      for (var i = 0; i < replacements.length; i++) {
        modifiedText = modifiedText.replace(search[i], replacements[i]);
      }
      return modifiedText;
    },
  },
  watch: {
    shopByStore: function (selected) {
      if (selected && this?.nearestDealership?.dealerId) {
        this.setIncludedDealerships([this.nearestDealership.dealerId]);
      } else {
        this.setIncludedDealerships([]);
      }
      this.$root.$emit("filter-updated-srp");
    },
  },
};
</script>

<style scoped>
.shopByRadiusLabel {
  color: #a2a6ab;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}
.storesInRadiusLabel {
  color: #242c33;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 16px;
}
.center-options {
  padding: 0px 0px 16px 0px;
}
.dropdown {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  width: 100%;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.radiusDropdown {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.dropdown-menu li {
  padding: 10px;
}

.dropdown-menu li a {
  color: #333;
  text-decoration: none;
  display: block;
}

.dropdown-menu li a:hover {
  background-color: #e0e0e0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
