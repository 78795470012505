const HierarchicalFacetFactory = function () {
    function canHandle(type) {
        return type === "hierarchical";
    }

    function create(name, value) {
        function toQueryString() {
            let exctractedString = extractFacetStringFromHierarchy(value.facetValue);
            return `${name}:${exctractedString}`
        };

        return {
            toQueryString
        };
    }

    function extractFacetStringFromHierarchy(obj) {
        let combinations = [];
    
        function extractCombos(prefix, currentObj) {
            for (let key in currentObj) {
                if (currentObj[key].selected) {
                    let tempCombo = prefix ? `${prefix}|${key}` : key;
    
                    if (Object.values(currentObj[key]).some(x => x?.selected === true)) {
                        extractCombos(tempCombo, currentObj[key]);
                    } else {
                        combinations.push(tempCombo);
                    }
                }
            }
        }
    
        extractCombos('', obj);
        return combinations.join('~');
    }

    return {
        canHandle,
        create
    };
}();

const NumericRangeFacetFactory = function () {
    function canHandle(type) {
        return type === "numericRange";
    }

    function create(name, value) {
        function toQueryString() {
            return `${name}:${value.min}|${value.max}`
        };

        return {
            toQueryString
        };
    }

    return {
        canHandle,
        create
    };
}();

const StringFacetFactory = function () {
    function canHandle(type) {
        return type === "string";
    }

    function create(name, value) {
        function toQueryString() {
            return `${name}:${value.facetValue.join('~')}`
        };

        return {
            toQueryString
        };
    }

    return {
        canHandle,
        create
    };
}();

export const FacetFiltersManager = function () {
    const facetFilters = [];
    const facetFilterTypes = [NumericRangeFacetFactory, StringFacetFactory, HierarchicalFacetFactory];

    const addFacetFilter = function (filter) {
        facetFilters.push(filter);
    }

    const createFacetFilter = function (name, value) {
        for (let filterKey in facetFilterTypes) {
            let facetFilterType = facetFilterTypes[filterKey];

            if (facetFilterType.canHandle(value.type)) {
                return facetFilterType.create(name, value);
            }
        }
        throw `Unable to handle ${name} facet of type ${value.type}`;
    }

    const toQueryString = function () {
        if (facetFilters.length === 0) {
            return '';
        }

        return facetFilters.map(x => x.toQueryString()).join(',');
    }

    return {
        addFacetFilter,
        createFacetFilter,
        toQueryString
    }
}