import Cookies from 'js-cookie';
import Vue from 'vue';
import { facetMetaData } from '@util/facetHelper.js';
import eventBus from '@assets/js/eventBus';
import { GenerateCapiEventId } from '@assets/js/capiFunctions';

/* https://v2.ssr.vuejs.org/guide/data.html#data-store */
export default () => ({
  namespaced: true,
  state: () => ({
    queryParams: {
      page: 0,
    },
    loading: false,
    filterSelected: false,
    distanceShippingFilterSelected: false,
    mpgEngineFilterSelected: false,
    colorFilterSelected: false,
    bodyStyleFilterSelected: false,
    yearFilterSelected: false,
    mileageFilterSelected: false,
    driveTypeFilterSelected: false,
    priceFilterSelected: false,
    makeModelTrimFilterSelected: false,
    extColorToggleSelected: true,
    priceToggleSelected: true,
    shipToStore: true,
    shipToStoreRadius: true,
    shopByStore: false,
    filterZipFlyout: false,
    featureFilterSelected: false,
    zip: '28205',
    radius: -1,
    defaultRadius: -1,
    take: 24,
    srpVehiclesData: null,
    dealerships: null,
    includedDealerships: [],
    facetFilters: {},
    selectedFacetFilters: {},
    resetSelectedFacetItems: false,
    nearestDealership: null,
    sortType: {
      sortBy: 'distance',
      sortDirection: 'asc',
      sortName: 'Distance: Nearest',
    },
    askUsModalTitle: null,
    facetChipsExpanded: false,
    hiddenChips: 0,
    facetCounts: {},
    recentSelectedFilter: '',
    urlCount: 0
  }),
  getters: {
    formattedVehicleAmount(state) {
      return (state.srpVehiclesData?.resultCount ?? 0).toLocaleString().replace('.', ',');
    },
    getVehicleByVin: (state) => (selectedVin) => {
      const selectedVehicle = state.srpVehiclesData.items.find(
        (vehicle) => vehicle.vin === selectedVin
      );
      return selectedVehicle;
    },
    getDynamicLinkData(state) {
      return state.srpVehiclesData?.dynamicLinks;
    },
    vehicleCount(state) {
      return state.srpVehiclesData?.resultCount ?? 0;
    },
    metaDataContent(state) {
      if (Object.keys(state.facetFilters).length > 0) {
        const relevantFilters = ['extcolor', 'year', 'mmt', 'bodystyle'];
        const allFilternames = Object.keys(state.facetFilters);
        const filtersICareAbout = allFilternames.filter((name) => relevantFilters.includes(name));
        //calculate how many total selected filters(facets) there are (that we care about [color, make, model, body style, year])
        const selectedFiltersCount = filtersICareAbout.reduce((counter, current) => {
          //count up how many filters
          switch (current) {
            case 'extcolor':
            case 'bodystyle':
              return counter + state.facetFilters[current].facetValue.length;
            case 'mmt':
              const makes = Object.keys(state.facetFilters[current].facetValue);
              const makesCount = makes.length;

              const modelsCountTotal = makes.reduce((modelsTotal, make) => {
                const models = Object.keys(state.facetFilters[current].facetValue[make]).filter(
                  (model) => model !== 'selected'
                );
                const modelsCount = models.length;
                return modelsTotal + modelsCount;
              }, 0);

              return counter + makesCount + modelsCountTotal;
            case 'year':
              return counter + 2;
            default:
              return counter;
          }
        }, 0);

        //logic for adjusting the title for the specified criteria: https://sonicautomotive.atlassian.net/browse/SAECHO-4788
        if (selectedFiltersCount > 3 || selectedFiltersCount < 1) {
          return null;
        }

        //if less than 3 filters, set the title in priority of facets: Color, year, make, model, body style
        let contentArr = [];
        if (state.facetFilters.extcolor) {
          contentArr = [...state.facetFilters.extcolor.facetValue];
        }
        if (state.facetFilters.year) {
          contentArr = [
            ...contentArr,
            state.facetFilters.year.min,
            '-',
            state.facetFilters.year.max,
          ]; //spread + append new
        }
        if (state.facetFilters.mmt) {
          //iterate over makes for each model
          const makes = Object.keys(state.facetFilters.mmt.facetValue);
          makes.forEach((make) => {
            const models = Object.keys(state.facetFilters.mmt.facetValue[make]).filter(
              (model) => model !== 'selected' //because dataStructure is: {make {model1, model2, selected {true/false} } }
            );
            contentArr = [...contentArr, make, ...models];
          });
        }
        if (state.facetFilters.bodystyle) {
          contentArr = [...contentArr, ...state.facetFilters.bodystyle.facetValue];
        }
        const content = contentArr.join(' ');
        return content;
      }
    },
    metaDataLocation(state) {
      if (!state.shopByStore || state.includedDealerships.length != 1) {
        return null;
      }
      const dealerId = state.includedDealerships[0];
      const dealer = state.dealerships.find((d) => d.dealerId == dealerId);
      return dealer?.storeName ?? null;
    },
  },
  mutations: {
    decrementUrlCount(state) {
      state.urlCount--;
    },
    incrementUrlCount(state) {
      state.urlCount++;
    },
    setQueryParams(state, paramObject) {
      if (paramObject?.page) {
        state.queryParams.page = paramObject?.page;
      }
    },
    setPage(state, value) {
      state.queryParams.page = parseInt(value);
    },
    setAskUsModalTitle(state, value) {
      state.askUsModalTitle = value;
    },
    setPageIncrement(state, value) {
      state.queryParams.page = parseInt(state.queryParams.page) + parseInt(value);
    },
    setFeatureFilterSelected(state, value) {
      state.featureFilterSelected = value;
    },
    setFilterSelected(state, value) {
      if (!value) {
        state.distanceShippingFilterSelected = value;
        state.mpgEngineFilterSelected = value;
        state.colorFilterSelected = value;
        state.bodyStyleFilterSelected = value;
        state.yearFilterSelected = value;
        state.mileageFilterSelected = value;
        state.driveTypeFilterSelected = value;
        state.priceFilterSelected = value;
        state.makeModelTrimFilterSelected = value;
        state.featureFilterSelected = value;
      }
      state.filterSelected = value;
    },
    setSpecificFilterSelected(state, value) {
      let filter = value;

      if (value && value.length > 0) {
        state.filterSelected = true;
        if (filter === 'storename' || filter === 'shiptostore') {
          state.distanceShippingFilterSelected = true;
        } else if (filter === facetMetaData.mmt.key) {
          state.makeModelTrimFilterSelected = true;
        } else if (filter === facetMetaData.bodystyle.key) {
          state.bodyStyleFilterSelected = true;
        } else if (filter === facetMetaData.price.key) {
          state.priceFilterSelected = true;
          state.priceToggleSelected = true;
        } else if (filter === facetMetaData.payment.key) {
          state.priceFilterSelected = true;
          state.priceToggleSelected = false;
        } else if (filter === facetMetaData.miles.key) {
          state.mileageFilterSelected = true;
        } else if (filter === facetMetaData.year.key) {
          state.yearFilterSelected = true;
        } else if (filter === facetMetaData.feature.key) {
          state.featureFilterSelected = true;
        } else if (
          filter === facetMetaData.hmpg ||
          filter === facetMetaData.enginedescription.key
        ) {
          state.mpgEngineFilterSelected = true;
        } else if (
          filter === facetMetaData.drivetype.key ||
          filter === facetMetaData.transmission.key
        ) {
          state.driveTypeFilterSelected = true;
        } else if (filter === facetMetaData.extcolor.key) {
          state.colorFilterSelected = true;
          state.extColorToggleSelected = true;
        } else if (filter === facetMetaData.intcolor.key) {
          state.colorFilterSelected = true;
          state.extColorToggleSelected = false;
        }
      }
    },
    setDistanceShippingFilterSelected(state, value) {
      state.distanceShippingFilterSelected = value;
    },
    setMakeModelTrimFilterSelected(state, value) {
      state.makeModelTrimFilterSelected = value;
    },
    setColorFilterSelected(state, value) {
      state.colorFilterSelected = value;
    },
    setMpgEngineFilterSelected(state, value) {
      state.mpgEngineFilterSelected = value;
    },
    setBodyStyleFilterSelected(state, value) {
      state.bodyStyleFilterSelected = value;
    },
    setYearFilterSelected(state, value) {
      state.yearFilterSelected = value;
    },
    setMileageFilterSelected(state, value) {
      state.mileageFilterSelected = value;
    },
    setDriveTypeFilterSelected(state, value) {
      state.driveTypeFilterSelected = value;
    },
    setPriceFilterSelected(state, value) {
      state.priceFilterSelected = value;
    },
    setShopByStore(state, value) {
      state.shopByStore = value;
      setShopLocationCookie({ radius: state.radius });
    },
    setFilterZipFlyout(state, value) {
      state.filterZipFlyout = value;
    },
    setRecentSelectedFilter(state, value) {
      state.recentSelectedFilter = value;
    },
    setShipToStore(state, value) {
      let obj = {
        Removed: `Ship To Store`,
        event: '',
      };

      if (value == false) {
        obj = {
          Removed: `Ship To Store`,
          event: 'Filter Removed',
        };
      } else if (value == true) {
        obj = {
          Removed: `Ship To Store`,
          event: 'Filter Applied',
        };
      }

      eventBus.$emit('event', obj);
      state.shipToStore = value;
    },
    setShipToStoreRadius(state, value) {
      state.shipToStoreRadius = value;
    },
    initializeZip(state, value) {
      if (!value || state.zip === value) return;
      state.zip = value;
    },
    setZip(state, value) {
      if (!value || state.zip === value) return;
      if (Cookies.set) {
        Cookies.set('ep_selectedZipcode', value, { expires: 3650 });
        Cookies.set('ep_zipstatus', 'ManualEntry', { expires: 3650 });
      }
      state.zip = value;
      let obj = {
        event: 'zip code selected',
        entered: value,
      };

      eventBus.$emit('event', obj);
    },
    initializeRadius(state, value) {
      if (!value || state.radius === value) return;
      state.radius = value;
    },
    setRadius(state, value) {
      state.radius = value;
      setShopLocationCookie({ radius: state.radius });
    },
    setTake(state, value) {
      state.take = value;
    },
    setSrpVehiclesData(state, value) {
      state.srpVehiclesData = value || 0;
      let facetKeys = Object.keys(state.facetFilters);
      let facetValues = Object.values(state.facetFilters);
      let appliedFacets = [];
      let includedDealershipsString = Object.values(state.includedDealerships);

      facetValues.forEach((nestedObj, index) => {
        if (nestedObj && nestedObj.type) {
          if (
            nestedObj.type === 'numericRange' &&
            nestedObj.min !== undefined &&
            nestedObj.max !== undefined
          ) {
            appliedFacets.push(`${facetKeys[index]}: ${nestedObj.min}-${nestedObj.max}`);
          } else if (nestedObj.type === 'string' && nestedObj.facetValue) {
            appliedFacets.push(`${facetKeys[index]}:${nestedObj.facetValue}`);
          } else if (nestedObj.type === 'hierarchical' && nestedObj.facetValue) {
            appliedFacets.push(`${facetKeys[index]}:${getKeys(nestedObj.facetValue)}`);
          }
        }
      });

      appliedFacets.push(...includedDealershipsString);
      appliedFacets = appliedFacets.join(', ');

      let viewResultsObj = {
        applied: appliedFacets ?? '',
        event: 'View Results Clicked',
        nearestStore: state.nearestDealership?.dealerId ?? '',
        resultCount: state.srpVehiclesData?.resultCount ?? 0,
      };

      eventBus.$emit('event', viewResultsObj);

      let capiEventId = GenerateCapiEventId('top cars Information');
      let obj = {
        event: 'top cars Information',
        CAPI_event_id: capiEventId,
      };
      let locationName, makeName, priceName, modelName, currencyName, vinName, yearName, zipName;
      for (let i = 0; i < 5; i++) {
        locationName = `Location${i + 1}`;
        makeName = `make${i + 1}`;
        priceName = `Price${i + 1}`;
        modelName = `model${i + 1}`;
        currencyName = `currency${i + 1}`;
        vinName = `vin${i + 1}`;
        yearName = `year${i + 1}`;
        zipName = `zip${i + 1}`;

        obj[locationName] = value.items[i]?.dealership || null;
        obj[makeName] = value.items[i]?.make || null;
        obj[priceName] = value.items[i]?.sellingPrice || null;
        obj[modelName] = value.items[i]?.model || null;
        obj[currencyName] = 'USD';
        obj[vinName] = value.items[i]?.vin || null;
        obj[yearName] = value.items[i]?.year || null;
        obj[zipName] = state.zip || null;
      }
      eventBus.$emit('event', obj);
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setDealerships(state, value) {
      if (!state.dealerships) state.dealerships = [];
      state.dealerships.length = 0;
      value.forEach((dealer) => {
        state.dealerships.push(dealer);
      });
    },
    setIncludedDealerships(state, value) {
      state.includedDealerships = value ?? [];
      if (state.includedDealerships.length > 0) {
        state.shopByStore = true;
        state.shipToStore = false;
        setShopLocationCookie({ dealer: state.includedDealerships });
      }
      else {
        state.shipToStore = state.shipToStoreRadius;
      }
    },
    setFacetCounts(state, value) {
      state.facetCounts = value;
    },
    setSrpNearestDealership(state, value) {
      state.nearestDealership = value;
    },
    setResetSelectedFacetItems(state, value) {
      state.resetSelectedFacetItems = value;
    },
    setDefaultRadius(state, value) {
      state.defaultRadius = value;
    },
    setRemoveFacet: function (state, value) {
      let facetFilters = state.facetFilters[value];
      let facetFiltersValues = Object.values(facetFilters);
      let obj = {
        Removed: `${value}`,
        event: 'Filter Removed',
      };
      if (
        typeof facetFiltersValues[2] != undefined &&
        facetFiltersValues[2] != null &&
        facetFiltersValues[2] == 'numericRange'
      ) {
        obj = {
          Removed: `${value}:${facetFiltersValues[0]}-${facetFiltersValues[1]}`,
          event: 'Filter Removed',
        };
      }

      Vue.delete(state.facetFilters, value);

      eventBus.$emit('event', obj);
    },
    setStringFacetFilter: function (state, value) {
      let facetName = value['name'];
      let facetValue = value['value'];

      if (typeof facetValue === undefined || facetValue === null) {
        Vue.set(state.facetFilters, facetName, undefined);
      } else {
        Vue.set(state.facetFilters, facetName, { facetValue, type: 'string' });
        let obj = {
          Applied: `${facetName}:${facetValue}`,
          event: 'Filter Applied',
        };
        eventBus.$emit('event', obj);
      }
    },
    setSelectedFacetItems: function (state, value) {
      let facetName = value['name'];
      let facetValue = value['value'];

      if (typeof facetValue === undefined || facetValue === null) {
        Vue.set(state.selectedFacetFilters, facetName, undefined);
      } else {
        Vue.set(state.selectedFacetFilters, facetName, facetValue);
      }
    },
    setHierarchicalFacetFilter: function (state, value) {
      let facetName = value['name'];
      let facetValue = value['value'];

      if (typeof facetValue === undefined || facetValue === null) {
        Vue.set(state.facetFilters, facetName, undefined);
      } else {
        Vue.set(state.facetFilters, facetName, { facetValue, type: 'hierarchical' });

        let filterValue = getKeys(facetValue);
        let obj = {
          Applied: `${facetName}:${filterValue}`,
          event: 'Filter Applied',
        };
        eventBus.$emit('event', obj);
      }
    },
    setNumericFacetFilter: function (state, value) {
      let facetName = value['name'];
      let facetValue = value['value'];

      if (typeof facetValue === undefined || facetValue === null) {
        Vue.set(state.facetFilters, facetName, undefined);
      } else {
        Vue.set(state.facetFilters, facetName, { ...facetValue, type: 'numericRange' });
        let obj = {
          Applied: `${facetName}:${facetValue.min} - ${facetValue.max}`,
          event: 'Filter Applied',
        };
        eventBus.$emit('event', obj);
      }
    },
    setSortType(state, value) {
      state.sortType = value;
    },
    setResetSelectedFacetItemList(state, value) {
      let filterType = value;

      if (state.facetFilters[filterType]) {
        let excludedVals = [];
        if (state.selectedFacetFilters[filterType]) {
          excludedVals = state.selectedFacetFilters[filterType];
        }

        state.facetFilters[filterType].facetValue.forEach((facetValue) => {
          if (!state.selectedFacetFilters[filterType]) {
            Vue.set(state.selectedFacetFilters, filterType, [facetValue]);
          } else if (
            state.selectedFacetFilters[filterType] &&
            !state.selectedFacetFilters[filterType].hasOwnProperty(facetValue) &&
            !excludedVals.includes(facetValue)
          ) {
            let val = [facetValue];
            let includedVals = state.selectedFacetFilters[filterType];
            Vue.set(state.selectedFacetFilters, filterType, val.concat(includedVals));
          }
        });
      }
    },
    setClearFacets(state) {
      Object.keys(state.facetFilters).forEach((key) => {
        Vue.delete(state.facetFilters, key);
      });

      Object.keys(state.selectedFacetFilters).forEach((key) => {
        Vue.delete(state.selectedFacetFilters, key);
      });
    },
    resetStoreDefaults(state, layoutPath) {
      if (!layoutPath) {
        state.includedDealerships = [];
      }
      state.queryParams.page = 0;
      state.shipToStore = true;
      state.shopByStore = false;
      state.filterZipFlyout = false;
      state.radius = state.defaultRadius ?? -1;
      state.take = 24;
      state.sortType.sortBy = 'distance';
      state.sortType.sortDirection = 'asc';
      state.sortType.sortName = 'Distance: Nearest';
      state.facetChipsExpanded = false;
      state.hiddenChips = 0;
      state.recentDeselectedFilter = '';
      state.recentSelectedFilter = '';
      setShopLocationCookie({ radius: state.radius });
    },
    setFacetChipsExpanded(state, value) {
      state.facetChipsExpanded = value;
    },
    setHiddenChipCount(state, value) {
      state.hiddenChips = value;
    },
    setExtColorToggleSelected(state, value) {
      state.extColorToggleSelected = value;
    },
    setPriceToggleSelected(state, value) {
      state.priceToggleSelected = value;
    },
    setFacetFilters(state, value) {
      Vue.set(state, "facetFilters", {});
      if (value) {
        for (let propertyName in value) {
          Vue.set(state.facetFilters, propertyName, value[propertyName]);
        }
      }
    },
  },
});

//function to recursively get object keys and filter "selected" out
const getKeys = function (obj) {
  return Object.keys(obj)
    .flatMap((k) => (Object(obj[k]) === obj[k] ? [k, ...getKeys(obj[k])] : k))
    .filter((item) => item !== 'selected');
};

const setShopLocationCookie = function (obj) {
  if (Cookies.set) {
    if (obj['radius'])
      Cookies.set('ep_shopLocation', JSON.stringify({ radius: obj['radius'] }), {
        path: '/used-cars',
      });
    else if (isNaN(obj['radius']) && typeof obj['radius'] !== 'undefined')
      Cookies.set('ep_shopLocation', JSON.stringify({ radius: -1 }), { path: '/used-cars' });
    else if (obj.hasOwnProperty('dealer') && obj['dealer'])
      Cookies.set('ep_shopLocation', JSON.stringify({ dealers: obj['dealer'] }), {
        path: '/used-cars',
      });
  }
};
