let resetDefault = false;

export function onForgotPasswordMount() {
  $("#emailId").focus(function () {
    focus("#emailId");
  });

  $("#emailId").blur(function (key) {
    focusOut("#emailId");
  });

  $("#emailId").keyup(function () {
    validateRequired("Email", "#emailId");
  });

  $("#btnSignIn").click(function () {
    $("#forgotPasswordContainer").removeClass(["show_panel", "show-forgotpassword"]);
    $("#signInContainer").addClass(["show_panel", "show-signIn"]);
    $(".signInOuterContainer").removeClass("d-none");

    $(".forgotpassword-content").show();
    $(".form-content.forgotpasswordSentEmail-content").hide();
    $(".forgotPasswordOuterContainer").toggleClass("d-none", true);
  });

  $(".form-content.forgotpasswordSentEmail-content").hide();

  $("#btnSend").attr("id", "submitForgotPassword");

  $("#submitForgotPassword").click(function (e) {
    e.preventDefault();
    if (validateRequired("Email", "#emailId") == true) {
      sendForgotPasswordApi(false);
    }
  });

  $("#resendEmailLink").click(function (e) {
    e.preventDefault();
    if (validateRequired("Email", "#emailId") == true) {
      sendForgotPasswordApi(true);
    }
  });
}

function sendForgotPasswordApi(resend) {
  $.ajax({
    datatype: "json",
    type: "Post",
    url: "/api/Sitecore/ForgotPassword/SendResetPasswordLink",
    async: true,
    data: JSON.stringify({
      emailAddress: $("#emailId").val(),
    }),
    contentType: "application/json; charset=utf-8",
    beforeSend: function () {
      $("#divFlyoutWait").show();
    },
    success: function (data) {
      $("#divFlyoutWait").hide();
      if (data != null && data != undefined) {
        if (data.Status == "2" || data.Status == 2) {
          //Email not exist in database
          $("#emailError").removeClass().addClass("field-validation-error");

          $("#emailError")
            .empty()
            .append(
              '<span id="emailId-error" class="">' + data.Message + "</span>"
            );
        } else if (data.Status == "1" || data.Status == 1) {
          if (resend == true) $("#emailSentDivId").text("Email was re-sent!");
          $(".form-content.forgotpasswordSentEmail-content").show();
          $(".form-content.forgotpassword-content").hide();
        }
      }
      console.log(data.Message);
    },
    error: function (data) {
      $("#divFlyoutWait").hide();
      console.log(data);
    },
  });
}

export const focus = (id) => {
  if (!$(id).parent().hasClass("focused")) $(id).parent().addClass("focused");
  if (!$(id).parent().hasClass("Filled")) $(id).parent().addClass("Filled");
};

export const focusOut = (id) => {
  $(id).parent().removeClass("Filled");
  if ($(id).val().length === 0) $(id).parent().removeClass("focused");
  else if (!$(id).parent().hasClass("Active"))
    $(id).parent().addClass("Active");
};

function validateRequired(name, id) {
  if ($(id).val().length == 0) {
    $(id).removeClass("valid");
    $(id).addClass("input-validation-error");
    var spanError = document.createElement("SPAN");
    spanError.appendChild(document.createTextNode(name + " is required."));
    var span = $(id).parent().find(".field-validation-valid")[0];
    if (span) {
      if (span.innerHTML.length <= 0) span.appendChild(spanError);
      $(id)
        .parent()
        .find(".field-validation-valid")
        .addClass("field-validation-error");
      $(id)
        .parent()
        .find(".field-validation-valid")
        .removeClass("field-validation-valid");
    }
    return false;
  } else {
    $(id).addClass("valid");
    $(id).removeClass("input-validation-error");
    var span = $(id).parent().find(".field-validation-error")[0];
    if (span) {
      if (span.innerHTML.length > 0) span.innerHTML = "";
      $(id)
        .parent()
        .find(".field-validation-error")
        .addClass("field-validation-valid");
      $(id)
        .parent()
        .find(".field-validation-error")
        .removeClass("field-validation-error");
    }
    return true;
  }
}
