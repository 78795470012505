<template>
  <transition name="slide">
    <div data-component-name="feature-filter">
      <div v-show="!featureFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="!!this.facetFilters[filterType]"
          :title="fields.MenuTitle.value"
        />
      </div>
      <div v-show="featureFilterSelected" class="full-height-container">
        <div class="title-block">
          <sc-text :field="fields.MenuTitle" class="title-text" />
        </div>
        <div class="featureFilter" v-if="selectedFeatures && selectedFeatures.length > 0">
          <FacetList
            class="facet-list"
            :data="selectedFeatures"
            :filterType="filterType"
          ></FacetList>
        </div>
        <div class="menu-title-block">
          <sc-text :field="fields.CommonFeaturesTitle" class="menu-title-text" />
        </div>
        <div class="featureFilter">
          <FacetList
            class="facet-list"
            :data="popularFeatureOptions"
            :filterType="filterType"
          ></FacetList>
        </div>
        <div class="menu-title-block">
          <sc-text :field="fields.OtherFeaturesTitle" class="menu-title-text" />
        </div>
        <div class="featureFilter">
          <FacetList
            class="facet-list"
            :data="otherFeatureOptions"
            :filterType="filterType"
          ></FacetList>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import FacetList from '@components-vue/List/FacetList.vue';
import FilterMenuLineItem from '../FilterMenuLineItem.vue';
import { facetMetaData } from '@util/facetHelper.js';

export default {
  name: 'FeatureFilter',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FacetList,
    ScText: Text,
    FilterMenuLineItem,
  },
  data() {
    return {
      filterType: facetMetaData.feature.key,
      selectedFeatures: [],
      popularFeatureOptions: [],
      otherFeatureOptions: [],
    };
  },
  computed: {
    ...mapState('srp', {
      facetFilters: (state) => state.facetFilters ?? {},
      filterSelected: (state) => state.filterSelected,
      featureFilterSelected: (state) => state.featureFilterSelected,
      featureCount: (state) => state.facetCounts?.feature || {},
    }),
    facetListCommonFeatures() {
      return this.fields?.CommonFeatures?.map((x) => ({
        name: x.fields?.Feature?.value,
        value: this.getCount(x),
        scImage: x.fields?.FeatureImage,
      }));
    },
    facetListOtherFeatures() {
      return this.fields?.OtherFeatures?.map((x) => ({
        name: x.fields?.Feature?.value,
        value: this.getCount(x),
      }));
    },
    getCount() {
      return (x) =>
        this.featureCount.hasOwnProperty(x.fields?.Feature?.value)
          ? this.featureCount[x.fields?.Feature?.value]
          : 0;
    },
    /* from facetListCommonFeatures and facetListOtherFeatures, fish out the currently selected facets */
    selectedFeatureFacets() {
      return this.facetFilters.feature?.facetValue
        ?.map((filter) => {
          return (
            this.facetListCommonFeatures.find((commonFacet) => {
              return commonFacet.name === filter;
            }) ||
            this.facetListOtherFeatures.find((otherFacet) => {
              return otherFacet.name === filter;
            })
          );
        })
        .filter((item) => item); // remove undefined
    },
  },
  methods: {
    ...mapMutations('srp', [
      'setFilterSelected',
      'setFeatureFilterSelected',
      'setResetSelectedFacetItems',
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setFeatureFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    updateStore(data) {
      this.$store.commit('srp/setStringFacetFilter', data);
      this.$root.$emit('filter-updated-srp');
    },
  },
  watch: {
    featureFilterSelected(isSelected) {
      if (isSelected) {
        /* Upon entry to the filter, calculate which facets belong at the top, and which belong under the sub headings */
        this.selectedFeatures = this.selectedFeatureFacets || [];
        this.popularFeatureOptions = this.facetListCommonFeatures.filter((item) => {
          return !this.selectedFeatures.some((facet) => facet.name === item.name);
        });
        this.otherFeatureOptions = this.facetListOtherFeatures.filter((item) => {
          return !this.selectedFeatures.some((facet) => facet.name === item.name);
        });
        this.$root.$emit('fetch-facet-count', this.filterType);
      }
    },
    facetFilters: {
      handler: async function () {
        if (this.featureFilterSelected) {
          this.$root.$emit('fetch-facet-count');
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.title-block {
  padding: 16px 24px 32px;
  justify-content: flex-start;
  align-items: center;
  gap: 84px;
  display: inline-flex;
  width: 100%;
}
.title-text {
  color: var(--park-gray-0);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  word-wrap: break-word;
}
.menu-title-text {
  color: var(--park-gray-0);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
.menu-title-block {
  padding: 16px 24px;
  justify-content: flex-start;
  align-items: center;
  gap: 84px;
  display: inline-flex;
  width: 100%;
}
.facet-list {
  width: 100%;
}
.featureFilter {
  padding-bottom: 32px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 8px;
  border: 1px solid #ffffff;
}

.full-height-container {
  height: 88vh; /* 100% of the viewport height */
  overflow-y: auto; /* Enable vertical scrolling if the content overflows */
}
</style>
