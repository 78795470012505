<template>
  <div id="h-list-container">
    <span v-if="title" id="h-list-title">{{ title }}</span>
    <div v-if="data.length > 0">
      <li
        v-for="(item, index) in data"
        v-if="
          isSelected(`${item.fullName}~${id}`) && !item.hidden && item.value
        "
        class="h-list-element"
        :class="{ selected: isSelected(`${item.fullName}~${id}`) }"
      >
        <div
          class="divItemInfo"
          :class="{
            'hover-underline': isSelected(`${item.fullName}~${id}`),
          }"
          :style="{
            width:
              isSelected(`${item.fullName}~${id}`) && subMenu ? '80%' : '100%',
          }"
          @click="deselect(`${item.fullName}~${id}`)"
          @keyup.enter="deselect(`${item.fullName}~${id}`)"
          tabindex="0"
        >
          <div>
            <img
              v-if="item.descendant"
              src="@icons/ArrowPath.svg"
              alt="Option"
              class="imgArrow"
            />
            <img
              v-if="isSelected(`${item.fullName}~${id}`)"
              src="@icons/check.png"
              alt="Check"
              class="imgCheck"
            />
            <span>
              <span id="lblName">{{ item.name }}</span>
              <span id="lblCount" class="ml-1"
                >({{ item.value.toLocaleString() }})</span
              >
            </span>
          </div>
        </div>
        <div
          v-if="
            !item.lastChild && subMenu && isSelected(`${item.fullName}~${id}`)
          "
          class="h-sub-menu d-flex align-items-center"
          @click="enterSubMenu(item.name)"
          @keyup.enter="enterSubMenu(item.name)"
          tabindex="0"
        >
          {{ item.subMenu ? item.subMenu : subMenu
          }}<img src="@icons/Path_MMT.svg" class="arrow-icon ml-1" />
        </div>
      </li>
      <li
        v-for="(item, index) in data"
        v-if="
          isNotSelected(`${item.fullName}~${id}`) && !item.hidden && item.value
        "
        class="h-list-element"
        :class="{ selected: isSelected(`${item.fullName}~${id}`) }"
      >
        <div
          class="divItemInfo"
          :class="{
            'hover-underline': isInSelection(`${item.fullName}~${id}`),
          }"
          :style="{
            width:
              isInSelection(`${item.fullName}~${id}`) && subMenu
                ? '80%'
                : '100%',
          }"
          @click="select(`${item.fullName}~${id}`)"
          @keyup.enter="select(`${item.fullName}~${id}`)"
          tabindex="0"
        >
          <div>
            <img
              v-if="item.descendant"
              src="@icons/ArrowPath.svg"
              alt="Option"
              class="imgArrow"
            />
            <img
              v-if="isInSelection(`${item.fullName}~${id}`)"
              src="@icons/check.png"
              alt="Check"
              class="imgCheck"
            />
            <span>
              <span id="lblName">{{ item.name }}</span>
              <span id="lblCount" class="ml-1"
                >({{ item.value.toLocaleString() }})</span
              >
            </span>
          </div>
        </div>
        <div
          v-if="
            !item.lastChild &&
            subMenu &&
            isInSelection(`${item.fullName}~${id}`)
          "
          class="h-sub-menu d-flex align-items-center"
          @click="enterSubMenu(item.name)"
          @keyup.enter="enterSubMenu(item.name)"
          tabindex="0"
        >
          {{ item.subMenu ? item.subMenu : subMenu
          }}<img src="@icons/Path_MMT.svg" class="arrow-icon ml-1" />
        </div>
      </li>
    </div>
    <div v-else class="h-no-items">No items to display</div>
    <div
      v-if="!data.some((x) => !x.hidden)"
      class="h-no-items d-flex justify-content-center"
    >
      No Results
    </div>
  </div>
</template>

<script>
import { Text, Image, Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { mapState, mapMutations } from 'vuex';
import { facetMetaData } from "@util/facetHelper.js";

export default {
  name: "HierarchicalFacetList",
  props: {
    title: {
      type: String,
    },
    data: {
      type: Array,
    },
    subMenu: {
      type: String,
    },
  },
  components: {
    ScText: Text,
    ScImage: Image,
    ScPlaceholder: Placeholder,
  },
  data() {
    return {
      selected: {},
      inSelection: {},
      id: this._uid,
    };
  },
  computed: {
    ...mapState("srp", {
      reset: (state) => state.resetSelectedFacetItems,
    }),
    isSelected() {
      return (key) => this.selected[key];
    },
    isInSelection() {
      return (key) => this.inSelection[key];
    },
    isNotSelected() {
      return (key) => !this.selected[key];
    },
  },
  methods: {
    ...mapMutations("srp", ["setRemoveFacet", "setRecentSelectedFilter"]),
    select(key) {
      if (!this.inSelection.hasOwnProperty(key))
        this.$set(this.inSelection, key, true);
      else {
        this.$set(this.inSelection, key, !this.inSelection[key]);
      }

      let selectedItems = Object.assign({}, this.selected, this.inSelection);
      this.$store.commit("srp/setResetSelectedFacetItems", false);
      let output = {
        internalUpdate: true,
        items: Object.keys(selectedItems)
          .filter((key) => selectedItems[key] === true)
          .map((x) => x.split("~")[0]),
      };

      this.setRecentSelectedFilter(facetMetaData.mmt.key);
      this.$emit("input", output);
    },
    deselect(key) {
      this.$set(this.selected, key, false);
      if (this.inSelection.hasOwnProperty(key))
        this.$set(this.inSelection, key, false);

      let selectedItems = Object.assign({}, this.selected, this.inSelection);
      this.$store.commit("srp/setResetSelectedFacetItems", false);
      let output = {
        internalUpdate: true,
        items: Object.keys(selectedItems)
          .filter((key) => selectedItems[key] === true)
          .map((x) => x.split("~")[0]),
      };
      this.setRecentSelectedFilter(facetMetaData.mmt.key);
      this.$emit("input", output);
    },
    deselectFromExterior(key) {
      key += `~${this.id}`;
      this.$set(this.selected, key, false);
      this.$set(this.inSelection, key, false);
    },
    enterSubMenu(key) {
      this.$emit("select", key);
    },
    resetLists() {
      this.selected = {};
      this.inSelection = {};
    },
  },
  watch: {
    reset(value) {
      if (value) {
        for (let key in this.inSelection) {
          this.$set(this.selected, key, this.inSelection[key]);
        }
        this.inSelection = {};
      }
    },
    data(newValue, oldValue) {
      this.resetLists();
      newValue.forEach((x) => {
        if (x.selected) {
          let key = x.fullName + "~" + this.id;
          if (!this.inSelection.hasOwnProperty(key)) {
            this.$set(this.inSelection, key, true);
          } else {
            this.$set(this.inSelection, key, !this.inSelection[key]);
          }
        }
      });
      let selectedItems = Object.assign({}, this.selected, this.inSelection);
      this.$store.commit("srp/setResetSelectedFacetItems", false);
      let output = {
        internalUpdate: false,
        items: Object.keys(selectedItems).map((x) => x.split("~")[0]),
      };
      this.$emit("input", output);
      for (let key in this.inSelection) {
        const subOption = newValue.some(
          (x) => x.fullName === key.split("~")[0] && x.descendant
        );
        const parentOption = newValue.some(
          (x) => x.fullName === key.split("~")[0] && x.parent
        );
        if (!subOption && !parentOption) {
          this.$set(this.selected, key, this.inSelection[key]);
        }
      }
    },
  },
};
</script>

<style scoped>
.arrow-icon {
  fill: var(--system-alert-blue-60);
  height: 14px;
}

.hover-underline:hover {
  text-decoration: underline;
  text-decoration-color: #242c33;
}

.h-no-items {
  padding: 20px;
  cursor: default;
}

.h-sub-menu {
  color: #006fa6;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  padding: 16px 24px;
}

.h-sub-menu:hover {
  text-decoration: underline;
}

#h-list-container {
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: default;
}

.imgCheck {
  padding-right: 8px;
}

.imgArrow {
  padding-right: 8px;
  margin-top: -16px;
}

.selected {
  background-color: #eaf5dc;
}

.sc-image {
  margin-top: -6px;
  margin-bottom: -6px;
}

.selected .facetIcon,
.inSelection {
  filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg)
    brightness(90%) contrast(103%);
}

#lblCount {
  color: #666b70;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

#h-list-title {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  height: 24px;
  color: #242c33;
  padding-bottom: 50px;
  padding-left: 20px;
  display: inline-block;
}

.h-list-element {
  width: 100%;
  border-bottom: 1px solid #e8e9eb;
  align-self: stretch;
  color: var(--park-gray-0);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-right: -7px;
  display: flex;
  justify-content: space-between;
  cursor: default !important;
}

.divItemInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 80%;
  min-width: 175px;
  padding: 16px 24px;
}

/* Focus */
.divItemInfo:focus,
.divItemInfo:focus-visible,
.h-sub-menu:focus,
.h-sub-menu:focus-visible {
  outline-offset: -3px;
}

/* Active */
.h-list-element:not(.selected) .divItemInfo:active,
.h-list-element:not(.selected) .h-sub-menu:active {
  background-color: #e8e9eb;
}

/* Hover */
.divItemInfo:hover .facetIcon,
.h-sub-menu:hover .facetIcon {
  filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg)
    brightness(90%) contrast(103%);
}

.h-list-element:not(.selected):hover .divItemInfo,
.h-list-element:not(.selected):hover .h-sub-menu {
  background-color: var(--park-gray-100);
}
</style>
