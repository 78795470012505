<template>
  <div class="location-card-zip" data-component-name="dealership-card">
    <div v-if="dealership" class="location-card-info-zip" data-for="dealerships">
      <div class="location-card-info-title-zip">
        <a class="store-name" :href="dealership.url" target="_self" role="link" tabindex="-1" :data-info-for="dealership.storeName">
          {{ dealership.storeName }}
          <span class="distance-store"> ({{ dealership.distance.toFixed() }} {{ $t('mi') }}) </span>
        </a>
      </div>
      <div class="container-general">
        <div class="left-side">
          <p class="store-addr">
            {{ dealership.address }}<br />
            {{ dealership.city }}, {{ dealership.state }} {{ dealership.zip }}<br />
          </p>
          <p class="telefone-store">
            <a
              :href="'tel:' + dealership.phone"
              tabindex="-1"
              :data-storename="dealership.storeName"
              :data-storephone="dealership.phone"
              v-if="dealership.phone"
              >{{ dealership.phone }}</a
            >
          </p>
        </div>
        <div class="right-side">
          <a
            :href="directionsLink"
            class="directions-link"
            role="link"
            tabindex="-1"
            target="_blank"
            :data-storename="dealership.storeName"
            aria-describedby="audioeye_new_window_message"
          >
            <div class="button-directions">
              <sc-image :media="fields.iconDirection" />
            </div>
            <div class="directions-description">
              <sc-text :field="fields.directionDescription" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
export default {
  name: 'DealershipCard',

  props: {
    dealership: {
      type: Object,
    },
    directionsOrigin: {
      type: String,
      default: '',
    },
    fields: {
      type: Object,
      default: {},
    },
  },
  computed: {
    directionsLink: {
      get() {
        if (!this.dealership) return '';
        const store = this.dealership;
        const address = `${store.address} ${store.city}, ${store.state} ${store.zip}`;
        return `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
          this.directionsOrigin
        )}&destination=${encodeURIComponent(address)}`;
      },
    },
  },
  components: {
    ScText: Text,
    ScImage: Image,
  },
};
</script>

<style scoped>
.directions-link {
  text-decoration: none;
  padding-top: 8px;
  display: grid;
  justify-items: center;
  color: var(--supplementary-blue-60);
}

.directions-link:hover,
.telefone-store a:hover {
  cursor: pointer;
  text-decoration: underline;
  color: var(--supplementary-blue-60);
}

.directions-link:active,
.telefone-store a:active {
  cursor: pointer;
  text-decoration: underline;
  color: var(--supplementary-blue-50);
}

.distance-store {
  font-weight: 300;
}

.store-name {
  text-decoration: none;
  color: var(--park-gray-0, #242c33);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.button-directions {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--system-alert-blue-60);
}

.location-card-info-zip {
  width: 100%;
}

@media (max-width: 991px) {
  .location-card-zip .location-card-info-zip {
    flex: 1;
  }

  .location-card-zip {
    width: 100%;
    display: flex;
  }
}

.location-card-zip .location-card-info-zip .location-card-info-title-zip {
  margin-bottom: 12px;
}

.location-card-zip {
  overflow: hidden;
  display: flex;
}

@media (min-width: 992px) {
  .location-card-zip {
    max-width: 504px;
  }
}

@media (min-width: 1240px) {
  .location-card-zip {
    max-width: 544px;
  }
}

.phone-number-section {
  padding-top: 12px;
}

.container-general {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.left-side {
  flex: 1;
}

.right-side {
  align-self: flex-end;
  padding-bottom: 8px;
}

.telefone-store {
  padding-top: 12px;
  text-decoration: none;
  align-self: stretch;
  color: var(--system-alert-blue-60);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.telefone-store a {
  font-weight: 500;
  color: var(--system-alert-blue-60);
}

.directions-description {
  padding-top: 5px !important;
  text-decoration: none;
  height: 21px;
  align-self: stretch;
  color: var(--system-alert-blue-60);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.store-addr {
  font-size: 14px;
}
</style>
