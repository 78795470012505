import zipService from "../../services/zipService.js";
import CheckAvailabilityService from "../../services/CheckAvailabilityService.js";
import eventBus from "@assets/js/eventBus.js";
import {GenerateCapiEventId, getFbClientId, getFbClidFromUrl, getUserDataSrp, generateCapiModelSrp, SendFacebookCapiEvent, SendTikTokCapiEvent} from "../../assets/js/capiFunctions";
export const validateFormAskAboutThisCar = async () => {
  let firstname = document.getElementById("inputFirstNameAboutThisCar").value;
  let lastname = document.getElementById("inputLastNameAboutThisCar").value;
  let phone = document.getElementById("inputPhoneNumberAskAboutThisCar").value;
  let email = document.getElementById("inputEmailAskAboutThisCar").value;
  let zipcode = document.getElementById("inputZipCodeAboutThisCar").value;
  let zipcodeField = document.getElementById("inputZipCodeAboutThisCar");
  let isEmailPhone = document.getElementById("isEmailPhone").value;

  let isvalidForm = true;
  isvalidForm = verifyEmailAndPhoneNumberValidation(isEmailPhone, email, phone);

  let regexEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (firstname == "") {
    changeDisplayError("error_FirstName", true);
    document.getElementById("inputFirstNameAboutThisCar").classList.add("error-necessaryFields");
    isvalidForm = false;
  } else {
    changeDisplayError("error_FirstName", false);
    document.getElementById("inputFirstNameAboutThisCar").classList.remove("error-necessaryFields");
  }

  if (lastname == "") {
    changeDisplayError("error_LastName", true);
    document.getElementById("inputLastNameAboutThisCar").classList.add("error-necessaryFields");
    isvalidForm = false;
  } else {
    changeDisplayError("error_LastName", false);
    document.getElementById("inputLastNameAboutThisCar").classList.remove("error-necessaryFields");
  }

  if (phone.length > 0 && phone.length != 14) {
    changeDisplayError("error_PhoneNumber", true);
    isvalidForm = false;
  } else {
    changeDisplayError("error_PhoneNumber", false);
  }

  if (email.length > 0) {
    let is_email = regexEmail.test(email);
    if (!is_email) {
      changeDisplayError("error_Email", true);
      isvalidForm = false;
    } else {
      changeDisplayError("error_Email", false);
    }
  }

  const isValidZip = await zipService.isValidZip(zipcode)
  if (isValidZip) {
    if (zipcodeField.classList.contains("error-zip")) {
      zipcodeField.classList.remove("error-zip");
    }
    changeDisplayError("error-zip", false);
  } else {
    isvalidForm = false;
    if (!zipcodeField.classList.contains("error-zip")) {
      zipcodeField.classList.add("error-zip");
    }
    changeDisplayError("error-zip", true);
  }

  return isvalidForm;
};

function changeDisplayError(errorElementId, display) {
  if (display) {
    if (
      document.getElementById(errorElementId).classList.contains("hide-text")
    ) {
      document.getElementById(errorElementId).classList.remove("hide-text");
    }
  } else {
    if (
      !document.getElementById(errorElementId).classList.contains("hide-text")
    ) {
      document.getElementById(errorElementId).classList.add("hide-text");
    }
  }
}

function verifyEmailAndPhoneNumberValidation(featureFlag, email, phone){
  if(featureFlag === '1'){
    if (email == "" || phone == "") {
      changeDisplayError("Error_btn", true);
      return false;
    }
  } else{
    if (email == "" && phone == "") {
      changeDisplayError("Error_btn", true);
      return false;
    }
  }
  return true;
}

export const onFocus = (Id) => {
  var IdFocus = document.getElementById(Id);
  var IdGroup = IdFocus.closest(".form-group");
  if (IdGroup)
    if (!IdGroup.classList.contains("focused")) {
      IdGroup.classList.add("focused");
    }
};

export const onFocusOut = (Id) => {
  var IdFocus = document.getElementById(Id);

  var IdGroup = IdFocus.closest(".focused");

  IdGroup.classList.remove("focused");

  if (IdFocus.value.length > 0 && IdGroup) {
    IdGroup.classList.add("filled");
  } else if (IdFocus.value.length == 0 && IdGroup) {
    if (IdGroup.classList.contains("filled"))
      IdGroup.classList.remove("filled");
  }
};

export const TextAreaFocus = (Id) => {
  var IdFocus = document.getElementById(Id);
  var IdGroup = IdFocus.closest(".form-group");
  if (IdGroup)
    if (!IdGroup.classList.contains("focused")) {
      IdGroup.classList.add("focused");
      IdGroup.querySelector(".hintTextArea").classList.remove("hide-text");
      IdFocus.placeholder = "";
    }
};

export const TextAreaFocusOut = (Id) => {
  var IdFocus = document.getElementById(Id);
  var IdGroup = IdFocus.closest(".form-group");
  if (IdGroup)
    if (IdFocus.value.length > 0 && IdGroup) {
      IdGroup.classList.remove("focused");
      IdGroup.classList.add("filled");
      IdFocus.placeholder = "";
    } else if (IdFocus.value.length == 0 && IdGroup) {
      IdGroup.classList.remove("focused");
      IdGroup.querySelector(".hintTextArea").classList.add("hide-text");
      IdFocus.placeholder = IdGroup.querySelector(".hintTextArea").textContent;
    }
};

export const CheckAvailability = () => {
  let FirstName = document.getElementById("inputFirstNameAboutThisCar").value;
  let LastName = document.getElementById("inputLastNameAboutThisCar").value;
  let PhoneNumber = document.getElementById(
    "inputPhoneNumberAskAboutThisCar"
  ).value;
  let Email = document.getElementById("inputEmailAskAboutThisCar").value;
  let ZipCode = document.getElementById("inputZipCodeAboutThisCar").value;
  let comments = document.getElementById("comments").value;
  let vin = document.getElementById("selectedVehicleVin").value;
  let make = document.getElementById("selectedVehicleMake").value;
  let model = document.getElementById("selectedVehicleModel").value;
  let year = document.getElementById("selectedVehicleYear").value;

  CheckAvailabilityService.CheckAvailability(
    FirstName,
    LastName,
    Email,
    PhoneNumber,
    comments,
    ZipCode,
    vin
  );

  let eventName = "Lead";
  let contentName = "EchoPark.com SRP Check Availability";
  let capiEventId = GenerateCapiEventId("EchoPark.com SRP Check Availability");
  let requestUrl = window.location.href;
  let baseUrl = window.location.origin;
  let contentCategory = "Web Form Lead Sent";
  let value = "1";
  let search = "";
  let street = "";
  let city = "";
  let state = "";
  let userAgent = navigator.userAgent;
  let fbp = getFbClientId();
  let fbclid = getFbClidFromUrl();

  let obj = {
    event: 'Lead Sent',
    Source: 'EchoPark.com SRP Check Availability',
    Subsource: 'Form Submitted',
    Location: ZipCode,
    VOI_VIN: vin,
    VOI_Make: make,
    VOI_Model: model,
    VOI_Year: year,
    CAPI_event_id: capiEventId,
  };



  let userData = getUserDataSrp(FirstName, LastName, street, city, state, ZipCode, Email, PhoneNumber, userAgent);
  let capiModel = generateCapiModelSrp(eventName, contentName, capiEventId, requestUrl, baseUrl, vin, userData, contentCategory, value, search, fbp, fbclid);

  SendFacebookCapiEvent(capiModel);
  SendTikTokCapiEvent(capiModel, 'Contact');
  eventBus.$emit('event',  obj);
};
