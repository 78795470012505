<template>
  <div class="modal show" id="AskUsModal" v-if="isOpen">
    <div class="askUsModalContent">
      <AskUsForm :askUsVehicle="getVehicleByVin(selectedVin)" />
    </div>
  </div>
</template>

<script>
import { RichText } from "@sitecore-jss/sitecore-jss-vue";
import AskUsForm from "../Forms/AskUsForm.vue";
import { Text, Image } from "@sitecore-jss/sitecore-jss-vue";
import { mapGetters } from "vuex";

export default {
  name: "AskUsModal",

  props: {
    isOpen: Boolean,
    selectedVin: Array,
  },

  computed: {
    ...mapGetters("srp", ["getVehicleByVin"]),
  },

  components: {
    ScRichText: RichText,
    AskUsForm,
    ScText: Text,
    ScImage: Image,
  },
};
</script>

<style scoped>
#AskUsModal {
  display: flex;
  min-height: calc(100% - 80px);
  background-color: var(--park-gray-0-op50);
}

.askUsModalContent {
  display: flex;
  background-color: var(--base-white-100);
  padding: 24px;
  margin: auto auto;
  min-width: 328px;
  max-width: 500px;
  border-radius: 16px;
}

@media only screen and (max-width: 625px) {
  #AskUsModal {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .askUsModalContent {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

@media only screen and (max-width: 375px) {
  #AskUsModal {
    width: 100%;
    border-radius: 0;
    overflow: auto;
  }

  .askUsModalContent {
    width: 100%;
    border-radius: 0;
    height: auto;
  }
}


</style>
