const DealershipSearchClient = function (axios, baseUrl, zip) {

    const __searchParameters = {};
    const __baseUrl = baseUrl;
    const __zip = zip;

    const addZip = function (zip) {
        __searchParameters["zip"] = zip;
    }

    const execute = function () {
        addZip(__zip);
        return axios.get(__baseUrl, { params: __searchParameters });
    } 

    return {execute};

};

export default DealershipSearchClient;