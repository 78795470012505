<template>
    <lord-icon
    src="https://cdn.lordicon.com/utwelszo.json"
    trigger="loop"
    delay="1000"
    colors="primary:#3e8500,secondary:#f42434,tertiary:#666b70,quaternary:#f4f5f7"
    style="width:144px;height:144px">
</lord-icon>
</template>

<script>
export default {
    name: "FuelAnimatedIcon"
};
</script>