<template>
    <span>{{prefix}}{{ min }}&ndash;{{prefix}}{{ max }}{{ postfix }}</span>
</template>

<script>
export default {
    name: "RangeData",
    props: {
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 0
        },
        prefix: {
            type: String | null,
            default: ''
        } ,
        postfix: {
            type: String | null,
            default: ''
        }
    },
}
</script>
