<template>
  <div class="vehicleInfoPrice">
    <div class="vehicleInfoPriceFullPrice">
      <span class="vehicleInfoPriceFullPriceCurrencySymbol">{{
        currencySymbol
      }}</span
      >{{ formatVehiclePrice(sellingPrice) }}
    </div>
    <div class="vehicleInfoPriceInstallmentPrice" v-if="showPayEstimate">
      {{ formatVehicleInstallmentPrice(estimatedPayLow, estimatedPayHigh)
      }}{{ $t("Monthly payment") }}
      <span class="tooltipContainer ignoreFullTileClick">
        <img
          :id="`priceTooltipIcon-${vin}`"
          @click.prevent.stop="unSetTooltipVisible"
          @focus="toggleTooltipVisible"
          @blur="blurTooltipVisible"
          src="~@icons/info.svg"
          tabindex="0"
        />
        <b-tooltip
          :show.sync="show"
          :target="`priceTooltipIcon-${vin}`"
          triggers="click blur"
        >
          {{ $t("financing-tooltip") }}
        </b-tooltip>
      </span>
    </div>
  </div>
</template>

<script>
import {
  formatVehiclePrice,
  formatVehicleInstallmentPrice,
} from "@util/formatFunctions.js";

export default {
  name: "VehicleCardPrice",
  data() {
    return {
      show: false,
      focus: false,
      currencySymbol: "$",
    };
  },
  props: {
    vin: String,
    sellingPrice: Number,
    estimatedPayLow: Number,
    estimatedPayHigh: Number,
    showPayEstimate: Boolean,
  },
  methods: {
    formatVehiclePrice,
    formatVehicleInstallmentPrice,
    toggleTooltipVisible() {
      this.show = true;
    },
    blurTooltipVisible() {
      this.focus = false;
    },
    unSetTooltipVisible() {
      if (this.focus === true) {
        this.toggleTooltipVisible();
      }
      this.focus = true;
    },
  },
};
</script>

<style scoped>
.vehicleInfoPrice {
  flex: 1;
}

.vehicleInfoLocation {
  flex: 1;
}

.vehicleInfoPriceFullPrice {
  color: var(--system-green-50);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.vehicleInfoPriceFullPriceCurrencySymbol {
  font-size: 14px;
  vertical-align: text-top;
  line-height: 28px;
}

.vehicleInfoPriceInstallmentPrice {
  color: var(--park-gray-0);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  display: flex;
  height: 100%;
  vertical-align: bottom;
}

.vehicleInfoPriceInstallmentPrice img {
  height: 16px;
  width: 16px;
  margin-left: 4px;
}

.tooltipContainer {
  z-index: 1;
  position: relative;
  display: inline-block;
  opacity: 100;
}

.tooltip {
  border-color: transparent;

  .tooltip-inner {
    border-radius: 6px;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    background: var(--park-gray-0);
    padding: 16px;
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;
  }

  .arrow::before {
    top: -0.5px;
    border-top-color: var(--park-gray-0);
  }
}
</style>
