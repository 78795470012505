<template>
  <div class="vehicleCardPaper" :data-vin="vehicle.vin">
    <a :href="vehicle.url" @click="FullTileClick">
      <FavoriteButton
        :favorite="this.isCarFavoriteData"
        @toggle="toggleFavorite"
      />
      <VehicleCardCarousel
        :imageList="imageList"
        :lowRezImage="vehicle.lowRezImage || imageList[0]"
        :imageAltText="`${vehicle.year} ${vehicle.make} ${vehicle.model}`"
        @seeMoreOnClick="FullTileClick"
      />
      <div class="vehicleInfoWrapper">
        <VehicleCardDetails
          :year="vehicle.year"
          :mileage="vehicle.miles"
          :stockNumber="vehicle.stockNumber"
          :make="vehicle.make"
          :model="vehicle.model"
          :trim="vehicle.trim"
          :url="vehicle.url"
        />
        <div class="vehicleInfoWrapperPriceLocation">
          <VehicleCardPrice
            :vin="vehicle.vin"
            :sellingPrice="vehicle.sellingPrice"
            :estimatedPayHigh="Math.ceil(vehicle.estimatedPayHigh)"
            :estimatedPayLow="Math.floor(vehicle.estimatedPayLow)"
            :showPayEstimate="showPaymentEstimates"
            ref="vehicleCardPrice"
          />
          <VehicleCardLocation
            :ShippingLabel="shippingTitle"
            :ShippingStore="shippingDetails"
          />
        </div>
        <VehicleCardButtons :vehicle="vehicle" />
      </div>
    </a>
  </div>
</template>

<script>
import VehicleCardCarousel from "./VehicleCardCarousel";
import VehicleCardDetails from "./VehicleCardDetails";
import VehicleCardPrice from "./VehicleCardPrice";
import VehicleCardLocation from "./VehicleCardLocation";
import VehicleCardButtons from "./VehicleCardButtons";
import FavoriteButton from "@components-vue/Buttons/FavoriteButton";
import SrpButton from "@components/buttons/SrpButton.vue";
import favoriteService from "@services/favoriteService";
import eventBus from "@assets/js/eventBus";
import { mapState } from "vuex";
import {
  GenerateCapiEventId,
  getFbClientId,
  getFbClidFromUrl,
  getUserDataSrp,
  generateCapiModelSrp,
  SendFacebookCapiEvent,
  SendTikTokCapiEvent,
} from "@assets/js/capiFunctions";

export default {
  name: "VehicleCard",
  data() {
    return {
      noImageSrc: require("@assets/images/no-image.svg"),
      isCarFavoriteData: Boolean,
      shippingTitle: String,
      shippingDetails: String,
    };
  },
  props: {
    vehicle: Object,
    urlBase: String,
    showPaymentEstimates: Boolean,
    isCarFavorite: Boolean,
    shippableTitle: String,
    shippableDetails: String,
    pickUpTodayTitle: String,
    pickUpOnlyTitle: String,
    pickUpDetails: String,
  },
  components: {
    FavoriteButton,
    SrpButton,
    VehicleCardCarousel,
    VehicleCardDetails,
    VehicleCardPrice,
    VehicleCardLocation,
    VehicleCardButtons,
  },
  created() {
    this.isCarFavoriteData = this.isCarFavorite;
    this.FormatShippingInfo();
  },
  computed: {
    ...mapState("srp", ["dealerships"]),
    imageList() {
      return !this.vehicle?.images || this.vehicle.images.length < 1
        ? [this.noImageSrc]
        : this.vehicle.images;
    },
  },
  methods: {
    FullTileClick(event) {
      if (this.showPaymentEstimates ?? !event.target.closest(".tooltip"))
        this.$refs.vehicleCardPrice.unSetTooltipVisible();
      if (
        !event.target.closest(".ignoreFullTileClick") &&
        !event.target.closest(".carousel-control-next-icon") &&
        !event.target.closest(".carousel-control-prev-icon") &&
        !event.target.closest(".vehicleInfoDetailsStockNumber")
      ) {
        let capiEventIdVdp = GenerateCapiEventId("VDP Car Information");
        let obj = {
          event: "VDP Car Information",
          make: this.vehicle.make || "",
          model: this.vehicle.model || "",
          year: this.vehicle.year || "",
          vin: this.vehicle.vin || "",
          Location: this.vehicle.dealership || "",
          Price: this.vehicle.sellingPrice || "",
          currency: "USD",
          bodyType: this.vehicle.bodystyle || "",
          Cartype: $("#IsOutletVehicleFlag").val() ? "outlet" : "normal",
          CAPI_event_id: capiEventIdVdp || "",
          Carfax_available: $("#HiddenHasCarFaxUrl").val() == "True" ? 1 : 0,
        };
        let contentCategory = "VDP view";
        let contentName = "VDP View";
        let eventName = "ViewContent";
        let requestURL = window.location.href || "";
        let baseUrl = window.location.origin || "";
        let value = "0";
        let search = "";
        let street = "";
        let firstName = "";
        let lastName = "";
        let city = "";
        let state = "";
        let zipCode = $("#ZipcodeInformationHeader").text();
        let email = "";
        let phone = "";
        let userAgent = navigator.userAgent;
        let fbp = getFbClientId();
        let fbclid = getFbClidFromUrl();

        let userData = getUserDataSrp(
          firstName,
          lastName,
          street,
          city,
          state,
          zipCode,
          email,
          phone,
          userAgent,
        );
        let capiModel = generateCapiModelSrp(
          eventName,
          contentName,
          capiEventIdVdp,
          requestURL,
          baseUrl,
          this.vehicle.vin,
          userData,
          contentCategory,
          value,
          search,
          fbp,
          fbclid,
        );

        SendFacebookCapiEvent(capiModel);
        SendTikTokCapiEvent(capiModel, "ViewContent");
        eventBus.$emit("event", obj);

        window.location.href = this.vehicle.url;
      }
    },

    FormatShippingInfo() {
      if (this.vehicle.shippingStore) {
        this.shippingTitle = this.shippableTitle.replace(
          "##price##",
          this.vehicle.shippingPrice,
        );
        this.shippingDetails = this.shippableDetails.replace(
          "##store##",
          this.getFormattedStoreName(this.vehicle.shippingStore),
        );
        //else if it's shippable > then display 'ship for cost to storename'
      } else if (this.vehicle.shippable) {
        this.shippingTitle = this.pickUpTodayTitle;

        if (this.vehicle.distanceToUser) {
          this.shippingDetails = this.pickUpDetails
            .replace(
              "##store##",
              this.getFormattedStoreName(this.vehicle.dealership),
            )
            .replace(
              "##miles##",
              this.vehicle.distanceToUser.toLocaleString("en-US", {
                maximumFractionDigits: 0,
              }),
            );
        } else {
          //no distance to store
          this.shippingDetails = this.shippableDetails.replace(
            "##store##",
            this.getFormattedStoreName(this.vehicle.dealership),
          );
        }
      } else {
        //else > pick up only at storename (mi)
        this.shippingTitle = this.pickUpOnlyTitle;

        if (this.vehicle.distanceToUser) {
          this.shippingDetails = this.pickUpDetails
            .replace(
              "##store##",
              this.getFormattedStoreName(this.vehicle.dealership),
            )
            .replace("##miles##", this.vehicle.distanceToUser);
        } else {
          //no distance to store
          this.shippingDetails = this.shippableDetails.replace(
            "##store##",
            this.getFormattedStoreName(this.vehicle.dealership),
          );
        }
      }
    },

    getFormattedStoreName(dealership) {
      if (typeof dealership !== "undefined" || dealership !== null) {
        if (this.dealerships != null) {
          for (let i = 0; i < this.dealerships.length; i++) {
            if (this.dealerships[i].dealerId == dealership) {
              return (
                this.dealerships[i].storeName ??
                this.formatStoreName(dealership)
              );
            }
          }
        }
      }
      return this.formatStoreName(dealership);
    },

    formatStoreName(input) {
      //turn DealerID into a name if the store, for some reason the store is Undefined
      let epStringRegEx = /EchoPark/;

      if (input === null || typeof input === "undefined")
        return "the store is closed";

      let matched = epStringRegEx.exec(input); //try to find "EchoPark" in for example: EchoParkRaleigh
      if (matched) {
        var indexAfter = matched.index + matched[0].length; //separate EchoPark out from EchoParkRaleighAndOtherStuff
        var output = input.substring(indexAfter).replace(/([A-Z])/g, " $1"); //add a space between each capital, starting after "EchoPark"
        return output.trim();
      } else {
        return input;
      }
    },

    async toggleFavorite() {
      let response = await favoriteService.toggleFavorite(this.vehicle.vin);
      if (response && response.Data) {
        this.isCarFavoriteData = response.Data.IsFavorite;
      }

      if (this.isCarFavoriteData) {
        let capiEventId = GenerateCapiEventId("favorite applied");
        let contentCategory = "Car Added to Favorites";
        let requestURL = window.location.href;
        let vin = this.vehicle.vin || "";
        let value = "0";
        let search = "";
        let baseUrl = window.location.origin;
        let street = "";
        let firstName = "";
        let lastName = "";
        let city = "";
        let state = "";
        let zipCode = $("#ZipcodeInformationHeader").text();
        let email = "";
        let phone = "";
        let userAgent = navigator.userAgent;
        let fbp = getFbClientId();
        let fbclid = getFbClidFromUrl();
        let obj = {
          CAPI_event_id: capiEventId,
          PageLocation: $("#SrpPaginationText").text().trim(),
          event: "favorite applied",
          make: this.vehicle.model || "",
          model: this.vehicle.make || "",
          vin: this.vehicle.vin || "",
          year: this.vehicle.year || "",
        };
        eventBus.$emit("event", obj);

        let userData = getUserDataSrp(
          firstName,
          lastName,
          street,
          city,
          state,
          zipCode,
          email,
          phone,
          userAgent,
        );
        let capiModel = generateCapiModelSrp(
          "AddToWishlist",
          "SRP2",
          capiEventId,
          requestURL,
          baseUrl,
          vin,
          userData,
          contentCategory,
          value,
          search,
          fbp,
          fbclid,
        );

        SendFacebookCapiEvent(capiModel);
        SendTikTokCapiEvent(capiModel, "AddToWishlist");
      }
    },
  },
};
</script>

<style>
.vehicleCardPaper {
  border-radius: 16px;
  border: solid 1px var(--shadow-white);
  background-color: var(--base-white-100);
  overflow: hidden;
  min-width: 280px;
  max-width: 420px;
  width: 100%;
  cursor: pointer;
}

.vehicleCardPaper:hover {
  text-decoration: none;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.vehicleCardPaper > a {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
}

.vehicleInfoWrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.vehicleInfoWrapperPriceLocation {
  display: flex;
  margin-bottom: 14px;
  margin-top: 14px;
}
</style>
