<template>
    <span>{{ prefix }}{{ item }}{{ postfix }}</span>
</template>

<script>
export default {
    name: "StringData",
    props: {
        item: {
            type: String | null
            , default: ''
        } ,
        prefix: {
            type: String | null
            , default: ''
        },
        postfix: {
            type: String | null
            , default: ''
        }
    },
}
</script>
