<template>
  <div id="divRangeSlider" data-role="range-slider">
    <div ref="slider"></div>
  </div>
</template>

<script>
import noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";

export default {
  name: "RangeSlider",
  props: {
    setRange: {
      type: Array,
      default: function () {
        return [0, 100];
      },
    },
    range: {
      type: Array,
      default: function () {
        return [0, 100];
      },
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  mounted() {
    let slider = this.$refs.slider;
    noUiSlider.create(slider, {
      start: [parseInt(this.range[0]), parseInt(this.range[1])],
      connect: true,
      step: this.step,
      range: {
        min: parseInt(this.setRange[0]),
        max: parseInt(this.setRange[1]),
      },
    });

    slider.noUiSlider.on("slide", (values) => {
      let newMinPosition = parseInt(values[0]);
      let newMaxPosition = parseInt(values[1]);

      if (
        newMinPosition !== this.range[0] ||
        newMaxPosition !== this.range[1]
      ) {
        this.$emit("sliderValueUpdated", values);
      }
    });

    slider.noUiSlider.on("set", (values) => {
      let newMinPosition = parseInt(values[0]);
      let newMaxPosition = parseInt(values[1]);
      this.$emit("sliderValueSet", values);
    });
  },
  watch: {
    range(newValue) {
      let slider = this.$refs.slider;
      if (!slider.noUiSlider) return;
      let minInt = parseInt(newValue[0]);
      let maxInt = parseInt(newValue[1]);
      let currentPosition = slider.noUiSlider.get();
      let currentMinInt = parseInt(currentPosition[0]);
      let currentMaxInt = parseInt(currentPosition[1]);
      if (minInt !== currentMinInt || maxInt !== currentMaxInt) {
        let slider = this.$refs.slider;
        slider.noUiSlider.set(newValue);
      }
    },
    setRange(newValue) {
      let slider = this.$refs.slider;
      if (!slider.noUiSlider) return;
      let minInt = parseInt(newValue[0]);
      let maxInt = parseInt(newValue[1]);
      slider.noUiSlider.updateOptions({
        range: {
          min: minInt,
          max: maxInt,
        },
      });
    },
  },
};
</script>

<style>
#divRangeSlider .noUi-background {
  background-color: #666b70;
}

#divRangeSlider {
  padding: 0 24px 0 28px;
  width: 100% !important;
}

#divRangeSlider .noUi-horizontal {
  height: 5px;
  width: calc(100% - 16px);
  background: #e8e9eb;
  border-radius: 11px;
  border: 0;
  margin-bottom: 8px;
  margin-left: 8px;
}

#divRangeSlider .noUi-handle::after,
.noUi-handle::before {
  display: none;
}

#divRangeSlider .noUi-connect {
  background: #242c33;
}

#divRangeSlider .noUi-horizontal .noUi-handle {
  width: 24px !important;
  height: 24px !important;
  right: -10px !important;
  border-radius: 25px !important;
  background-color: #242c33;
  box-shadow: 0 2px 5px 0 #242c33 !important;
  border: 0;
  cursor: pointer;
  cursor: url("../../assets/icons/Drag.svg"), pointer;
  top: -10px !important;
}

#divRangeSlider .noUi-base:active .noUi-connect,
#divRangeSlider .noUi-base:active .noUi-handle {
  background-color: #3e8500 !important;
}

#divRangeSlider .noUi-base:hover .noUi-connect,
#divRangeSlider .noUi-base:hover .noUi-handle {
  background-color: #326b00;
}
</style>
