<template>
  <div id="filter-container">
    <Disclaimer />
  </div>
</template>

<script>
import Disclaimer from '../Disclaimer/Disclaimer.vue';
export default {
  components: {
    Disclaimer
  },

  name: "FilterContent",
}
</script>

<style scoped>
#filter-container {
  order: 7;
  display: flex;
  max-width: 100%;
  justify-content: center;
}
</style>