<template>
    <div>
        <button type="button" role="button" class="text-button" :class="{'text-button-small' : btnStyle==='small'}" 
        @click="$emit('btnclick')" :disabled="btnDisabled">
            <div class="text-button-label">{{ btnText }}</div>
            <div v-if="arrowRight">
                <img src="~@icons/ArrowIcons/arrow-md-right.svg" />
            </div>
        </button>
    </div>
  </template>
  
  <script>
  export default {
    name: "textButton",
  
    props: {
        btnText: String,
        btnDisabled: Boolean,
        btnStyle: String,
        arrowRight:Boolean
    },
  
    components: {},
  };
  </script>
  
  <style scoped>
  .text-button-label{
    float: left;
  }

  .text-button{
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--system-alert-blue-60);
    font-feature-settings: 'clig' off, 'liga' off;
    
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: 0.4px;

    background-color: transparent;
    border: 0;

    height: 22px;
  }

  .text-button-small{
    font-size: 14px;
    height: 20px;
  }

  .text-button:hover:enabled .text-button-label{
    text-decoration-line: underline;
  }

  .text-button:active:enabled .text-button-label{
    color: var(--supplementary-blue-50);
    text-decoration-line: underline;
  }

  .text-button:focus .text-button-label{
    text-decoration-line: underline;
  }
  .text-button:focus{
    border-radius: 4px;
    border: 2px solid;
    border-color: var(--supplementary-blue-80);
    margin-left: -2px;
    margin-right: -2px;
  }

  .text-button:disabled{
    color: var(--park-gray-70);
  }
  </style>