<template>
  <transition name="slide">
    <div data-component-name="year-filter">
      <div v-show="!yearFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="this.facetFilters['year'] ? true : false"
          :title="title"
        />
      </div>
      <div v-show="yearFilterSelected">
        <div class="yearRangeFrameDropdown" data-role="drop-down-list">
          <RangeDropdown
            :title="title"
            :selected="yearRange"
            :options="yearOptions"
            @option-selected="optionSelected"
            :minLabel="minLabel"
            :maxLabel="maxLabel"
          ></RangeDropdown>
        </div>
        <div class="yearRangeFrameSlider" data-role="range-slider">
          <RangeSlider
            style="width: 80%"
            :range="yearRange"
            :setRange="setRange"
            v-model="yearModel"
            @sliderValueUpdated="updateYearRange"
            @sliderValueSet="updateYearStore"
          ></RangeSlider>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import RangeSlider from "@components-vue/Range/RangeSlider.vue";
import RangeDropdown from "@components-vue/Range/RangeDropdown.vue";
import FilterMenuLineItem from "../FilterMenuLineItem.vue";

export default {
  name: "YearFilter",
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    RangeSlider,
    RangeDropdown,
    FilterMenuLineItem,
  },
  data() {
    return {
      yearOptions: [],
      yearRange: [],
      setRange: [],
      title: this.$t("Year Filter Title"),
      minLabel: this.$t("Min Label"),
      maxLabel: this.$t("Max Label"),
      filterType: "year",
      setUpdateStoreYear: true,
    };
  },
  computed: {
    ...mapState("srp", {
      filterSelected: (state) => state.filterSelected,
      yearFilterSelected: (state) => state.yearFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      filterSelected: (state) => state.filterSelected,
      facetFilters: (state) => state.facetFilters ?? {},
      vehicleYearMin: (state) => state.srpVehiclesData?.facets?.year?.min,
      vehicleYearMax: (state) => state.srpVehiclesData?.facets?.year?.max,
    }),
    yearModel: {
      get() {
        return this.yearOptions;
      },
      set(value) {
        let minInt = parseInt(value[0]);
        let maxInt = parseInt(value[1]);

        const data = {
          name: "year",
          value: { min: minInt, max: maxInt },
        };

        this.updateStore([minInt, maxInt], data);
      },
    },
  },
  created() {
    this.setUpdateStoreYear = false;
    this.setYearRange();
  },
  methods: {
    ...mapMutations("srp", [
      "setFilterSelected",
      "setYearFilterSelected",
      "setResetSelectedFacetItems",
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setYearFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    setYearRange() {
      const currentYear = new Date().getFullYear();
      let startYear = this.vehicleYearMin ?? 2000;
      
      this.yearOptions = [];
      for (let year = startYear; year <= currentYear; year++) {
        this.yearOptions.push(parseInt(year));
      }

      this.setRange = [startYear, currentYear];
      this.yearRange = [startYear, currentYear];
    },
    updateYearRange(values) {
      this.setUpdateStoreYear = true;
      let minInt = parseInt(values[0]);
      let maxInt = parseInt(values[1]);
      this.yearRange = [minInt, maxInt];
    },
    updateYearStore(values) {
      if (this.setUpdateStoreYear) {
        let minInt = parseInt(values[0]);
        let maxInt = parseInt(values[1]);

        const data = {
          name: "year",
          value: { min: minInt, max: maxInt },
        };

        clearTimeout(this.updateTimer);
        this.updateTimer = setTimeout(() => {
          this.yearRange = [minInt, maxInt];
          this.$store.commit("srp/setNumericFacetFilter", data);
          this.$root.$emit("filter-updated-srp");
        }, 500);
      }
    },
    optionSelected(values) {
      this.setUpdateStoreYear = true;
      this.updateYearStore(values);
    },
  },
  watch: {
    facetFilters: {
      handler: async function (newValue) {
        if (!newValue.hasOwnProperty(this.filterType)) {
          this.yearRange = [this.setRange[0], this.setRange[1]];
          this.setUpdateStoreYear = false;
        } else {
          this.yearRange = [
            this.facetFilters[this.filterType].min,
            this.facetFilters[this.filterType].max,
          ];
        }
      },
      deep: true,
    },
    vehicleYearMin() {
      this.setYearRange();
    },
    vehicleYearMax() {
      this.setYearRange();
    },
  },
};
</script>

<style scoped>
.yearRangeFrameDropdown {
  padding-top: 20px;
  padding-bottom: 0 !important;
}

.filter-selected .yearRangeFrameDropdown {
  padding-top: 0;
}

.yearRangeFrameSlider {
  padding-top: 0;
  padding-bottom: 20px !important;
}
</style>
