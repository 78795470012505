<template>
  <div class="zipModalContainer">
    <Overlay :showOverlay="zipFlyout" :zIndex="75" @click="handleClick()" />
    <div
      class="zipLocation_flyout newZipLocation"
      role="presentation"
      aria-hidden="true"
      tabindex="-1"
      :style="zipFlyout ? 'right: 0' : ''"
    >
      <div class="zipLocation_flyout_title">
        <ZipLocationEdit
          :fields="fields"
          v-model="zipcode"
          :showClose="true"
          ref="zipEdit"
        />
      </div>

      <ZipNearestStoresDisplay
        :fields="fields"
        :dealerships="dealerships"
        :closestDealership="nearestDealership"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import { Text, Image } from "@sitecore-jss/sitecore-jss-vue";
import { mapState, mapMutations } from "vuex";
import DealershipCard from "@components-vue/ZipInfo/DealershipCard.vue";
import ZipLocationEdit from "@components-vue/ZipInfo/ZipLocationEdit";
import Overlay from "@components-vue/Overlay/Overlay";
import ZipNearestStoresDisplay from "@components/zip/ZipNearestStoresDisplay";

export default {
  name: "ZipHeader",
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ScText: Text,
    ScImage: Image,
    ZipLocationEdit,
    DealershipCard,
    ZipNearestStoresDisplay,
    Overlay,
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState("srp", ["zip", "dealerships", "radius", "nearestDealership"]),
    ...mapState("common", ["zipFlyout"]),
    zipcode: {
      get: function () {
        return this.zip;
      },
      set: function (value) {
        if (this.zip !== value) {
          this.changeZip(value);
        }
      },
    },
    
  },
  methods: {
    ...mapMutations("srp", ["setZip", "setRadius"]),
    changeZip(value) {
      this.loading = true;
      this.setZip(value);
      this.$root.$emit("dealership-list-fetch-srp");
      setTimeout(() => (this.loading = false), 200);
    },
    handleClick() {
      this.$refs.zipEdit.handleClose();
    },
  },
};
</script>

<style>
div[id^="headerZipCodeDisplay"] {
  display: flex;
}

.zipModalContainer {
  position: fixed;
  z-index: 81;
}

.newZipLocation {
  padding-bottom: 0;
}

.zipLocation_flyout {
  width: 100%;
  height: 100%;
  background: var(--base-white-100);
  padding-bottom: 72px;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 81;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  scrollbar-width: thin;
  overflow-x: hidden;
}

.zipLocation_flyout::-webkit-scrollbar-track {
  background: var(--accent-warm-gray-warm-gray-80);
}

.zipLocation_flyout::-webkit-scrollbar {
  width: 2px;
}

@media (min-width: 576px) {
  .zipLocation_flyout {
    max-width: 306px;
  }
}

.zipLocation_flyout_title {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 32px;
  position: relative;
}
</style>

<!-- Global styling yet to be untangled -->
<style>
.hide {
  display: none;
}

a[target="_blank"]:after {
  content: none;
}

fieldset {
  border: 0;
}

fieldset legend {
  display: none;
}

.account_overlay,
.calculator_overlay,
.car_flyout_overlay,
.create_account_overlay {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 75;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.zipLocation {
  cursor: pointer;
}
</style>
