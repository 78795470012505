<template>
  <div v-if="hasResults" class="pageNavigationBox">
    <NavigationButton
      id="navButtonLeft"
      direction="left"
      :disabled="isFirstPage"
      :handleButtonClick="goToPreviousPage"
    />
    <b-tooltip target="navButtonLeft">Previous {{ take }}</b-tooltip>

    <div id="SrpPaginationText">
      <span>{{ startCarIndex }}-{{ lastCarIndex }} of {{ formattedVehicleAmount }} </span>
    </div>

    <NavigationButton
      id="navButtonRight"
      direction="right"
      :disabled="isLastPage"
      :handleButtonClick="goToNextPage"
    />
    <b-tooltip target="navButtonRight">Next {{ take }}</b-tooltip>
  </div>
</template>

<script>
import NavigationButton from "./NavigationButton/NavigationButton.vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import eventBus from "@assets/js/eventBus";

export default {
  data() {
    return {
      nextTooltipKey: 0,
      previousTooltipKey: 0,
    };
  },
  name: "PageNavigation",
  computed: {
    ...mapState("srp", ["queryParams", "take", "srpVehiclesData", "loading"]),
    ...mapGetters("srp", ["formattedVehicleAmount", "vehicleCount"]),
    isFirstPage() {
      return this.queryParams.page === 0;
    },
    isLastPage() {
      return this.queryParams.page * this.take + this.take >= this.vehicleCount;
    },
    startCarIndex() {
      return (this.queryParams.page * this.take + 1).toLocaleString().replace(".", ",");
    },
    lastCarIndex() {
      return Math.min(this.queryParams.page * this.take + this.take, this.vehicleCount)
        .toLocaleString()
        .replace(".", ",");
    },
    resultCount() {
      if (!this.srpVehiclesData) return -1;
      return this.srpVehiclesData.resultCount;
    },
    hasResults() {
      return !this.loading && this.resultCount > 0;
    },
  },
  methods: {
    ...mapMutations("srp", ["setPageIncrement"]),
    goToNextPage() {
      this.nextTooltipKey += 1;
      if (!this.isLastPage) {
        this.setPageIncrement(1);
        this.$root.$emit("pagination-fetch-srp");
      }
      let obj = {
            elementTitle: 'next button',
            "page number": $("#SrpPaginationText").text().trim()
        };
      eventBus.$emit('event',  obj);
    },
    goToPreviousPage() {
      this.previousTooltipKey += 1;
      if (!this.isFirstPage) {
        this.setPageIncrement(-1);
        this.$root.$emit("pagination-fetch-srp");
      }
      let obj = {
            elementTitle: 'prev button',
            "page number": $("#SrpPaginationText").text().trim()
        };
      eventBus.$emit('event',  obj);
    },
  },
  components: { NavigationButton },
};
</script>

<style scoped>
.pageNavigationBox {
  order: 6;
  width: 100%;
  margin: 24px 0px;
  display: flex;
  justify-content: center;
  justify-items: center;
}

#SrpPaginationText {
  max-width: 246px;
  flex: 1;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: var(--park-gray-0);
  text-align: center;
  align-self: center;
}

.tooltip {
  border-color: transparent;

  .tooltip-inner {
    border-radius: 6px;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    background: var(--park-gray-0);
    padding: 16px;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;
  }

  .arrow::before {
    top: -0.5px;
    border-top-color: var(--park-gray-0);
  }
}
</style>
