<template>
    <div v-show="showOverlay" class="overlay-container" @click="handleClick()" :style="{'z-index': zIndex}"></div>
</template>

<script>

export default {
    data() {
        return {};
    },
    name: "Overlay",
    props: {
        showOverlay: {
            type: Boolean,
            default: false
        },
        clickEvent: {
            type: String,
            default: ""
        },
        zIndex: {
            type: Number,
            default: 71
        }
    },
    components: {

    },
    methods: {
        handleClick() {
            this.$emit('click');
            if(this.clickEvent) {
                this.$root.$emit(this.clickEvent);
            }
        }
    },
    emits: ['click']
};
</script>

<style scoped>
.overlay-container {
    max-width: initial;
    height: 100%;
    width: 100%;
    transition: 0.2s ease-in-out;
    position: fixed;
    top: 0;
    left: 0; 
    background-color: #242C33;
    opacity: 0.33;
}
</style>
