<template>
  <div class="currency-wrapper">
    <input
      v-if="isMin"
      type="text"
      ref="inputMinCurrency"
      class="inputCurrency"
      v-model="displayValue"
      @blur="isInputActive = false"
      @focus="handleFocus"
      data-action="set-minimum-value"
    />
    <input
      v-if="!isMin"
      type="text"
      ref="inputMaxCurrency"
      class="inputCurrency"
      v-model="displayValue"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
      data-action="set-maximum-value"
    />
  </div>
</template>

<script>
export default {
  name: 'CurrencyInput',
  props: {
    value: {
      type: Number,
    },
    isMin: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isInputActive: false,
    };
  },
  emits: ['focus', 'input'],
  computed: {
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          return this.value.toString();
        } else {
          return '$ ' + this.value?.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ''));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    handleFocus() {
      this.isInputActive = true;
      this.$emit('focus');
    },
  },
};
</script>

<style scoped>
.inputCurrency {
  border: none;
  display: inline;
  width: 100%;
  color: var(--park-gray-0);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.inputCurrency:active,
.inputCurrency:focus {
  padding-left: 22px;
  margin-left: -9px;
  border-radius: 8px;
  height: 40px;
  width: 100px;
  line-height: 40px;
}

.currency-wrapper {
  display: -webkit-box;
  width: 100px;
  height: 40px;
  padding: 0 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--park-gray-80);
}
</style>
