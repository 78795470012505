<template>
  <!--
        This component is separated into 3 sections:
        1. Checkbox behavior
        2. leftContent (slot)
        3. rightContent (slot)
    -->
  <div
    class="lblListElement"
    :class="{ 'filter-disabled': isCheckboxDisabled }"
  >
    <div class="divItemInfo">
      <div v-if="isCheckboxDisabled && isSelected">
        <img src="@icons/check_disabled.png" alt="Check" class="imgCheck" />
      </div>
      <div v-else-if="isSelected || isInSelection">
        <img src="@icons/check.png" alt="Check" class="imgCheck" />
      </div>
      <div>
        <slot name="leftContent">
          <span :data-select-filter="item.name">{{ item.name }} </span>
          <span id="lblCount">({{ item.value }})</span>
        </slot>
      </div>
      <div class="rightContent">
        <slot name="rightContent">
          <img
            v-if="item.icon"
            :src="require('@icons/' + getIcon(item.name))"
            :alt="item.name"
            :class="{
              inSelection:
                isInSelection &&
                filterType !== facetMetaData.extcolor.key &&
                filterType !==
                  facetMetaData.intcolor
                    .key /* Gives the green bg on the image */,
            }"
            class="facetIcon float-right"
          />
          <sc-image
            v-if="item.scImage"
            :media="item.scImage"
            :class="{
              inSelection:
                isInSelection &&
                filterType !== facetMetaData.extcolor.key &&
                filterType !== facetMetaData.intcolor.key,
            }"
            class="sc-image float-right"
          ></sc-image>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Text, Image, Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { facetMetaData } from "@util/facetHelper.js";

export default {
  name: "FacetListItem",
  props: {
    /* Input item:
            - name
            - value
            - icon (optional)
            - scImage (optional) */
    item: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isInSelection: {
      type: Boolean,
      default: false,
    },
    isCheckboxDisabled: {
      // freeze the isSelected state
      type: Boolean,
      default: false,
    },
    filterType: {
      type: String,
      default: "",
    },
  },
  components: {
    ScText: Text,
    ScImage: Image,
    ScPlaceholder: Placeholder,
  },
  data() {
    return {
      facetMetaData: facetMetaData,
    };
  },
};
</script>

<style scoped>
.inSelection {
  filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg)
    brightness(90%) contrast(103%);
}

.lblListElement {
  border-bottom: 1px solid var(--shadow-white);
  padding: 16px 24px 15px;
  align-self: stretch;
  color: var(--park-gray-0);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.colorFilter .lblListElement {
  padding: 8px 24px 7px;
}

.lblListElement .divItemInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Active */
.lblListElement:active:not(.filter-disabled) {
  background-color: var(--shadow-white);
}

/* Hover */
.lblListElement:hover:not(.filter-disabled) .facetIcon {
  filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg)
    brightness(90%) contrast(103%);
}

li:not(.selected) .lblListElement:hover:not(.filter-disabled),
.lblListElement:hover:not(.filter-disabled) {
  background-color: var(--park-gray-100);
  cursor: pointer;
}

li.selected .lblListElement:hover:not(.filter-disabled) {
  background-color: var(--green-90);
  text-decoration: underline;
}

.rightContent {
  margin-left: auto;
}

#divFacetList #lblCount {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}

#divFacetList .imgCheck {
  padding-right: 8px;
}
</style>
