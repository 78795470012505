<template>
  <transition name="slide">
    <div data-component-name="body-style-filter">
      <div v-show="!bodyStyleFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="this.facetFilters[filterType] ? true : false"
          :title="$t('Body Style Filter Title')"
          :data-for="$t('Body Style Filter Title')"
        />
      </div>
      <div v-show="bodyStyleFilterSelected" >
        <div class="bodyStyleFilter">
          <FacetList
            class="bodyStyleFilterFacetList"
            v-model="facetModel"
            :title="title"
            :data="facetList"
            :filterType="filterType"
          ></FacetList>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import FacetList from "@components-vue/List/FacetList.vue";
import FilterMenuLineItem from "../FilterMenuLineItem.vue";
import { facetMetaData } from "@util/facetHelper.js";

export default {
  name: "BodyStyleFilter",
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FacetList,
    FilterMenuLineItem,
  },
  data() {
    return {
      title: this.$t("Body Style Filter Title"),
      filterType: facetMetaData.bodystyle.key,
      cachedCount: {},
      updateCachedCount: true,
    };
  },
  computed: {
    ...mapState("srp", {
      facetFilters: (state) => state.facetFilters ?? {},
      filterSelected: (state) => state.filterSelected,
      bodyStyleFilterSelected: (state) => state.bodyStyleFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      bodyStyleCount: (state) => state.facetCounts?.bodystyle || {},
      recentSelectedFilter: (state) => state.recentSelectedFilter,
    }),
    facetList() {
      return this.fields?.BodyStyle?.map((x) => ({
        name: x.displayName,
        value: this.getCount(x),
        scImage: x.fields?.BodyStyleIcon,
      }));
    },
    getCount() {
      return (x) =>
        this.cachedCount.hasOwnProperty(x.displayName)
          ? this.cachedCount[x.displayName]
          : 0;
    },
    icons() {
      return "BodyStyleIcons";
    },
    facetModel() {
      return this.facetList;
    },
  },
  methods: {
    ...mapMutations("srp", [
      "setFilterSelected",
      "setBodyStyleFilterSelected",
      "setResetSelectedFacetItems",
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setBodyStyleFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
  },
  watch: {
    recentSelectedFilter(newValue, oldValue) {
      if (newValue === this.filterType && oldValue !== this.filterType)
        this.updateCachedCount = false;
      else this.updateCachedCount = true;
    },
    bodyStyleCount() {
      this.cachedCount = this.bodyStyleCount;
    },
    bodyStyleFilterSelected(isSelected) {
      if (isSelected) {
        this.$root.$emit("fetch-facet-count", this.filterType);
      }
    },
    facetFilters: {
      handler: async function () {
        if (this.updateCachedCount && this.bodyStyleFilterSelected) {
          this.$root.$emit("fetch-facet-count", this.filterType);
          this.cachedCount = this.bodyStyleCount;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.bodyStyleFilter {
  padding-top: 20px;
  padding-bottom: 20px;
}

.filter-selected .bodyStyleFilter {
  padding-top: 0;
}

.bodyStyleFilterFacetList {
  width: 100%;
}
</style>
