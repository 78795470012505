import axios from "axios";
import config from '../appConfig';

const LOCAL_HOST = config.sitecoreApiHost;

const api = axios.create({
    baseURL: LOCAL_HOST + "api/sitecore",
});

export default {
    async toggleFavorite (vin) {
        let data = null;

        await api.post("/Favorites/ToggleFavorite", {productId: vin})
        .then(response => {
            data = response.data
        })
        .catch(error => {
          this.errorMessage = error.message;
        });
        return data;
    },

    async getFavoritesLines () {
        let data = null;

        await api.get("/Favorites/GetFavoriteLines")
        .then(response => { 
            data = response.data
        })
        .catch(error => {
          this.errorMessage = error.message;
        });

        return data;
    }
}