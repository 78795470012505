<template>
    <div>
        <div class="registrationOuterContainer d-none">
            <div id="registrationContainer" class="create_account">
                <div class="div-loading flyoutLoader" style="display: none;"></div>
                <sc-rich-text :field="fields.html" />
            </div>
        </div>

        <!-- Confirmation Display -->
        <div class="accountConfirmationOuterContainer d-none">
            <div id="accountConfirmationContainer" class="create_account">
                <div class="create_account_title">
                    <span class="create_account_close">
                        <img src="/-/media/Feature/EchoPark/Account/EchoparkLogin/icons-menu-close.svg?iar=0&amp;hash=A72D0C4A2849A3EB80563D85FE8E4E3A" alt="Close">
                    </span>
                </div>

                <div class="account_infobox">
                    <div class="status_icon">
                        <img src="/-/media/Project/Sonic/EchoPark/Icons/icons-circle-check.svg?iar=0&amp;hash=5113017CC71ECF6F53D655605B3E8024" alt="Check icon">
                    </div>
                    <div class="HeadingParkGray-H4">Account created</div>
                    <p class="ParagraphParkGray-BodySM">
                        You can now save favorites <br>
                        Access favorites on any device <br>
                        Get price alerts&nbsp;
                    </p>
                </div>

                <form action="" role="form" autocomplete="off">
                    <div class="form-group-16 submit-btn">
                        <a class="btn btn-primary" href="/used-cars" id="accountCreatedUsedCars">Shop all cars</a>
                    </div>
                    <div class="form-group-16">
                        <a class="btn btn-secondaryDark" href="/my-account" id="accountCreatedDefualtPageLink">View my account</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import {onLoginMount} from "../../assets/js/login";
import {onRegistrationMount} from "../../assets/js/registration";

export default {
    name: 'Registration',
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        ScRichText: RichText,
    },
    mounted() {
        onLoginMount();
        onRegistrationMount();
    },
    updated() {
        onLoginMount();
        onRegistrationMount();
    }
};
</script>

<style>
#registrationContainer {
    z-index: 81;
}

#accountConfirmationContainer {
    z-index: 81;
}

.registrationOuterContainer { 
    position: fixed;
    z-index: 81; 
}

.registrationOuterContainer .btn {
    background-color: var(--green-50);
    color: var(--base-white-100);
}

.accountConfirmationOuterContainer { 
    position: fixed;
    z-index: 81; 
}

.accountConfirmationOuterContainer .btn {
    background-color: var(--green-50);
    color: var(--base-white-100);
}

</style>