export function SendTikTokCapiEvent(capiModel, tiktokEventName) {
    if(tiktokEventName)
    capiModel.eventName = tiktokEventName;

    $.ajax({
        url: '/api/capi/tiktok',
        type: 'POST',
        dataType: 'json',
        data: capiModel
    });
}

export function SendFacebookCapiEvent(capiModel) {
    $.ajax({
        url: '/api/capi/fb',
        type: 'POST',
        dataType: 'json',
        data: capiModel
    });
}

export function GenerateCapiEventId(eventName) {
    let id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
        let eventid = eventName + '-' + id + '-'+Date.now();
        return eventid;
}

export function generateCapiModel(eventName, contentName, capiEventId, requestUrl, baseUrl, vin, userData, contentCategory, value, search, fbp, fbclid) {
    let capiModel = {
        eventName: eventName,
        contentName: contentName,
        eventId: capiEventId,
        requestUrl: requestUrl,
        baseUrl: baseUrl,
        vin: vin,
        userData: userData,
        contentCategory: contentCategory,
        value: value,
        search: search,
        fbp: fbp,
        fbclid : fbclid
    }

    return capiModel;
}

export function getUserData(firstName, lastName, street, city, state, zipCode, email, phone, userAgent) {
    let userData = {
        firstName: firstName,
        lastName: lastName,
        street: street,
        city: city,
        state: state,
        zipCode: zipCode,
        email: email,
        phone: phone,
        clientUserAgent: userAgent
    }
    return userData;
}

export function getFbClientId() {
    let result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
    if (!(result && result[1])) {
        return null;
    }
    return result[1];
}

export function getFbClidFromUrl() {
    let params = new URLSearchParams(window.location.search);

    if (params.has('fbclid'))
        return params.get('fbclid');

    return null;
}

export function getCookie(name) {
    const dc = document.cookie;
    const prefix = name + "=";
    let begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    } else {
        begin += 2;
    }
    let end = document.cookie.indexOf(";", begin);
    if (end == -1) {
        end = dc.length;
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
}

export function getQueryParam(q) {
    const params = new URLSearchParams(window.location.search);
    if (params.has(q)) {
        return params.get(q);
    }
    return null;
}
export function generateCapiModelSrp(eventName, contentName, capiEventId, requestUrl, baseUrl, vin, userData, contentCategory, value, search, fbp, fbclid) {
    let capiModel = {
        eventName: eventName,
        contentName: contentName,
        eventId: capiEventId,
        requestUrl: requestUrl,
        baseUrl: baseUrl,
        vin: vin,
        userData: userData,
        contentCategory: contentCategory,
        value: value,
        search: search,
        fbp: fbp,
        fbclid: fbclid
    }

    return capiModel;
}

export function getUserDataSrp(firstName, lastName, street, city, state, zipCode, email, phone, userAgent) {
    let userData = {
        firstName: firstName,
        lastName: lastName,
        street: street,
        city: city,
        state: state,
        zipCode: zipCode,
        email: email,
        phone: phone,
        clientUserAgent: userAgent
    }
    return userData;
}

function initializeDocument(){
    const FB_COOKIE = 'fbclid';
    const GC_COOKIE = 'gclid';

    const f = getCookie(FB_COOKIE);
    const g = getCookie(GC_COOKIE);

    const fq = getQueryParam(FB_COOKIE);
    const gq = getQueryParam(GC_COOKIE);

    if (!f && !fq) {
        document.cookie = `${FB_COOKIE}=${fq}; path=/`;
    }
    if (!g && !gq) {
        document.cookie = `${GC_COOKIE}=${gq}; path=/`;
    }
}
if(typeof window !== 'undefined'){
    initializeDocument();
}