<template>
  <transition name="slide">
    <div>
      <div v-show="!mpgEngineFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="
            this.facetFilters[filterTypeMpg] || this.facetFilters[filterTypeEngine] ? true : false
          "
          :title="$t('MPG Engine Filter Title')"
        />
      </div>
      <div v-show="mpgEngineFilterSelected">
        <div class="mpgRangeFrameInput">
          <RangeInput
            :title="titleMpg"
            :range="mpgRange"
            @input="updateMpgStore"
            @focus="setUpdateStoreMpg = true"
            :setRange="setRange"
            :minLabel="minLabel"
            :maxLabel="maxLabel"
            data-action="range-input"
          ></RangeInput>
        </div>
        <div class="mpgRangeFrameSlider">
          <RangeSlider
            style="width: 80%"
            :range="mpgRange"
            :setRange="setRange"
            v-model="mpgModel"
            @sliderValueUpdated="updateMpgRange"
            @sliderValueSet="updateMpgStore"
            data-action="range-slider"
          ></RangeSlider>
        </div>
        <div class="engineTypeFilter" style="max-height: 50%">
          <FacetList
            style="width: 100%; height: 100vh"
            v-model="engineModel"
            :title="titleEngineType"
            :data="facetList"
            :filterType="filterTypeEngine"
          ></FacetList>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import RangeSlider from '@components-vue/Range/RangeSlider.vue';
import RangeInput from '@components-vue/Range/RangeInput.vue';
import FacetList from '@components-vue/List/FacetList.vue';
import FilterMenuLineItem from '../FilterMenuLineItem.vue';
import { facetMetaData } from '@util/facetHelper.js';

export default {
  name: 'MpgEngineTypeFilter',
  props: ['filterName', 'fields'],
  components: {
    RangeSlider,
    RangeInput,
    FacetList,
    FilterMenuLineItem,
  },
  data() {
    return {
      mpgRange: [],
      setRange: [],
      titleMpg: this.$t('MPG Filter Title'),
      titleEngineType: this.$t('Engine Filter Title'),
      minLabel: this.$t('Min Label'),
      maxLabel: this.$t('Max Label'),
      filterTypeEngine: facetMetaData.enginedescription.key,
      filterTypeMpg: facetMetaData.hmpg.key,
      setUpdateStoreMpg: true,
      cachedCount: {},
      updateCachedCount: true,
    };
  },
  computed: {
    ...mapState('srp', {
      filterSelected: (state) => state.filterSelected,
      mpgEngineFilterSelected: (state) => state.mpgEngineFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      facetFilters: (state) => state.facetFilters ?? {},
      vehicleHmpgMin: (state) => state.srpVehiclesData?.facets?.hmpg?.min,
      vehicleHmpgMax: (state) => state.srpVehiclesData?.facets?.hmpg?.max,
      engineTypeCount: (state) => state.facetCounts?.enginedescription || {},
      recentSelectedFilter: (state) => state.recentSelectedFilter,
    }),
    facetList() {
      return this.fields?.EngineType?.map((x, index) => ({
        name: x.fields?.EngineTypeCode?.value,
        value: this.getCount(x),
      }));
    },
    mpgModel: {
      get() {
        return this.mpgRange;
      },
      set(value) {
        this.updateMpgStore(value);
      },
    },
    engineModel() {
      return this.facetList;
    },
    getCount() {
      return (x) =>
        this.cachedCount.hasOwnProperty(x.fields?.EngineTypeCode?.value)
          ? this.cachedCount[x.fields?.EngineTypeCode?.value]
          : 0;
    },
  },
  created() {
    this.setUpdateStoreMpg = false;
    this.setInitialHmpgValues();
  },
  methods: {
    ...mapMutations('srp', [
      'setFilterSelected',
      'setMpgEngineFilterSelected',
      'setResetSelectedFacetItems',
    ]),
    setInitialHmpgValues() {
      let lowestMinInt = parseInt(this.vehicleHmpgMin ?? 0);
      let highestMaxInt = parseInt(this.vehicleHmpgMax ?? 100);
      this.setRange = [lowestMinInt, highestMaxInt];
      this.mpgRange = [lowestMinInt, highestMaxInt];
    },
    expandFilter() {
      this.setFilterSelected(true);
      this.setMpgEngineFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    updateMpgRange(values) {
      this.setUpdateStoreMpg = true;
      let minInt = parseInt(values[0]);
      let maxInt = parseInt(values[1]);
      this.mpgRange = [minInt, maxInt];
    },
    updateMpgStore(values) {
      if (this.setUpdateStoreMpg) {
        let minInt = parseInt(values[0]);
        let maxInt = parseInt(values[1]);

        const data = {
          name: this.filterTypeMpg,
          value: { min: minInt, max: maxInt },
        };

        clearTimeout(this.updateTimer);
        this.updateTimer = setTimeout(() => {
          this.mpgRange = [minInt, maxInt];
          this.$store.commit('srp/setNumericFacetFilter', data);
          this.$root.$emit('filter-updated-srp');
        }, 2000);
      }
    },
  },
  watch: {
    facetFilters: {
      handler: async function (newValue) {
        if (!newValue.hasOwnProperty(this.filterTypeMpg)) {
          this.mpgRange = [this.setRange[0], this.setRange[1]];
          this.setUpdateStoreMpg = false;
        } else {
          this.mpgRange = [
            this.facetFilters[this.filterTypeMpg].min,
            this.facetFilters[this.filterTypeMpg].max,
          ];
        }

        if (this.updateCachedCount && this.mpgEngineFilterSelected) {
          this.$root.$emit('fetch-facet-count', this.filterTypeEngine);
          this.cachedCount = this.engineTypeCount;
        }
      },
      deep: true,
    },
    vehicleHmpgMax: 'setInitialHmpgValues',
    recentSelectedFilter(newValue, oldValue) {
      if (newValue === this.filterTypeEngine && oldValue !== this.filterTypeEngine)
        this.updateCachedCount = false;
      else this.updateCachedCount = true;
    },
    engineTypeCount() {
      this.cachedCount = this.engineTypeCount;
    },
    mpgEngineFilterSelected(isSelected) {
      if (isSelected) {
        this.$root.$emit('fetch-facet-count', this.filterTypeEngine);
      }
    },
  },
};
</script>

<style scoped>
.mpgRangeFrameSlider {
  padding: 0 0 30px !important;
}

.engineTypeFilter {
  padding-top: 0;
  padding-bottom: 20px;
}
</style>
