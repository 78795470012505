<template>
  <div class="dynamicLinkArea">
    <textButtonCarousel class="dynamicLinkItem" v-for="list, key in getDynamicLinkData" 
    :title="list.title" :textBunttonList="list.links" :carouselId="list.id" 
    @btnclick="btnClickHandler"
    :btnStyle="btnStyle()" :key="`link-carousel-${key}`"/>
  </div>
</template>

<script>
import textButtonCarousel from "@components/buttons/textButtonCarousel.vue";
import { mapGetters, mapState } from "vuex";
import { facetMetaData } from "@util/facetHelper.js";


export default {
  name: "DynamicLinkArea",
  data(){
    return{
      mmtFacet: {},
      expandedMake: null,
      expandedModel: null,
    }
  },
  components: {
    textButtonCarousel
  },
  computed: {
    ...mapGetters("srp", ["getDynamicLinkData"]),
    ...mapState('srp', {
      filterSelected: (state) => state.filterSelected,
      bodyStyleFilterSelected: (state) => state.bodyStyleFilterSelected,
      vehicleData: (state) => state.srpVehiclesData
    }),
  },
  methods:{
    btnClickHandler(link, facet)
    {
      if (facet == facetMetaData.mmt.key){
        
        let mmtFilters = link.split('|');
        this.$set(this.mmtFacet, mmtFilters[0], { selected: true }) //sets make
        
        if(mmtFilters[1])
        {
          this.expandedMake = mmtFilters[0];
          this.$set(this.mmtFacet[this.expandedMake], mmtFilters[1], { selected: true }) // sets model
        }
        
        if(mmtFilters[2]){
          this.expandedModel = mmtFilters[1];
          this.$set(this.mmtFacet[this.expandedMake][this.expandedModel], mmtFilters[2], { selected: true }) // sets trim
        }
        
        const data = {
          "name": facet,
          "value": this.mmtFacet
        }
        this.$store.commit('srp/setHierarchicalFacetFilter', data);
      }
      else{
        
        const data = {
          "name": facet,
          "value": [link]
        }
        this.$store.commit('srp/setStringFacetFilter', data);
      }
      
      this.$root.$emit('filter-updated-srp');
    },
    btnStyle()
    {
      if(typeof window !== 'undefined' && window.innerWidth > 992)
      return "";
      return "small";
    },
  }
}
</script>

<style scoped>
.dynamicLinkArea{
  display: flex;
  flex-wrap: wrap;
  justify-content: unset;
  gap: 40px;
  padding-top: 26px;
}

.dynamicLinkItem{
  width: 100%;
}
</style>