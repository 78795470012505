<template>
    <div>
        <div class="account_overlay"></div>
        <div id="accountConfirmationContainer" class="create_account">
            <sc-rich-text :field="fields.html" />
        </div>
    </div>
</template>

<script>

import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { onLoginMount } from "../../assets/js/login";

export default {
    name: 'Account Confirmation',
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        ScRichText: RichText,
    },
    mounted() {
        onLoginMount();
    },
    updated() {
        onLoginMount();
    }
};
</script>

<style>
#accountCreatedDefualtPageLink {
    background-color: transparent;
    color: var(--park-gray-0);
    border-color: var(--park-gray-10);
    box-shadow: none;
}

#accountCreatedDefualtPageLink:hover {
    background-color: transparent;
    color: var(--park-gray-0);
    border-color: var(--park-gray-10)
}

#accountCreatedDefualtPageLink:focus {
    background-color: transparent;
    color: var(--park-gray-0);
    border-color: var(--supplementary-blue-80)
}

#accountCreatedDefualtPageLink:disabled {
    background-color: transparent;
    color: var(--park-gray-70);
    pointer-events: none;
    border-color: var(--park-gray-80)
}
</style>