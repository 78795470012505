export const menuMoreLinks = () => {
    $(".more_links").click(function () {
        $(".submenu").toggleClass("active");
    });
    $(".submenu, .more_links").on("click", function (e) {
        e.stopPropagation();
    });
    $(window).on("click", function () {
        $(".submenu").removeClass("active");
        $("html").removeClass("menu-open");
    });
    $(".toggle_menu").click(function () {
        $("html, body").removeClass();
        $("html").addClass("menu-open");
        $(".sidebar").hide();
    });
    $(".menu_close").click(function () {
        $("html").removeClass("menu-open");
    });
    $(".toggle_menu").on("click", function (e) {
        e.stopPropagation();
    });
}