import appConfig from '@/appConfig';
import validationFunctions from '@util/validationFunctions.js';
import axios from 'axios';
const API_URL = appConfig.storesApiEndpoint;
const ZIP_REGEX = /^\d+$/;

export default {
    isValidZip(zipcode) {
        return new Promise((resolve, _reject) => {
            if (zipcode == "") resolve(false);
            if (zipcode.length > 0) {
                if(!ZIP_REGEX.test(zipcode)) resolve(false);
                this.isUSAZipCode(zipcode).then(isUSA => {
                    resolve(isUSA);
                }).catch(error => {
                    console.log(error);
                    resolve(false);
                })
            }
        });
    },
    isUSAZipCode(zipcode) {
        return axios.post(API_URL, { zip: zipcode })
        .then((response) => {
            if (response.data != null && response.data != undefined) {
                if (!validationFunctions.IsNullOrEmpty(response.data.Error)) {
                    return false;
                } else if (response.data.IsUSAZipcode == 1) {
                    return true;
                }
            }
        }).catch((error) => {
            console.error(error);
            return false;
        })
    }
}