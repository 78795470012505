export function updatePhone(phone) {
    UpdateFooterPhonenumber(phone);
    UpdatePrequalDeniedPopupPhone();
}

function UpdateFooterPhonenumber(phone) {
    if(phone){
        let href = phone.replace(/\D/g,'');
        $("#ancFooterPhoneMobile").attr("title", phone).attr("href", 'tel:' + href).html(phone);

        let footerDiv = $('.footer-primary');
        let desktopPhoneDiv = $("<div>", {"class": "footer-phone d-lg-block"});
        let desktopPhoneLink = $("<a>", {id: "ancFooterPhoneDesktop", "class": "phNumber"}).attr("title", phone).attr("href", 'tel:' + href).html(phone);

        desktopPhoneDiv.append(desktopPhoneLink);
        footerDiv.append(desktopPhoneDiv);
    }
}

function UpdatePrequalDeniedPopupPhone() {
    var spanPrequalPhone = $("#spPrequalPhone");
    if (spanPrequalPhone != null && spanPrequalPhone != undefined && spanPrequalPhone.length > 0) {
        $(spanPrequalPhone).html($("#ancFooterPhoneDesktop").html());
    }

    var ancPrequalDeniedCallUs = $("#ancPrequalDeniedCallUs");
    if (ancPrequalDeniedCallUs != null && ancPrequalDeniedCallUs != undefined && ancPrequalDeniedCallUs.length > 0) {
        $(ancPrequalDeniedCallUs).attr("href", $("#ancFooterPhoneDesktop").attr("href"));
    }
}