<template>
  <div v-if="hasResults" id="disclaimer-container">
    <sc-rich-text :field="fields.description"> </sc-rich-text>
  </div>
</template>

<script>
import DynamicLinkArea from "../DynamicLinkArea/DynamicLinkArea.vue";
import { RichText } from "@sitecore-jss/sitecore-jss-vue";
import { mapState } from "vuex";

export default {
  name: "Disclaimer",
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ScRichText: RichText,
    DynamicLinkArea,
  },
  computed: {
    ...mapState("srp", ["srpVehiclesData", "loading"]),
    resultCount() {
      if (!this.srpVehiclesData) return -1;
      return this.srpVehiclesData.resultCount;
    },
    hasResults() {
      return !this.loading && this.resultCount > 0;
    },
  },
};
</script>

<style scoped>
#disclaimer-container {
  order: 8;
  color: var(--park-gray-0);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: justify;
  padding: 14px 24px 10px 24px;
  max-width: calc(100vw - 324px);
}

@media only screen and (max-width: 1240px) {
  #disclaimer-container {
    max-width: 100vw;
  }
}

.disclaimer-container-dynamic-link-area {
  padding-bottom: 40px;
}
</style>
