<template>
    <button :id=id :class="['navigationButton', { 'disabled': disabled }]" @click="handleButtonClick">
        <ArrowIconPagination :direction="direction" :disabled="disabled" />
    </button>
</template>
  
<script>
import ArrowIconPagination from '@icons/ArrowIcons/ArrowIconPagination/ArrowIconPagination.vue';

export default {
    data() {
        return {};
    },
    name: "NavigationButton",
    props: {
        id: {
            type: String,
        },
        direction: {
            type: String,
            default: "left"
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        handleButtonClick: {
            tyep: Function,
            default: () => ({})
        }
    },
    components: { ArrowIconPagination },
};
</script>
  
<style scoped>
.navigationButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: #3E8500;
}

.navigationButton.disabled {
    background-color: #eeecea;
    /* Change this to the desired disabled background color */
}
</style>
  