<template>
  <transition name="slide">
    <div
      v-show="isFilterMenuOpen"
      class="filter-menu-container"
      data-component-name="facet-menu"
    >
      <!-- Special menu for the DistanceAndShippingFilter factet when selected -->
      <div v-if="filterZipFlyout">
        <div class="filter-menu-header">
          <div
            class="filter-back align-items-center"
            v-if="filterSelected"
            @click="setFilterZipFlyout(false)"
            @keyup.enter="setFilterZipFlyout(false)"
            tabindex="0"
          >
            <img src="@icons/Path_filtermain.svg" class="arrow-back-icon" />
            <span class="ml-2">Distance &amp; shipping</span>
          </div>
          <div v-show="!filterSelected" class="filter-label">
            <sc-image :media="fields.icon"></sc-image>
            <sc-text :field="fields.label" class="filter-text"></sc-text>
          </div>
          <span v-on:click="toggleFilterMenu()" class="close-button">
            <CloseIcon />
          </span>
          <span
            class="desktop-reset-button"
            @click="resetFacets()"
            @keyup.enter="resetFacets()"
            tabindex="0"
            ><sc-text :field="fields.button"></sc-text
          ></span>
        </div>
      </div>
      <div v-else class="filter-menu-header-outer">
        <div class="filter-menu-header">
          <div
            class="filter-back align-items-center"
            v-if="filterSelected"
            @click="resetFilters()"
            @keyup.enter="resetFilters()"
            tabindex="0"
            data-action="return-to-all-filters"
          >
            <img src="@icons/Path_filtermain.svg" class="arrow-back-icon" />
            <span class="ml-2">All Filters</span>
          </div>
          <div v-show="!filterSelected" class="filter-label">
            <sc-image :media="fields.icon"></sc-image>
            <sc-text :field="fields.label" class="filter-text"></sc-text>
          </div>
          <span v-on:click="toggleFilterMenu()" class="close-button">
            <CloseIcon />
          </span>
          <span
            class="desktop-reset-button"
            @click="resetFacets()"
            @keyup.enter="resetFacets()"
            tabindex="0"
            data-action="reset-facets"
            ><sc-text :field="fields.button"></sc-text
          ></span>
        </div>

        <sc-placeholder
          name="filter-chip"
          :rendering="rendering"
          :hasBorderBottom="false"
          :isInMobileFilterMenu="false"
          :isFilterMenuOpen="this.isFilterMenuOpen"
          class="filter-chips-mobile"
          v-if="!filterSelected"
        />

        <div class="facet-action-links" v-if="!filterSelected">
          <span class="mobile-reset-link" @click="resetFacets()">
            <sc-text :field="fields.button"></sc-text>
          </span>
          <span
            v-if="facetChipsExpanded && isMobile && hiddenChips > 0"
            class="mobile-collapse-link"
            @click="hideFilterChips"
          >
            {{ $t("Show less") }} <ChevronDownIconBlue />
          </span>
        </div>
      </div>

      <div
        class="filter-list"
        :class="{ 'mt-0': filterZipFlyout }"
        data-menu-type="filter-list"
      >
        <sc-placeholder
          class="border-top"
          :class="{
            'border-top-0': filterZipFlyout,
            'filter-selected': filterSelected,
          }"
          :rendering="rendering"
          name="filter-category"
          data-role="container"
        />
      </div>

      <div class="filter-footer">
        <Button
          :text="`View ${formattedVehicleAmount} results`"
          @click="toggleFilterMenu"
        />
      </div>
    </div>
  </transition>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import { Text, Image, Placeholder } from "@sitecore-jss/sitecore-jss-vue";
import FilterIconMedium from "@assets/icons/FilterIcon/FilterIconMedium.vue";
import CloseIcon from "@assets/icons/CloseIcon/CloseIcon.vue";
import Button from "@components-vue/Buttons/Button";
import ChevronDownIconBlue from "@assets/icons/ChevronDownIcon/ChevronDownIconBlue";
import { facetMetaData } from "@util/facetHelper.js";

export default {
  name: "FilterMenu",
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FilterIconMedium,
    ChevronDownIconBlue,
    CloseIcon,
    ScText: Text,
    ScImage: Image,
    ScPlaceholder: Placeholder,
    Button,
  },
  data() {
    return {
      isFilterMenuOpen: false,
      isMobile: false,
    };
  },
  computed: {
    ...mapState("srp", {
      filterSelected: (state) => state.filterSelected,
      facetChipsExpanded: (state) => state.facetChipsExpanded,
      hiddenChips: (state) => state.hiddenChips,
      facetFilters: (state) => state.facetFilters ?? {},
      filterZipFlyout: (state) => state.filterZipFlyout,
      radius: (state) => state.radius,
      dealerships: (state) => state.dealerships,
    }),
    ...mapGetters("srp", ["formattedVehicleAmount"]),
  },

  mounted() {
    this.checkIsMobile();

    this.$root.$on("toggle-filter-menu", () => {
      this.isFilterMenuOpen = !this.isFilterMenuOpen;
    });

    window.addEventListener("resize", this.handleResize);
    if (this.isMobile) {
      this.isFilterMenuOpen = false;
    }
    //If the nearest store is outside the currently set radius for the new zip - then set it to Nationwide and fetch cars
    this.$root.$on("all-dealerships-list-updated", () => {
      if (this.radius > 0 && this.dealerships[0].distance > this.radius) {
        this.setRadius(facetMetaData.radius.radiusMap.nationwide.value);
      }
      this.$root.$emit("fetch-cars-srp");
    });
  },
  created() {},
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.$root.$off("all-dealerships-list-updated");
  },

  methods: {
    ...mapMutations("srp", [
      "setFilterSelected",
      "setClearFacets",
      "setFacetChipsExpanded",
      "resetStoreDefaults",
      "setFilterZipFlyout",
      "setRadius",
    ]),
    checkIsMobile() {
      if (typeof window !== "undefined") {
        this.isMobile = window.innerWidth < 1240;
      }
    },
    toggleFilterMenu() {
      this.setFilterZipFlyout(false);
      this.setFilterSelected(false);
      this.$root.$emit("toggle-filter-menu");
    },
    resetFacets() {
      this.resetFilters();
      this.setClearFacets();
      this.resetStoreDefaults(this.$jss?.sitecoreContext()?.layoutPath);
      this.$root.$emit("filter-updated-srp");
    },
    handleResize() {
      this.checkIsMobile();

      if (!this.isMobile) {
        this.isFilterMenuOpen = false;
        document.body.classList.remove("freeze-background");
      }
    },
    resetFilters() {
      this.setFilterSelected(false);
    },
    hideFilterChips() {
      this.setFacetChipsExpanded(false);
    },
  },
};
</script>

<style scoped>
.filter-footer {
  z-index: 150;
  padding: 16px;
  box-shadow: 0 0 5px 0 #00000024;
  border: 1px solid var(--park-gray-90);
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-list {
  flex: 1;
  overflow-y: auto;
}

.filter-selected {
  border-top: 0;
}

.filter-menu-container::-webkit-scrollbar {
  width: 0;
}

.filter-back {
  display: flex;
  align-self: center;
  font-size: 16px;
}

.filter-label {
  display: flex;
  align-self: center;
  font-weight: 700;
  align-items: center;
  gap: 8px;
  margin-bottom: 0;
}

.arrow-back-icon {
  /* #006FA6; */
  filter: brightness(0) saturate(100%) invert(18%) sepia(91%) saturate(3047%)
    hue-rotate(184deg) brightness(99%) contrast(102%);
  height: 14px;
  transform: rotate(180deg);
  margin-top: -2px;
}

.filter-menu-container {
  display: none;
  flex-direction: column;
  height: 100%;
  z-index: 70;
  background: var(--base-white-100);
}

.filterExpanded .filter-menu-container {
  display: flex;
}

.filter-menu-header-outer {
  position: sticky;
  top: 0;
}

.filter-menu-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  align-items: end;
}

.desktop-reset-button {
  display: none;
}

.facet-action-links {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.mobile-collapse-link svg {
  transform: rotate(180deg);
}

.filter-menu-container span {
  color: var(--system-alert-blue-60);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
}

.filter-menu-container p {
  color: var(--park-gray-0);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.filter-chip {
  position: unset;
}

.slide-enter {
  transform: translateX(-100%);
}

.slide-enter-active {
  transition: all 0.3s ease-in;
}

.slide-leave-active {
  transition: all 0.1s ease-in;
}

.slide-leave-to {
  transform: translateX(-100%);
}

.filter-chips-mobile {
  margin: -4px auto;
}

/*Tablet style */
@media only screen and (min-width: 625px) and (max-width: 1240px) {
  .filter-menu-container {
    width: 375px;
  }
}

/* Large devices (laptops/desktops, 1240px and up) */
@media only screen and (min-width: 1240px) {
  .filter-chips-mobile {
    display: none;
  }

  .filter-footer {
    display: none;
  }

  .filter-menu-container {
    display: flex !important;
    width: 306px;
    max-width: 306px;
    position: sticky;
    top: 0;
    border-right: 1px solid var(--park-gray-90);
    height: 100vh;
  }

  .scrollUp .filter-menu-container {
    top: 72px;
  }

  .filter-menu-header .close-button {
    display: none;
  }

  .filter-menu-header {
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    background: var(--base-white-100);
  }

  .filter-label img {
    width: 24px;
    height: 24px;
  }

  .facet-action-links {
    display: none;
  }

  .mobile-reset-link {
    margin-left: 24px;
    margin-bottom: 16px;
  }

  .desktop-reset-button:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .desktop-reset-button span {
    font-size: 14px;
    font-weight: 400;
  }

  .desktop-reset-button {
    display: block;
    width: 36px;
    height: 24px;
    line-height: 24px !important;
  }

  .filter-chip {
    display: none;
  }

  .filter-size-mock {
    position: sticky;
    top: 76px;
  }
}

span.filter-text {
  color: #242c33;
  cursor: default;
}
</style>
