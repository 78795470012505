<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
        <path d="M6.78197 5.05937L11.732 0.109375L13.146 1.52338L6.78197 7.88737L0.417969 1.52338L1.83197 0.109375L6.78197 5.05937Z" fill="#006FA6"/>
    </svg>
</template>

<script>
export default {
    name: "ChevronDownIconBlue"
};
</script>