import Vue from 'vue';
import Meta from 'vue-meta';
import VueApollo from 'vue-apollo';
import createStore from '../store';
import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue';
import AppRoot from '../AppRoot.vue';
import { createRouter } from '../router';
import SitecoreJssStorePlugin from '../library/SitecoreJssStorePlugin';
import GraphQLClientFactory from '../library/GraphQLClientFactory';
import config from '../appConfig';
import componentFactory from '../short-lived/componentFactory';
import ignoredElements from './ignoredElements';
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(Meta, { attribute: 'echo', ssrAppId: '1', tagIDKeyName: 'echo' });
Vue.use(SitecoreJssStorePlugin);
Vue.use(SitecoreJssPlaceholderPlugin, { componentFactory });
Vue.use(VueApollo);
Vue.use(BootstrapVue);

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

// createApp is invoked by both the main and SSR entry points, so the two entry points can use the same app creation process.
export function createApp(initialState, i18n, vuexState) {
  return new Promise((resolve) => {
    Vue.config.productionTip = false;
    Vue.config.ignoredElements = ignoredElements;
    const store = createStore();

    const router = createRouter();
    const graphQLProvider = createGraphQLProvider(initialState);

    if (vuexState) {
      store.replaceState(mergeDeep(store.state, vuexState));
    }

    const vueOptions = {
      apolloProvider: graphQLProvider,
      router,
      store,
      render: (createElement) => createElement(AppRoot),
      created: function () { },
    };
    // conditionally add i18n to the Vue instance if it is defined
    if (i18n) {
      vueOptions.i18n = i18n;
    }

    const app = new Vue(vueOptions);

    // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
    if (initialState) {
      app.$jss.store.setSitecoreData(initialState);
    }

    resolve({ app, router, graphQLProvider });
  });
}

export function createGraphQLProvider(initialState) {
  const client =
    initialState && initialState.APOLLO_STATE
      ? GraphQLClientFactory(config.graphQLEndpoint, false, initialState.APOLLO_STATE)
      : GraphQLClientFactory(config.graphQLEndpoint, true);

  const provider = new VueApollo({
    defaultClient: client,
  });

  return provider;
}
