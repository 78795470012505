import Vue from 'vue';


const eventBus = new Vue();

eventBus.$on('event', (eventData) => {
    if(typeof window !== "undefined"){
        if (window.dataLayer) {
            window.dataLayer.push(eventData);
        }
        else {
            window.dataLayer = [];
            window.dataLayer.push(eventData);
        }
    }
  });

export default eventBus;