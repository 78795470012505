import eventBus from "@assets/js/eventBus";
const RegisterSearchEvents = function (srpRelativeUrl) {
    $(function () {
        $(document).ajaxComplete(function (event, request, settings) {
            $('.searchInput').on('keyup', function () {
                if ($(this).val() !== '') {
                    $(this).val($(this).val().replace(/[%#Ⓡ]/gi, ''));
                    $('.search-bar').addClass('searchMob searchFocused');
                    $('.autocomplete-suggestions').show();
                    $('.searchAutoSaggetion--container').addClass('active');
                } else {
                    if ($(window).width() >= 768) {
                        $('.search-bar').removeClass('searchMob searchFocused');
                        $('.searchAutoSaggetion--container').removeClass('active');
                    }
                }
                if ($(window).width() <= 767) {
                    if ($(this).val() !== '') {
                        $('#clearSearch').show();
                    } else {
                        $('#clearSearch').hide();
                    }
                }
            });

            $(document).on('keyup', function (e) {
                if (e.key == "Escape") {
                    $('.search-bar').removeClass('searchMob searchFocused');
                    $('.searchAutoSaggetion--container').removeClass('active');
                    $('.search-bar').blur($(this).val(''));
                }
            });

            $(document).on('click', '.searchBarIn', function () {
                $('#searchbar').focus();
            });

            $('.searchInput').on('focus', function (e) {
                $('html, body').addClass('searchOverflowHidden');
                if ($(window).width() <= 767) {
                    $('.search-bar').addClass('searchMob searchFocused');
                    $('.searchAutoSaggetion--container').addClass('active');

                    if ($(this).val() !== '') {
                        $('#clearSearch').show();
                        $('.search-bar').addClass('searchMob searchFocused');
                    } else {
                        $('#clearSearch').hide();
                    }
                }
            }).on('blur', function () {
                $('.searchAutoSaggetion--container').removeClass('active');
                //$('html, body').removeClass('searchOverflowHidden');
            });

            $('#clearSearch').click(function () {
                $('.searchInput').val('').focus();
                $('#clearSearch').hide();
                if ($(window).width() >= 768) {
                    $('.search-bar').removeClass('searchMob searchFocused');
                    $('.searchAutoSaggetion--container').removeClass('active');
                }
            });

            $('#searchCancelMob').click(function () {
                $('.search-bar').removeClass('searchMob searchFocused');
                $('.autocomplete-suggestions').hide();
                $('html, body').removeClass('searchOverflowHidden');
            });

            $(window).on('scroll', function () {
                // $('.searchInput').blur();
                $('.searchAutoSaggetion--container').removeClass('active');
                $('html, body').removeClass('searchOverflowHidden');
            });
        });

        $("#btnSearchIcon").click(function () {
            let searchString = $.trim($('#searchbar').val());
            deleteCookie('searchString');
            setCookie('searchString', searchString, 1);
            GetSrpRelativeUrlForSearchKeywords(srpRelativeUrl);

            PushDataLayer();
        })

        $('#searchbar').keypress(function (event) {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == '13') {
                event.preventDefault();
                let searchString = $.trim($('#searchbar').val());
                deleteCookie('searchString');
                setCookie('searchString', searchString, 1);
                GetSrpRelativeUrlForSearchKeywords(srpRelativeUrl);
                PushDataLayer();
            }
        });

        var searchQuery = '';
        $('#searchbar').autocomplete({
            minChars: 2,
            deferRequestBy: 500,
            triggerSelectOnValidInput: false,
            lookup: function (queryStr, done) {
                var result;
                searchQuery = queryStr;
                $.ajax({
                    url: "/api/search-suggestion",
                    data: { "query": queryStr },
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    success: function (sonic) {
                        result = sonic;
                        if (result['suggestions'].length) {
                            localStorage.setItem('searchSuggestions', JSON.stringify(result));
                        }
                        done(result, function () {
                            $('.search-bar').addClass('searchMob searchFocused');
                            $('.autocomplete-suggestions').show();
                            $('.searchAutoSaggetion--container').addClass('active');
                        });
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });
            },
            appendTo: '.searchAutoSaggetion',
            onSelect: function (suggestion) {
                let obj = {
                    event: 'search complete',
                    location: 'header',
                    Search: searchQuery || '',
                    'Suggested term': searchQuery || '',
                    'Original Search': $("#searchbar").val() || '',
                };
      
                eventBus.$emit('event',  obj);
                if (!!suggestion.data) {
                    window.location = suggestion.data;
                } else {
                    let searchString = $.trim($('#searchbar').val());
                    deleteCookie('searchString');
                    setCookie('searchString', searchString, 1);
                    GetSrpRelativeUrlForSearchKeywords(srpRelativeUrl);
                }
            }
        });
    });
}

function GetSrpRelativeUrlForSearchKeywords(relativeUrl) {
    var srpRelativeUrl = relativeUrl;
    var searchKeywords = $.trim($('#searchbar').val());
    if (searchKeywords == null || searchKeywords == undefined || searchKeywords.length == 0) {
        window.location = srpRelativeUrl;
    }
    else {
        $.ajax({
            url: "/api/sitecore/vehicleslisting/GetSrpRelativeUrlForSearchKeywords",
            data: { keywords: searchKeywords },
            method: "POST",
            //contentType: "application/x-www-form-urlencoded",
            beforeSend: function () {
                ShowWaitDiv();
            },
            success: function (data) {
                if (data != null && data != undefined) {
                    if (data.Url === relativeUrl) {
                        RedirectToSRP_Page(relativeUrl)
                    } else {
                        window.location = data.Url;
                    }
                }
                else {
                    window.location = srpRelativeUrl;
                }
                //HideWaitDiv();
            },
            error: function (err) {
                console.log(err);
                window.location = srpRelativeUrl;
                HideWaitDiv();
            }
        });
    }
}

function selectSuggestion(id) {
    // SAUTO-712 - Global Header Search Complete
    PushDataLayer();

    $("#searchbar").val(id.innerText);
    suggestion_lists.hide();

    localStorage.setItem(ls_ep_sb_sugg, true);
    setCookie(ls_ep_sb_sugg, true);

    GetSrpRelativeUrlForSearchKeywords();
}

function RedirectToSRP_Page(relativeUrl) {
    var searchPageUrl = relativeUrl + "?query=" + $.trim($("#searchbar").val());
    window.location = searchPageUrl;
}

function PushDataLayer() {
    let obj = {
        event: 'search complete',
        location: 'header',
        Search: $('#searchbar').val() || '',
        'Original Search': $("#searchbar").val() || '',
    };

    eventBus.$emit('event',  obj);
}

function setCookie(name, value, days) {
    if (readCookie(name) == null) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    } else {
        document.cookie = name + "=" + value + "; path=/";
    }
}

function deleteCookie(name) {
    setCookie(name, "", -1);
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function ShowWaitDiv() {
    $("#divWait").show();
}

function HideWaitDiv() {
    $("#divWait").hide();
}

export default RegisterSearchEvents;