<template>
  <div class="SrpButton">
    <button
      v-if="onlyIcon"
      tabindex="0"
      class="default-button calculator-button"
    >
      <img
        v-if="calculator"
        src="@icons/calculator-hover.svg"
        class="onhover"
        alt="calculator icon"
      />
      <img
        v-if="calculator"
        src="@icons/calculator.svg"
        class="initial"
        alt="calculator icon"
      />
    </button>
    <button
      v-else
      @click="navigateToLinkClick"
      tabindex="0"
      class="wideButton"
      :class="{ 'carousel-button': carousel, 'default-button': !carousel }"
    >
      <img
        v-if="steeringWheel"
        class="onhover"
        src="@icons/steeringWheel-hover.svg"
        alt="steering wheel icon"
      />
      <img
        v-if="steeringWheel"
        class="initial"
        src="@icons/steeringWheel.svg"
        alt="steering wheel icon"
      />
      <img
        v-if="subtract"
        class="onhover"
        src="@icons/subtract-hover.svg"
        alt="subtract icon"
      />
      <img
        v-if="subtract"
        class="initial"
        src="@icons/subtract.svg"
        alt="subtract icon"
      />
      <span class="SrpButtonText">{{ buttonText }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "SrpButton",
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    calculator: Boolean,
    testDrive: Boolean,
    carousel: Boolean,
    steeringWheel: Boolean,
    subtract: Boolean,
    buttonText: String,
    onlyIcon: Boolean,
    linkClick: String,
  },
  methods: {
    navigateToLinkClick(e) {
      if (this.linkClick) {
        e.preventDefault();
        e.stopPropagation();
        window.location = this.linkClick;
      }
    },
  },
};
</script>

<style scoped>
.wideButton {
  width: 180px;
}

.SrpButton button:not(.wideButton) {
  width: 40px;
}

.SrpButton {
  display: flex;
  justify-content: flex-start;
}

.SrpButton a {
  text-decoration: none;
}

.SrpButton button {
  border-radius: 28px;
  background-color: transparent;
  min-width: 40px;
  height: 40px;
  white-space: nowrap;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-button {
  border: 2px solid var(--system-alert-blue-60);
  color: var(--system-alert-blue-60);
}

.default-button:hover:not(:active) {
  transition: 0.3s;
  border: 2px solid var(--supplementary-blue-50);
  color: var(--supplementary-blue-50);
}

.carousel-button {
  border: 2px solid var(--park-gray-90);
  color: var(--park-gray-90);
}

.carousel-button .SrpButtonText {
  font-weight: 500;
}

.SrpButtonText {
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Bold", "roboto medium", roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.onhover {
  display: none;
}

.default-button:hover:enabled:not(:active) .onhover {
  display: initial;
}

.default-button:hover:enabled:not(:active) .initial {
  display: none;
}
</style>
