export default {
  namespaced: true,
  /* https://v2.ssr.vuejs.org/guide/data.html#data-store */
  state: () => ({
    zipFlyout: false,
  }),
  getters: {},
  mutations: {
    setZipFlyout(state, value) {
      state.zipFlyout = value;
    },
  },
};
