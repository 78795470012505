<template>
    <svg :class="[{ 'disabled': disabled }, arrowClass]" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
        viewBox="0 0 24 24">
        <g transform="translate(-8 -8)">
            <path d="M13.172 12L8.222 7.05 9.636 5.636 16 12 9.636 18.364 8.222 16.95z" transform="translate(8 8)" />
        </g>
    </svg>
</template>

<script>
export default {
    name: "ArrowIconPagination",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        direction: {
            type: String,
            default: "up",
        }
    },
    computed: {
        arrowClass() {
            return {
                'rotate-0': this.direction === 'right',
                'rotate-90': this.direction === 'down',
                'rotate-180': this.direction === 'left',
                'rotate-270': this.direction === 'up',
            };
        },
    },
};
</script>

<style scoped>
.rotate-90 {
    transform: rotate(-90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-270 {
    transform: rotate(90deg);
}

path {
    fill: white;
}

.disabled path {
    fill: #bcbfc2;
}
</style>