import Vue from "vue";
import Vuex from "vuex";
import SrpStore from "./SRP/SrpStore";
import CommonStore from "./Common/CommonStore";

Vue.use(Vuex);
Vue.config.devtools = true;

export default () => new Vuex.Store({
  modules: {
    common: CommonStore,
    srp: SrpStore()
  },
});
