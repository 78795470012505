<template>
  <!--
        There is a 'strange' Figma requirement, that on selection of a particular option in a filter:

        The option should be 'InSelection' (checkbox will appear by option, BUT option will not move to the top of the list)
        When going out of the filter, and stepping back in, that option that was previously 'InSelection', moves to 'Selected' state.
        Basically, we have these two similar but different states of an option : (InSelection & Selected)
            InSelection - marks the option as selected in current interaction with filter.
            Selected - once you finish interaction with particular filter, and then you step back into the filter, InSelection options move to Selected state.
    -->
  <ul id="divFacetList">
    <span v-if="title" id="lblTitle">{{ title }}</span>
    <li
      v-if="isSelected(item.name) && item.value"
      @click="deselect(item.name)"
      @keyup.enter="deselect(item.name)"
      tabindex="0"
      :class="{ selected: isSelected(item.name), 'border-top': index === 0 }"
      v-for="(item, index) in data"
    >
      <FacetListItem
        :filterType="filterType"
        :item="item"
        :isSelected="true"
        :isInSelection="isInSelection(item.name)"
      />
    </li>
    <li
      v-if="isNotSelected(item.name) && item.value"
      @click="select(item.name)"
      @keyup.enter="select(item.name)"
      tabindex="0"
      :class="{ selected: isSelected(item.name), 'border-top': index === 0 }"
      v-for="(item, index) in data"
    >
      <FacetListItem
        :filterType="filterType"
        :item="item"
        :isSelected="false"
        :isInSelection="isInSelection(item.name)"
      />
    </li>
  </ul>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Text, Image, Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import FacetListItem from './FacetListItem.vue';

export default {
  name: 'FacetList',
  props: {
    title: {
      type: String,
    },
    data: {
      type: Array,
    },
    filterType: {
      type: String,
      default: '',
    },
  },
  components: {
    ScText: Text,
    ScImage: Image,
    ScPlaceholder: Placeholder,
    FacetListItem,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapState('srp', {
      facetFilters: (state) => state.facetFilters ?? {},
      selectedFacetFilters: (state) => state.selectedFacetFilters,
      reset: (state) => state.resetSelectedFacetItems,
    }),
    filteredInSelectionItems() {
      return this.facetFilters[this.filterType]
        ? this.facetFilters[this.filterType].facetValue
        : [];
    },
    filteredSelectedItems() {
      return this.selectedFacetFilters[this.filterType]
        ? this.selectedFacetFilters[this.filterType].filter((element) =>
            this.facetFilters[this.filterType]?.facetValue?.includes(element)
          )
        : [];
    },
    isSelected() {
      return (key) =>
        this.filteredSelectedItems ? this.filteredSelectedItems.includes(key) : false;
    },
    isInSelection() {
      return (key) =>
        this.filteredInSelectionItems ? this.filteredInSelectionItems.includes(key) : false;
    },
    isNotSelected() {
      return (key) =>
        this.filteredSelectedItems ? !this.filteredSelectedItems.includes(key) : true;
    },
  },
  methods: {
    ...mapMutations('srp', [
      'setStringFacetFilter',
      'setRemoveFacet',
      'setResetSelectedFacetItemList',
      'setSelectedFacetItems',
      'setRecentSelectedFilter',
    ]),
    getIcon(name) {
      let icon = this.data.find((obj) => obj.name === name);
      let iconName = icon.icon;
      return iconName;
    },
    select(key) {
      let items = this.filteredInSelectionItems;
      if (items && items.includes(key)) {
        const indexToRemove = items.indexOf(key);
        if (indexToRemove !== -1) {
          items.splice(indexToRemove, 1);
        }
      } else {
        items.push(key);
      }

      if (items.length === 0) {
        this.setRemoveFacet(this.filterType);
      } else {
        const data = {
          name: this.filterType,
          value: items,
        };
        this.setStringFacetFilter(data);
      }

      this.setRecentSelectedFilter(this.filterType);
      this.$root.$emit('filter-updated-srp');
    },
    deselect(key) {
      const items = this.filteredSelectedItems;
      if (items && items.includes(key)) {
        const indexToRemove = items.indexOf(key);
        if (indexToRemove !== -1) {
          items.splice(indexToRemove, 1);
        }
      }

      const data = {
        name: this.filterType,
        value: items,
      };

      this.setSelectedFacetItems(data);

      let selectedItems = this.filteredInSelectionItems;
      if (selectedItems && selectedItems.includes(key)) {
        const indexToRemove = selectedItems.indexOf(key);
        if (indexToRemove !== -1) {
          selectedItems.splice(indexToRemove, 1);
        }
      }

      if (selectedItems.length === 0) {
        this.setRemoveFacet(this.filterType);
      } else {
        const data = {
          name: this.filterType,
          value: selectedItems,
        };
        this.setStringFacetFilter(data);
      }

      this.setRecentSelectedFilter(this.filterType);
      this.$root.$emit('filter-updated-srp');
    },
  },
  watch: {
    reset(value) {
      if (value) {
        this.setResetSelectedFacetItemList(this.filterType);
      }
    },
  },
};
</script>

<style scoped>
#divFacetList {
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
}

.selected {
  background-color: var(--green-90);
}

.sc-image {
  margin-top: -6px;
  margin-bottom: -6px;
}

.selected .facetIcon {
  filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg) brightness(90%) contrast(103%);
}

#lblTitle {
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  height: auto;
  color: var(--park-gray);
  padding: 16px 24px;
}

#divFacetList > li:focus-visible {
  outline-offset: -3px;
}

#divFacetList > li:first-of-type {
  border-top: 1px solid var(--shadow-white);
}
</style>
