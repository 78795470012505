<template>
  <div>
    <div id="skipMainContent" :class="[zipFlyout ? 'freeze-bg' : '', scrollDirection]"  role="main">
      <sc-placeholder name="jss-header" :rendering="route" />
      <sc-placeholder name="jss-main" :rendering="route" class="jss-main" />
      <sc-placeholder name="jss-footer" :rendering="route" class="jss-footer" />
    </div>
  </div>
</template>

<script>
import { Placeholder } from "@sitecore-jss/sitecore-jss-vue";
import Copyright from "./components/copyright/Copyright.vue";
import { mapState } from "vuex";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/app.css";
import "./styles/global.css";

export default {
  name: "Layout",
  data() {
    return {
      headerData: {
        title: this.route.fields?.pageTitle?.value ?? "",
        meta: [
          {
            name: "description",
            content: this.route.fields?.["Meta Description"]?.value ?? "",
          },
          {
            name: "keywords",
            content: this.route.fields?.["Meta Keywords"]?.value ?? "",
          },
          { name: "og:title", content: this.route.fields?.["Meta Title"]?.value ?? "" },
          {
            name: "og:image",
            content: this.route.fields?.["OG Image"]?.value?.src ?? "",
          },
        ],
        link: [
          { rel: "canonical", href: this.$jss.sitecoreContext()?.canonicalUrl ?? "" },
        ],
        ogImage: null,
      },
      lastScrollTop: 0,
      scrollDirection: "scrollDown",
    };
  },
  props: {
    route: {
      type: Object,
      default: () => ({}),
    },
  },
  metaInfo() {
    const canonical = this.headerData.link.find((m) => m.rel == "canonical");
    if (canonical && canonical.href == "") {
      const index = this.headerData.link.indexOf(canonical);
      this.headerData.link.splice(index, 1);
    }
    return this.headerData;
  },
  components: {
    ScPlaceholder: Placeholder,
    Copyright,
  },
  methods: {
    handleScroll() {
      const currentScrollTop = Math.abs(document.body.getBoundingClientRect().top);
      if (currentScrollTop > this.lastScrollTop) {
        // scrolling down
        this.scrollDirection = "scrollDown";
      } else {
        if (currentScrollTop < 100) {
          // scrolling up
          // this ensures the scrollUp style stops 100 px from the top, so that the search bar reverts to its normal state
          this.scrollDirection = "";
        } else {
          this.scrollDirection = "scrollUp";
        }
      }
      this.lastScrollTop = currentScrollTop;
    },
  },
  computed: {
    ...mapState("common", ["zipFlyout"])
  },
  created() {
    this.headerData.title = this.route.fields?.["Meta Title"]?.value ?? "Page";
    let description = this.headerData.meta.find((m) => m.name == "description");
    let canonical = this.headerData.link.find((m) => m.rel == "canonical");

    if (!description) {
      description = { name: "description" };
      this.headerData.meta.push(description);
    }
    this.$root.$on("header-data-changed", (eventData) => {
      description.content = eventData.description;
      delete eventData.description;
      this.headerData = { ...this.headerData, ...eventData };
    });
    
    this.$root.$on("canonical-data-changed", (eventData) => {
      canonical.href = eventData.canonical;
      delete eventData.canonical;
      this.headerData = { ...this.headerData, ...eventData };
    });
  },
  mounted() {
    // NOTE: this is an expensive listener, and a debouncer should be added in the future
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
  .freeze-bg {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>