<template>
  <transition name="slide">
    <div data-component-name="distance-and-shipping-filter">
      <div v-show="!distanceShippingFilterSelected && !filterSelected">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="true"
          :title="pageTitle"
          data-role="facet-filter"
        />
      </div>
      <div v-if="distanceShippingFilterSelected" class="">
        <!-- Default View -->
        <div v-if="!filterZipFlyout">
          <div class="distance-shipping-title px-4 py-3">
            {{ pageTitle }}
          </div>
          <div
            class="divShipToStoreContainer px-4 py-3 d-flex"
            v-if="$t('Ship To Store Toggle Feature Flag') == 1"
          >
            <ShipToStoreToggle />
          </div>

          <!-- Clickable ZipCode -->
          <div class="d-flex flex-column align-items-center px-4 py-3">
            <span class="your-location">Your location:</span>
            <div>
              <span
                class="clickable-zip"
                @click="setFilterZipFlyout(true)"
                @keyup.enter="setFilterZipFlyout(true)"
                tabindex="0"
                data-action-toggle-filter="zip-code"
              >
                <sc-image class="icon-location-header-flyout" :media="fields.iconLocationFlyout" />
                {{ zip }}
              </span>
            </div>
          </div>

          <div class="shopByStoreToggle">
            <ShopByStoreToggle :fields="fields" />
          </div>
        </div>
        <!-- Zip Edit "Nested" View -->
        <div v-else class="zipLocation">
          <transition>
            <ZipLocation :fields="fields" />
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import ShipToStoreToggle from '../DistanceAndShipping/ShipToStoreToggle.vue';
import ZipLocation from './ZipLocation.vue';
import ShopByStoreToggle from './ShopByStoreToggle.vue';
import FilterMenuLineItem from '../FilterMenuLineItem.vue';

export default {
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      shopBy: 0,
      isDistanceAndShippingFilterOpen: true,
    };
  },
  computed: {
    ...mapState('srp', [
      'filterSelected',
      'distanceShippingFilterSelected',
      'zip',
      'filterZipFlyout',
    ]),
    pageTitle() {
      return this.$t('Ship To Store Toggle Feature Flag') == 1
        ? this.$t('Distance Shipping Filter Title')
        : this.$t('Distance stores Filter Title');
    },
  },
  name: 'DistanceAndShippingFilter',
  components: {
    ScText: Text,
    ScImage: Image,
    ShipToStoreToggle,
    ZipLocation,
    ShopByStoreToggle,
    FilterMenuLineItem,
  },
  created() {},
  destroyed() {},
  methods: {
    ...mapMutations('srp', [
      'setFilterSelected',
      'setDistanceShippingFilterSelected',
      'setResetSelectedFacetItems',
      'setFilterZipFlyout',
      'setRadius',
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setDistanceShippingFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
  },
};
</script>

<style scoped>
.shopping-near {
  color: var(--park-gray-0);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
}

/* Add this style to .clickable-zip to keep it aligned properly */
.clickable-zip {
  cursor: pointer;
  color: var(--Text-Colors-System-Blue60, #006fa6);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.your-location {
  color: var(--park-gray-0);
  font-family: Roboto;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 16px;
}
.divShipToStoreContainer {
  background-color: #f7f7f7;
}

.distance-shipping-title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
</style>
