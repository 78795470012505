<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.53125 4.5V6H16.0312V4.5H2.53125ZM7.78125 13.5H10.7812V12H7.78125V13.5ZM13.7812 9.75H4.78125V8.25H13.7812V9.75Z" fill="#242C33"/>
    </svg>
</template>

<script>
export default {
  name: "FilterIconSmall"
};
</script>