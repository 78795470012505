<template>
  <div>
    <div class="vehicleInfoDetails">
      <span>
        {{ year }}
      </span>
      <span class="vehicleInfoDetailsSeparator"> | </span>
      <span class="vehicleInfoDetailsMileage">
        {{ formatVehicleMilage(mileage) }} {{ $t("Car miles") }}
      </span>
      <span class="vehicleInfoDetailsSeparator"> | </span>
      <div
        class="vehicleInfoDetailsStockNumber"
        :id="`vehicleInfoDetailsStockNumber-${stockNumber}`"
        @click.prevent.stop="copyToClipboard"
      >
        {{ $t("Stock Number") }} {{ stockNumber }}
      </div>
    </div>
    <h2 class="vehicleInfoName">
      {{ formatVehicleName(make, model, trim) }}
    </h2>
    <b-tooltip
      placement="top"
      :show.sync="showStockNumberTooltip"
      :target="`vehicleInfoDetailsStockNumber-${stockNumber}`"
      :title="$t('Copied to Clipboard!')"
      triggers="manual"
    >
      {{ $t("Copied to Clipboard!") }}
    </b-tooltip>
  </div>
</template>

<script>
import { formatVehicleMilage } from "@util/formatFunctions.js";
import { formatVehicleName } from "@util/formatFunctions.js";

export default {
  name: "VehicleCardDetails",
  props: {
    year: Number,
    mileage: Number,
    stockNumber: String,
    make: String,
    model: String,
    trim: String,
    url: String,
  },
  data() {
    return {
      showStockNumberTooltip: false,
    };
  },
  methods: {
    formatVehicleMilage,
    formatVehicleName,
    copyToClipboard(e) {
      navigator.clipboard.writeText(this.stockNumber);
      this.showStockNumberTooltip = true;
      setTimeout(() => {
        this.showStockNumberTooltip = false;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.vehicleInfoDetails {
  color: var(--park-gray-0);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  margin-bottom: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
}

.vehicleInfoDetailsSeparator {
  margin-left: 4px;
  margin-right: 4px;
  color: var(--park-gray-70);
}

@media only screen and (max-width: 342px) {
  .vehicleInfoDetailsSeparator {
    margin-left: 4px;
    margin-right: 4px;
  }
}

@media only screen and (min-width: 625px) and (max-width: 675px) {
  .vehicleInfoDetailsSeparator {
    margin-left: 3px;
    margin-right: 3px;
  }
}

.vehicleInfoDetailsMileage {
  color: var(--park-gray-0);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
}

.vehicleInfoDetailsStockNumber {
  color: var(--park-gray-20);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vehicleInfoName {
  color: var(--park-gray-0);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
