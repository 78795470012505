export function numberWithCommas(x) {
  return Intl.NumberFormat().format(x);//x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
//#region VEHICLE INFORMATION FORMATING
export function formatVehicleName(make, model, trim) {
  return make + " " + model + " " + trim;
}

export function formatVehicleInstallmentPrice(low, high) {
  return "$" + numberWithCommas(low) + "-$" + numberWithCommas(high);
}

export function formatVehicleMilage(miles) {
  return miles >= 1000 ? `${Math.floor(miles / 1000)}K` : miles;
}

export function formatVehiclePrice(price) {
  return numberWithCommas(price);
}
//#endregion
