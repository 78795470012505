<template>
  <div class="vehicleInfoButtons">
    <span class="vehicleInfoButtonsCalculator">
      <SrpButton
        class="ignoreFullTileClick"
        onclick="document.activeElement.blur()"
        calculator
        onlyIcon
      />
    </span>
    <span class="vehicleInfoButtonsTestDrive">
      <SrpButton
        class="ignoreFullTileClick"
        :buttonText="$t('Test drive')"
        steeringWheel
        :linkClick="testDriveUrl"
        v-if="showTestDriveLink"
        onclick="document.activeElement.blur()"
      />
      <span
        @click.prevent.stop="openAskAboutUsModal()"
        v-on:keyup.space="openAskAboutUsModal()"
        v-on:keyup.enter="openAskAboutUsModal()"
        v-if="!showTestDriveLink"
      >
        <SrpButton
          class="ignoreFullTileClick"
          onclick="document.activeElement.blur()"
          :buttonText="$t('askUs')"
          subtract
        />
      </span>
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SrpButton from "@components/buttons/SrpButton.vue";
import eventHub from "@store/eventHub";

export default {
  name: "VehicleCardButtons",
  props: {
    vehicle: Object,
  },
  components: {
    SrpButton,
  },
  computed: {
    ...mapState("srp", {
      nearestDealership: (state) => state.nearestDealership,
    }),
    showTestDriveLink: {
      get() {
        if (this?.nearestDealership?.dealerId === this.vehicle.dealership) {
          if (this?.nearestDealership?.enableTestDrive) {
            return true;
          }
        }
        return false;
      },
    },
    testDriveUrl: {
      get() {
        return `${this.vehicle.url}?testDrive=1`;
      },
    },
  },
  methods: {
    openAskAboutUsModal() {
      eventHub.$emit("openAskAboutUsModal", {
        vin: this.vehicle.vin,
        isOpen: true,
      });
      this.$store.commit(
        "srp/setAskUsModalTitle",
        this.vehicle.make + " " + this.vehicle.model
      );
    },
  },
};
</script>

<style scoped>
.vehicleInfoButtons {
  display: flex;
  gap: 27px;
}

.vehicleInfoButtonsCalculator {
  flex: 1;
}

.vehicleInfoButtonsTestDrive {
  flex: 1;
  text-align: right;
}
</style>
