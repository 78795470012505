import { render, staticRenderFns } from "./PageNavigation.vue?vue&type=template&id=1021adef&scoped=true"
import script from "./PageNavigation.vue?vue&type=script&lang=js"
export * from "./PageNavigation.vue?vue&type=script&lang=js"
import style0 from "./PageNavigation.vue?vue&type=style&index=0&id=1021adef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1021adef",
  null
  
)

export default component.exports