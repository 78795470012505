<template>
    <div class="forgotPasswordOuterContainer d-none">
        <div class="account_overlay"></div>
        <div id="forgotPasswordContainer" class="create_account">
            <sc-rich-text :field="fields.html" />
        </div>
    </div>
</template>

<script>

import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import {onLoginMount} from "../../assets/js/login";
import {onForgotPasswordMount} from "../../assets/js/forgotPassword";

export default {
    name: 'ForgotPassword',
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        ScRichText: RichText,
    },
    mounted() {
        onLoginMount();
        onForgotPasswordMount();
    },
    updated() {
        onLoginMount();
        onForgotPasswordMount();
    }
};
</script>

<style>
#forgotPasswordContainer {
    z-index: 81;
}

.forgotPasswordOuterContainer{ 
    position: fixed;
    z-index: 81;
}

.forgotPasswordOuterContainer .btn {
    background-color: var(--green-50);
    color: var(--base-white-100);
}
</style>