<template>
  <div id="srptoggle" class="shopCar-section">
    <div>
      <div class="shopCar-section--tabs">
        <button
          type="button"
          v-for="(option, index) in options"
          :class="option.value == currentValue ? 'toggleBtn active' : 'toggleBtn'"
          v-on:click="updateValue(option.value)"
          :key="option.value"
          :id="option.text"
          :data-action-toggle="option.text"
        >
          {{ option.text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextToggle',
  props: {
    options: Array,
    value: { default: null, type: null | String | Number },
  },
  data() {
    return { currentValue: this.value };
  },
  computed: {},
  methods: {
    updateValue(newValue) {
      if (this.currentValue == newValue) {
        return;
      }
      this.currentValue = newValue;
      this.$emit('input', this.currentValue);
    },
  },
  watch: {
    value(newVal) {
      this.currentValue = newVal;
    },
  },
};
</script>

<style scoped>
.shopCar-section--tabs {
  border-radius: 20px;
  border: 1px solid var(--park-gray-80);
  background: var(--base-white-100);
  padding: 4px;
  margin: 0 24px;
  display: flex;
  justify-content: center;
}

.shopCar-section--tabs .toggleBtn {
  max-width: 327px;
  margin: 0 auto;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: var(--park-gray-0);
  cursor: pointer;
  padding: 8px 16px;
  border: 0;
  border-radius: 16px;
  display: flex;
  flex: 1;
  justify-content: center;
  white-space: nowrap;
  background-color: transparent;
}

.shopCar-section--tabs .toggleBtn.active {
  background-color: var(--system-green-50);
  color: var(--base-white-100);
}

.shopCar-section--tabs .toggleBtn:hover:not(.active) {
  background-color: transparent;
}
</style>
