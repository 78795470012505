<template>
  <div class="filter-and-sort-menu">
    <div class="sorting-menu-container">
      <sc-image :media="fields.SortIcon" class="sorting-icon"></sc-image>
      <div class="sort-by">
        <span class="sort-by-label"
          ><sc-text :field="fields.SortLabel"></sc-text>
        </span>
        <select v-model="selectedSortTypeModel">
          <option
            v-for="(option, index) in mappedSortOptions"
            :key="index"
            class="sort-dropdown-item"
          >
            {{ option.name }}
          </option>
        </select>
        <div
          v-if="showSortDropdown"
          class="sort-dropdown"
          v-on-clickaway="closeSortDropdown"
        ></div>
      </div>
    </div>
    <div class="filter-menu-cta-container" @click="toggleFilterMenu()">
      <sc-image :media="fields.FilterIcon" />
      <sc-text :field="fields.FilterLabel" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ChevronDownIconBlue from "@assets/icons/ChevronDownIcon/ChevronDownIconBlue";
import { mixin as clickaway } from "vue-clickaway";
import { Text, Image } from "@sitecore-jss/sitecore-jss-vue";
import eventBus from "@assets/js/eventBus";
import { sortMap } from "@util/facetHelper.js";

export default {
  data() {
    return {
      showSortDropdown: false,
    };
  },
  name: "FilterAndSortMenu",
  mixins: [clickaway],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ChevronDownIconBlue,
    ScText: Text,
    ScImage: Image,
  },
  computed: {
    ...mapState("srp", {
      sortType: (state) => state.sortType,
    }),
    sortOptions() {
      return this.fields.SortOptions;
    },
    mappedSortOptions() {
      return this.sortOptions?.map((option, index) => {
        const phraseValue = this.sortOptions[index]?.fields?.Phrase?.value?.trim() || 'No Phrase Available';
        const sortBy = sortMap.find(map => phraseValue.toLowerCase().includes(map.key))?.sortBy || 'No Sort By Available';
        const sortDirField = this.sortOptions[index]?.fields?.["Sort Direction"]?.value?.trim() || 'No Sort Direction Available';
        const sortDir = sortDirField !== 'No Sort Direction Available' ? sortDirField.split(',')[0] : 'No Sort Direction Available';
        return {
          name: phraseValue,
          sortBy: sortBy,
          sortDirection: sortDir
        };
      });
    },
    selectedSortTypeModel: {
      get() {
        return this.sortType.sortName;
      },
      set(value) {
        let optionResults = this.mappedSortOptions.filter((x) => x.name === value);
        let option = optionResults.length > 0 ? optionResults[0] : null;

        if (option) {
          this.selectedOption = option.name;
          this.showSortDropdown = false;

          this.$store.commit("srp/setSortType", {
            sortBy: option.sortBy,
            sortDirection: option.sortDirection,
            sortName: option.name,
          });
          this.$root.$emit("sort-updated-srp");
          let obj = {
            Selected: option.name,
            event: "sort selected",
          };

          eventBus.$emit("event", obj);
        }
      },
    },
  },
  methods: {
    ...mapMutations("srp", [
      "setSortType",
    ]),
    toggleFilterMenu() {
      this.$root.$emit("toggle-filter-menu");
    },
    toggleSortDropdown() {
      this.showSortDropdown = !this.showSortDropdown;
    },
    closeSortDropdown() {
      this.showSortDropdown = false;
    },
  },
};
</script>

<style scoped>
.filter-menu-cta-container {
  order: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: sticky;
  border-bottom: 1px solid var(--park-gray-90);
  background: var(--base-white-100);
  min-height: 52px;
  border-right: 1px solid var(--park-gray-90);
  z-index: 2;
}

.filter-menu-cta-container span {
  color: var(--supplementary-blue-60);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

select {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--supplementary-blue-60);
  background: 0;
  border: 0;
  cursor: pointer;
  position: relative;
  margin-left: -4px;
}

/* fix for spacing between sort label and select element */
.sort-by select:-moz-last-node {
  margin-left: unset;
}

.sorting-menu-container {
  float: right;
  order: 2;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  border-bottom: 1px solid var(--park-gray-90);
  background: transparent;
  gap: 8px;
  z-index: 3;
  position: sticky;
  height: 52px;
  background: var(--base-white-100);
}

.sort-by-label {
  color: var(--park-gray-0);
  display: block;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.sort-dropdown {
  background: var(--base-white-100);
  position: absolute;
  border: 1px solid var(--park-gray-90);
  top: 42px;
  max-width: 144px;
}

.sort-dropdown-item {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--park-gray-0);
  cursor: pointer;
  width: 100%;
  display: inline-block;
}

.sort-dropdown-item:hover {
  background: var(--supplementary-blue-60);
  color: var(--base-white-100);
}

@media only screen and (max-width: 1024px) {
  .sorting-menu-container {
    display: flex;
    padding-bottom: 0;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
  }
}

/* Large devices (laptops/desktops, 1240px and up) */
@media only screen and (min-width: 1240px) {
  .filter-menu-cta-container {
    display: none;
  }

  select {
    font-size: 16px;
    font-weight: 400;
  }

  .sorting-menu-container {
    order: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: unset;
    gap: 8px;
    margin: 24px 24px 0;
    max-height: 40px;
    background-color: var(--whitesmoke);
    width: auto;
  }

  .sorting-icon {
    display: none;
  }

  .sort-by-label {
    display: inline;
  }

  .sort-by {
    display: flex;
    flex-direction: row;
    gap: 8px;
    position: relative;
    align-items: center;
  }

  .sort-dropdown {
    top: 24px;
    right: 0;
  }
}
</style>
