import * as routeUtil from './routeUtilFunctions';

export function resolveUrl(router, params) {
  const { url, canonicalUrl } = resolveUrls(params);
  setUrl(router, url, canonicalUrl, params?.root);
}

function resolveUrls(params) {
  const { facetFilters, itemPath } = params;

  const buildUrlWithOptions = (options) => {
    const pathParams = buildPathParams(facetFilters, options).filter(param => param !== '');;
    const queryParamsList = buildQueryParams(params, options).filter(param => param !== '');;
    const finalPath = buildFinalPath(pathParams, queryParamsList);
    return buildUrl(finalPath, itemPath);
  };

  const urlOptions = {
    addNumeric: true,
    addRadius: true,
    addMmt: true,
    addShip: true,
    addSort: true,
    addDealerships: true,
    addString: true,
    addPage: true,
    isCanonical: false,
  };

  const canonicalOptions = {
    addNumeric: false,
    addRadius: false,
    addMmt: true,
    addShip: false,
    addSort: false,
    addDealerships: false,
    addString: true,
    addPage: true,
    isCanonical: true,
  };

  const url = buildUrlWithOptions(urlOptions);
  const canonicalUrl = buildUrlWithOptions(canonicalOptions);

  return { url, canonicalUrl };
}

function buildPathParams(facetFilters, options) {
  const params = [];

  if (options.addMmt) {
    routeUtil.addMmtPathParam(params, facetFilters, options.isCanonical);
  }
  if (options.addString) {
    routeUtil.addStringPathParams(params, facetFilters, options.isCanonical);
  }

  return params;
}

function buildQueryParams(params, options) {
  const {
    facetFilters,
    queryParams,
    ship,
    sortType,
    includedDealerships,
    radius,
    shopByStore,
    layoutPath,
  } = params;
  const qParams = [];

  if (options.addNumeric) {
    routeUtil.addNumericQueryParams(qParams, facetFilters);
  }
  if (options.addPage) {
    routeUtil.addPageQueryParam(qParams, queryParams);
  }
  if (options.addShip) {
    routeUtil.addShipQueryParam(qParams, ship);
  }
  if (options.addSort) {
    {
      routeUtil.addSortQueryParam(qParams, sortType);
    }
  }
  if (options.addDealerships) {
    routeUtil.addDealershipsQueryParam(qParams, includedDealerships, layoutPath);
  }
  if (options.addRadius) {
    routeUtil.addRadiusQueryParam(qParams, radius, shopByStore);
  }

  return qParams;
}

function buildFinalPath(pathParams, queryParams) {
  let finalPath = pathParams.length > 0 ? `/${pathParams.join('/')}` : '';
  if (queryParams.length > 0) {
    finalPath += `?${queryParams.join('&')}`;
  }
  return finalPath;
}

export function buildUrl(finalPath, itemPath) {
  return itemPath === '/' ? finalPath : `${itemPath}${finalPath}`;
}

function setUrl(router, url, canonicalUrl, root) {
  if (typeof window !== 'undefined') {
    let decodedLocation = decodeURI(window.location.href);
    if (!decodedLocation.endsWith(url)) {
      root?.$emit('canonical-data-changed', {
        canonical: window.location.origin + canonicalUrl,
      });
      router.push(url);
      root?.$store.commit('srp/incrementUrlCount');
    }
  }
}
