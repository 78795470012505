<template>
  <div id="divRangeDropdown">
    <span id="lblTitle">{{ title }}</span>
    <div id="divLblMinMaxOuter">
      <label id="selectMinLbl" for="textbox">{{ minLabel }}</label>
      <label id="selectMaxLbl" for="textbox">{{ maxLabel }}</label>
    </div>
    <div id="divMinMaxSelectContainer">
      <div id="divMinSelectContainer">
        <Dropdown
          :options="options.map((option) => ({ value: option, label: option }))"
          v-model="selected[0]"
          @input="emitUpdate"
        />
      </div>
      <div id="divMaxSelectContainer">
        <Dropdown
          :options="options.map((option) => ({ value: option, label: option }))"
          v-model="selected[1]"
          @input="emitUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "@components-vue/Select/Dropdown.vue";
export default {
  name: "RangeDropdown",
  props: {
    title: {
      type: String,
    },
    minLabel: {
      type: String,
    },
    maxLabel: {
      type: String,
    },
    options: {
      type: Array,
      default: function () {
        return [2000, 2023];
      },
    },
    selected: {
      type: Array,
      default: function () {
        return [2000, 2023];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    emitUpdate() {
      this.$emit("option-selected", this.selected);
    },
  },
  components: {
    Dropdown,
  },
};
</script>

<style scoped>
#divRangeDropdown #divLblMinMaxOuter,
#divRangeDropdown #divMinMaxSelectContainer {
  padding: 0 24px 4px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#lblTitle {
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  height: auto;
  color: #242c33;
  padding: 16px 24px;
}

#divRangeDropdown #selectMinLbl,
#divRangeDropdown #selectMaxLbl {
  display: inline-block;
  width: 100px;
  color: var(--park-gray-20);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

#divRangeDropdown #divMinMaxSelectContainer {
  padding-bottom: 30px;
}

#divRangeDropdown #divMinSelectContainer,
#divRangeDropdown #divMaxSelectContainer {
  width: 100px;
}

#divRangeDropdown #divMinSelectContainer select,
#divRangeDropdown #divMaxSelectContainer select {
  width: 100%;
}

#divRangeDropdown #selectMin,
#divRangeDropdown #selectMax {
  border-radius: 8px;
  border: 1px solid var(--park-gray-80);
  padding: 8px;
  width: 100%;
  height: 40px;
}

#divRangeDropdown .unselectedTrack {
  background-color: var(--park-gray-20);
}
</style>
