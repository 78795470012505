<template>
  <transition name="slide">
    <div data-component-name="drive-type-transmission-filter">
      <div v-show="!driveTypeFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter"
          :isSelected="isFilterSelected"
          :title="$t('Drive Type Transmission Filter Title')"
        />
      </div>
      <div v-show="driveTypeFilterSelected">
        <div class="driveTypeFilter">
          <FacetList
            style="width: 100%"
            v-model="driveTypeModel"
            :title="titleDriveType"
            :data="facetListDriveType"
            :filterType="driveFilterType"
            :data-action-select="titleDriveType"
          />
        </div>
        <div class="transmissionTypeFilter">
          <FacetList
            style="width: 100%"
            v-model="transmissionTypeModel"
            :title="titleTransmission"
            :data="facetListTransmission"
            :filterType="transmissionFilterType"
            :data-action-select="titleTransmission"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import FacetList from "@components-vue/List/FacetList.vue";
import FilterMenuLineItem from "../FilterMenuLineItem.vue";
import { facetMetaData } from "@util/facetHelper.js";

export default {
  name: "DriveTypeFilter",
  props: ["filterName", "fields"],
  components: {
    FacetList,
    FilterMenuLineItem,
  },
  data() {
    return {
      titleDriveType: this.$t("Drive Type Filter Title"),
      titleTransmission: this.$t("Transmission Filter Title "),
      driveFilterType: facetMetaData.drivetype.key,
      transmissionFilterType: facetMetaData.transmission.key,
      cachedCountDriveType: {},
      cachedCountTransmission: {},
      updateCachedCountDriveType: true,
      updateCachedCountTransmission: true,
    };
  },
  computed: {
    ...mapState("srp", {
      facetFilters: (state) => state.facetFilters ?? {},
      filterSelected: (state) => state.filterSelected,
      driveTypeFilterSelected: (state) => state.driveTypeFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      driveTypeCount: (state) => state.facetCounts?.drivetype || {},
      transmissionCount: (state) => state.facetCounts?.transmission || {},
      recentSelectedFilter: (state) => state.recentSelectedFilter,
    }),
    facetListTransmission() {
      return this.fields?.TransmissionType?.map((x) => ({
        name: x.displayName,
        value: this.getTransmissionCount(x),
      }));
    },
    facetListDriveType() {
      return this.fields?.DriveType?.map((x) => ({
        name: x.displayName,
        value: this.getDriveTypeCount(x),
      }));
    },
    getTransmissionCount() {
      return (x) =>
        this.cachedCountTransmission[x.displayName] || 0;
    },
    getDriveTypeCount() {
      return (x) =>
        this.cachedCountDriveType[x.displayName] || 0;
    },
    driveTypeModel() {
      return this.facetListDriveType;
    },
    transmissionTypeModel() {
      return this.facetListTransmission;
    },
    isFilterSelected() {
      return !!(this.facetFilters[this.driveFilterType] || this.facetFilters[this.transmissionFilterType]);
    },
  },
  methods: {
    ...mapMutations("srp", [
      "setFilterSelected",
      "setDriveTypeFilterSelected",
      "setResetSelectedFacetItems",
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setDriveTypeFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
  },
  watch: {
    recentSelectedFilter(newValue, oldValue) {
      if (newValue === this.driveFilterType && oldValue !== this.driveFilterType)
        this.updateCachedCountDriveType = false;
      else this.updateCachedCountDriveType = true;

      if (
        newValue === this.transmissionFilterType &&
        oldValue !== this.transmissionFilterType
      )
        this.updateCachedCountTransmission = false;
      else this.updateCachedCountTransmission = true;
    },
    driveTypeCount() {
      if (this.updateCachedCountDriveType)
        this.cachedCountDriveType = this.driveTypeCount;
    },
    transmissionCount() {
      if (this.updateCachedCountTransmission)
        this.cachedCountTransmission = this.transmissionCount;
    },
    driveTypeFilterSelected(isSelected) {
      if (isSelected) {
        this.$root.$emit("fetch-facet-count", this.transmissionFilterType);
        this.cachedCountTransmission = this.transmissionCount;
        this.cachedCountDriveType = this.driveTypeCount;
      }
    },
    facetFilters: {
      handler: async function () {
        if (this.updateCachedCountDriveType && this.driveTypeFilterSelected) {
          this.$root.$emit("fetch-facet-count", this.driveFilterType);
          this.cachedCountDriveType = this.driveTypeCount;
        } else if (this.updateCachedCountTransmission && this.driveTypeFilterSelected) {
          this.$root.$emit("fetch-facet-count", this.transmissionFilterType);
          this.cachedCountTransmission = this.transmissionCount;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.driveTypeFilter,
.transmissionTypeFilter {
  padding: 24px 0;
  max-height: 50%;
}
.driveTypeFilter {
  padding-top: 0;
}
</style>