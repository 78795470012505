<template>
  <transition>
    <div>
      <div class="zip-location-container">
        <ZipLocationEdit :fields="fields" v-model="zipCode" />
      </div>

      <ZipNearestStoresDisplay :fields="fields" :dealerships="dealerships" :closestDealership="nearestDealership" data-action="change-zip-code" />
    </div>
  </transition>
</template>

<script>

import { mapState, mapMutations } from "vuex";
import ZipLocationEdit from "@components-vue/ZipInfo/ZipLocationEdit";
import ZipNearestStoresDisplay from "@components/zip/ZipNearestStoresDisplay"
import DealershipCard from "@components-vue/ZipInfo/DealershipCard";
import { Text, Image } from "@sitecore-jss/sitecore-jss-vue";
import { facetMetaData } from "@util/facetHelper.js";

export default {
  name: "ZipLocation",
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    ZipLocationEdit,
    ZipNearestStoresDisplay,
    DealershipCard,
    ScText: Text,
    ScImage: Image
},

  data() {
    return {};
  },

  computed: {
    ...mapState("srp", ["zip", "dealerships", "radius", "nearestDealership"]),
    zipCode: {
      get() {
        return this.zip;
      },

      set(value) {        
        this.setZip(value);
        this.$root.$emit("dealership-list-fetch-srp");
      },
    },
  },

  methods: {
    ...mapMutations("srp", [
      "setZip",
      "setRadius",
    ]),
    toggleFilterMenu() {
      this.$root.$emit("toggle-filter-menu");
    },
  },
};

</script>
  <style scoped>
  .zip-location-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 24px 32px 24px;
  }
</style>
