<template>
  <select 
     v-model="value"
      @change="(event) => $emit('input', event.target.value)" 
      :type="inputType" 
      ref="selectItem"
      data-role="drop-down-list">
    <option :selected="option.value == value" v-for="option in options" :key="option.value" :value="(option.value)" :data-action-change="option.value" >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    options: {
      type: Array, // Array of objects with value and label keys
      required: true
    },
    inputType: {
      type: String,
      default: 'number'
      },
    value: {}
  }, 
  data() {
    return {}
  },
  computed: {},
  methods: {},
  emits: ['input']
};
</script>
  
<style scoped>
  select {
    padding: 0px 8px;
    height: 40px;
    border: 1px solid #D4D7D9;
    border-radius: 8px;
  }
</style>