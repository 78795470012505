<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.02661 7.523L9.08711 6.4625L5.37461 2.75L1.66211 6.4625L2.72336 7.523L4.62461 5.621V15.5H6.12461V5.621L8.02661 7.523ZM12.8746 16.25L16.5871 12.5375L15.5266 11.477L13.6246 13.379V3.5H12.1246L12.1254 13.379L10.2226 11.477L9.16211 12.5375L12.8746 16.25Z" fill="#242C33"/>
    </svg>
</template>


<script>
export default {
  name: "SortIcon"
};
</script>