<template>
    <div class="srp-content-container">
    </div>
</template>

<script>

import { Placeholder } from "@sitecore-jss/sitecore-jss-vue";

export default {
    data() {
        return {};
    },
    name: "SrpContent",
    components: {
        Placeholder
    },
};
</script>

<style scoped>
.srp-content-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
}

.test-class {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid var(--park-gray-90);
    background: var(--base-white, #FFF);
    min-height: 52px;
    border-right: 1px solid var(--park-gray-90);
    cursor: pointer;
    position: sticky;
    top: 0;
}

@media only screen and (min-width: 1240px) {
    .test-class {
        display: none;
    }
}
</style>
