<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 6V8H21.5V6H3.5ZM10.5 18H14.5V16H10.5V18ZM18.5 13H6.5V11H18.5V13Z" fill="#242C33"/>
    </svg>
</template>

<script>
export default {
  name: "FilterIconMedium"
};
</script>