<template>
  <transition name="slide">
    <div>
      <div v-show="!mileageFilterSelected && !filterSelected" data-role="facetfilter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="this.facetFilters[filterType] ? true : false"
          :title="$t('Mileage Filter Title')"
        />
      </div>
      <div v-show="mileageFilterSelected">
        <span id="lblTitle"
          ><b>{{ title }}</b></span
        >
        <div class="mileageDropdown" data-action-set="mileage-value">
          <Dropdown
            :options="mileOptions"
            @input="handleOptionSelected"
            v-model="selected"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Dropdown from "@components-vue/Select/Dropdown.vue";
import FilterMenuLineItem from '../FilterMenuLineItem.vue';
import { facetMetaData } from "@util/facetHelper.js";

export default {
  name: "MileageFilter",
  props: ["filterName", "fields"],
  components: {
    Dropdown,
    FilterMenuLineItem,
  },
  data() {
    return {
      title: this.$t("Mileage Filter Title"),
      optionText: this.$t("Mileage Dropdown Text"),
      mileOptions: [],
      selected: "200000",
      filterType: facetMetaData.miles.key,
    };
  },
  computed: {
    ...mapState("srp", {
      facetFilters: (state) => state.facetFilters ?? {},
      filterSelected: (state) => state.filterSelected,
      mileageFilterSelected: (state) => state.mileageFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      vehicleMileMax: (state) => state.srpVehiclesData?.facets?.miles?.max,
    }),
  },
  created() {
    this.getOptions();
  },
  methods: {
    ...mapMutations("srp", [
      "setFilterSelected",
      "setMileageFilterSelected",
      "setResetSelectedFacetItems",
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setMileageFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    getOptions() {
      let nearestUpperValue =
        Math.ceil((this.vehicleMileMax ?? 200000) / 10000) * 10000;
      this.mileOptions = [];

      for (let i = nearestUpperValue; i >= 1000; i -= 10000) {
        let value = i.toString();
        let stringValue = i.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let label = stringValue + " " + this.optionText;
        this.mileOptions.push({ value: value, label: label });
      }
      this.selected = nearestUpperValue;
    },
    updateStore(data) {
      this.$store.commit("srp/setNumericFacetFilter", data);
      this.$root.$emit("filter-updated-srp");
    },
    handleOptionSelected(value) {
      let maxInt = parseInt(value);
      this.selected = maxInt;
      const data = {
        name: this.filterType,
        value: { min: 0, max: maxInt },
      };
      this.updateStore(data);
    },
  },
  watch: {
    facetFilters: {
      handler: async function (newValue) {
        if (!newValue.hasOwnProperty(this.filterType)) {
          this.selected =
            Math.ceil((this.vehicleMileMax ?? 200000) / 10000) * 10000;
          this.setUpdateStorePrice = false;
        } else {
          this.selected = parseInt(this.facetFilters[this.filterType].max);
        }
      },
      deep: true,
    },
    vehicleMileMax: "getOptions",
  },
};
</script>

<style scoped>
#lblTitle {
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  height: auto;
  color: #242c33;
  padding: 16px 24px;
}
.mileageDropdown {
  padding: 0 24px 16px;
}
</style>
