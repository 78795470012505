<template>
  <div class="cta-banner d-flex" :class="{ 'cta-banner-grid': isGrid }">
    <div :class="[{ 'cta-banner-img': !isGrid }, { 'cta-banner-img-grid': isGrid }]">
      <sc-image :media="fields.Image" />
    </div>
    <div :class="[{ 'cta-banner-container d-flex': !isGrid }, { 'cta-banner-container-grid': isGrid }]">
      <div :class="[{ 'cta-banner-info': !isGrid }, { 'cta-banner-info-grid': isGrid }]">
        <h3><sc-text :field="fields.Heading" /></h3>
        <div :class="[{ 'cta-banner-body': !isGrid }, { 'cta-banner-body-grid': isGrid }]">
          <sc-rich-text :field="fields.Body" /></div>
      </div>
      <div :class="[{ 'cta-banner-btn': !isGrid }, { 'cta-banner-btn-grid': isGrid }]">
        <sc-link v-if=!isGrid class="btn btn-secondaryDark" :field="fields.Link" />
        <sc-link v-if=isGrid class="cta-link-grid" :field="fields.Link" />
        <img v-if=isGrid  class="cta-link-grid arrow-icon-grid"
          src="/dist/echoparkjss/img/Path_filtermain.697addc4.svg" alt="Right arrow icon" 
          data-v-21c192a7="">
      </div>
    </div> 
  </div>
</template>
  
<script>
  import {
    Image,
    RichText,
    Text,
    Link
  } from '@sitecore-jss/sitecore-jss-vue';

  export default {
    name: "CtaBanner",
    props: {
    fields: {
        type: Object,
        default: () => ({}),
      },
      isGrid: {
        type: Boolean,
        default: false,
      },
    },
    components: {
        ScImage: Image,
        ScRichText: RichText,
        ScText: Text,
        ScLink: Link
    }
  };
</script>
  
<style scoped>
.arrow-icon-grid {
  filter: brightness(0) saturate(100%) invert(18%) sepia(91%) saturate(3047%)
    hue-rotate(184deg) brightness(99%) contrast(102%);;
  height: 10px;
}

.cta-banner {
  margin: 24px;
  border: 1px solid var(--park-gray-90);
  border-radius: 16px;
  background-color: #FFFFFF;
}

.cta-banner-grid {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
}

.cta-banner-img {
  margin: 10px 20px 10px 10px;
}

.cta-banner-img-grid {
  margin: 0 auto; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-banner-img img {
  height: 100px;
  width: 100px
}

.cta-banner-img-grid img {
  display: block;
  height: 100px;
  width: 100px;
}

.cta-banner-container {
  padding: 16px 24px 16px 0px;
  justify-content: space-between;
  width: 100%;
}

.cta-banner-container-grid {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start;
  width: 100%;
}

.cta-banner-info {
  max-width: 500px;
  margin-right: 10px;
}

.cta-banner-container h3,
.cta-banner-container-grid h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--park-gray-10);
    margin: 0;
}

.cta-banner-info-grid {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  text-align: center;
  width: 100%;
}

.cta-banner-body {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--park-gray-10);
}

.cta-banner-body-grid {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  padding-top: 10px; 
  padding-left:30px;
  padding-right:30px;
}

.cta-banner-btn {
  margin-left: auto;
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-banner-btn-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; 
  width: 100%;
}

.cta-banner-btn .btn {
  border-width: 2px;
  border-style: solid;
}

.cta-banner-btn .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.cta-link-grid {
  color: #006fa6;
  padding-right:10px;
  font-weight: 600;
  font-size: 14px;
}

.cta-banner-btn .btn-secondaryDark, .cta-banner-btn .btn-secondaryDark:hover {
  background-color: transparent;
  color: var(--park-gray-0);
  border-color: var(--park-gray-10);
}

@media only screen and (max-width: 625px) {
  .cta-banner-container {
    flex-direction: column;
  }

  .cta-banner-btn {
    margin: 10px auto;
  }
}

</style>
  