<template>
  <transition name="slide">
    <div data-component-name="make-model-trim">
      <div
        v-show="!makeModelTrimFilterSelected && !filterSelected"
        data-role="facet-filter"
      >
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="this.facetFilters[facetMetaData.mmt.key] ? true : false"
          :title="$t('Make/Model/Trim')"
        />
      </div>
      <div v-show="makeModelTrimFilterSelected" class="mmt-body">
        <div class="row m-0 py-3">
          <div v-if="!searchPhrase.length" class="col-7 px-4 d-flex crumb-container">
            <div
              id="makeTitle"
              :class="!makeSelected ? 'active-crumb' : ''"
              @click="selectMake()"
              :data-for="makeTitle"
            >
              {{ makeTitle }}
            </div>
            <div id="modelTitle" v-if="modelSelected || trimSelected" class="d-flex">
              <div class="px-1">/</div>
              <div
                :class="!modelSelected ? 'active-crumb ' : ''"
                @click="selectModel()"
                :data-for="modelTitle"
              >
                {{ modelTitle }}
              </div>
            </div>
            <div id="trimTitle" v-if="trimSelected" class="d-flex">
              <div class="px-1">/</div>
              <div
                :class="!trimSelected ? 'active-crumb' : ''"
                @click="selectTrim()"
                :data-for="trimTitle"
              >
                {{ trimTitle }}
              </div>
            </div>
          </div>
          <div v-else class="col-12 px-4 d-flex">
            {{ searchTitle }}
          </div>
        </div>

        <div class="mmt-search">
          <input
            class="search-input"
            v-model="searchPhrase"
            placeholder="Search make or model"
            @keyup="triggerDelayedSearch"
            type="text"
          />
          <label v-if="searchPhrase.length < 2">
            <img src="@icons/search.png" class="" />
          </label>
          <label
            v-else
            class="pointer"
            @click="resetSearchPhrase()"
            @keyup.enter="resetSearchPhrase()"
            tabindex="0"
          >
            <img src="@icons/close.png" class="" />
          </label>
        </div>

        <transition name="slide">
          <div id="makeList" v-show="makeSelected" class="mt-3 border-top">
            <HierarchicalFacetList
              class="facet-list"
              v-model="makesModel"
              :data="makes"
              v-on:select="loadModels($event)"
              :sub-menu="'Model'"
              ref="makesRef"
            />
          </div>
        </transition>

        <transition name="slide">
          <div id="modelList" v-show="modelSelected" class="mt-3 border-top">
            <HierarchicalFacetList
              class="facet-list"
              v-model="modelsModel"
              :data="models"
              v-on:select="loadTrims($event)"
              :sub-menu="'Trim'"
              ref="modelsRef"
            />
          </div>
        </transition>

        <transition name="slide">
          <div id="trimList" v-show="trimSelected" class="mt-3 border-top">
            <HierarchicalFacetList
              class="facet-list"
              v-model="trimsModel"
              :data="trims"
              ref="trimsRef"
            />
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import config from "@/appConfig.js";
import HierarchicalFacetList from "@components-vue/List/HierarchicalFacetList.vue";
import FilterMenuLineItem from "../FilterMenuLineItem.vue";
import { facetMetaData } from "@util/facetHelper.js";

export default {
  name: "MakeModelTrimFilter",
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    HierarchicalFacetList,
    FilterMenuLineItem,
  },
  data() {
    return {
      makes: [],
      models: [],
      trims: [],
      mmtFacet: {},
      makeSelected: true,
      modelSelected: false,
      trimSelected: false,
      initialVehicleData: null,
      expandedMake: null,
      expandedModel: null,
      searchPhrase: "",
      timeout: null,
      mmtInteraction: false,
      searchInteraction: false,
      facetMetaData: facetMetaData,
      cachedCount: {},
      updateCachedCount: true,
    };
  },
  watch: {
    recentSelectedFilter(newValue, oldValue) {
      if (newValue === facetMetaData.mmt.key && oldValue !== facetMetaData.mmt.key)
        this.updateCachedCount = false;
      else this.updateCachedCount = true;
    },
    mmtCount() {
      this.cachedCount = this.mmtCount;
      this.initialize(this.facetFilters);
    },
    async makeModelTrimFilterSelected(newValue, oldValue) {
      if (newValue) {
        this.$root.$emit("fetch-facet-count", facetMetaData.mmt.key);
        this.selectMake();
        await this.initialize(this.facetFilters);
        this.searchPhrase = "";
      }
    },
    facetFilters: {
      handler: async function (newValue, oldValue) {
        if (this.updateCachedCount && this.makeModelTrimFilterSelected) {
          this.$root.$emit("fetch-facet-count", facetMetaData.mmt.key);
          this.cachedCount = this.mmtCount;
        }

        if (!this.mmtInteraction && !this.searchInteraction) {
          this.selectMake();
          await this.initialize(newValue);
          this.searchPhrase = "";
        }

        this.mmtInteraction = false;
        this.searchInteraction = false;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("srp", {
      facetFilters: (state) => state.facetFilters ?? {},
      filterSelected: (state) => state.filterSelected,
      makeModelTrimFilterSelected: (state) => state.makeModelTrimFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      mmtFacetData: (state) => state.facetCounts?.mmt,
      mmtCount: (state) => state.facetCounts?.mmt || {},
      recentSelectedFilter: (state) => state.recentSelectedFilter,
    }),
    initialMmtFacet: {
      get() {
        return (
          this.mmtFacetData ??
          this.initialVehicleData?.facets[facetMetaData.mmt.key] ??
          {}
        );
      },
    },
    makesModel: {
      get() {
        return this.makes;
      },
      set(data) {
        if (data.internalUpdate || this.searchPhrase) this.mmtInteraction = true;

        if (!data.internalUpdate)
          this.mmtFacet = this.facetFilters[facetMetaData.mmt.key]?.facetValue ?? {};
        else this.updateMmtFacet(data.items, this.mmtFacet);

        if (data.internalUpdate) {
          this.updateStore();
          let selectedMmt = this.facetFilters[facetMetaData.mmt.key].facetValue;
          this.parseSelectedMakeModels(selectedMmt);
        }
      },
    },
    modelsModel: {
      get() {
        return this.models;
      },
      set(data) {
        let expandedMake = data.items.map((x) => x.split("|")[0])[0] ?? this.expandedMake;
        let selectedItems = data.items.map((x) => x.split("|")[1]);

        if (!this.mmtFacet[expandedMake]) {
          this.expandedModel = expandedMake;
          let make = Object.keys(this.mmtFacet).find((x) =>
            this.mmtFacet[x].hasOwnProperty(this.expandedModel)
          );
          this.expandedMake = make;
        } else this.expandedMake = expandedMake;

        if (this.expandedModel && this.expandedMake) {
          this.updateMmtFacet(
            selectedItems,
            this.mmtFacet[this.expandedMake][this.expandedModel]
          );
        } else {
          this.updateMmtFacet(selectedItems, this.mmtFacet[this.expandedMake]);
        }
        if (data.internalUpdate) {
          this.updateStore();
          this.mmtInteraction = true;
        }
      },
    },
    trimsModel: {
      get() {
        return this.trims;
      },
      set(data) {
        this.mmtInteraction = true;
        let selectedItems = data.items.map((x) => x.split("|")[1]);
        this.updateMmtFacet(
          selectedItems,
          this.mmtFacet[this.expandedMake][this.expandedModel]
        );
        if (data.internalUpdate) {
          this.updateStore();
        }
      },
    },
    makeTitle: {
      get() {
        return this.$t("Make");
      },
    },
    modelTitle: {
      get() {
        return this.$t("Model");
      },
    },
    trimTitle: {
      get() {
        return this.$t("Trim");
      },
    },
    searchTitle: {
      get() {
        return "Searching make/model";
      },
    },
    getMakeCount() {
      return (key) => {
        let cachedCount = 0;
        if (Object.keys(this.cachedCount).length !== 0) {
          if (this.cachedCount.hasOwnProperty(key)) {
            Object.values(this.cachedCount[key]).forEach((x) => {
              if (x.__count) {
                cachedCount += x.__count;
              }
            });
          }
        }
        return cachedCount;
      };
    },
    getModelCount() {
      return (parent, make, key) => {
        let count = 0;
        if (Object.keys(this.cachedCount).length !== 0) {
          const makes =
            parent == null
              ? this.cachedCount && this.cachedCount[make]
              : this.cachedCount &&
                this.cachedCount[parent] &&
                this.cachedCount[parent][make];
          count = makes && makes.hasOwnProperty(key) ? makes[key].__count : 0;
        }

        return count;
      };
    },
    getTrimCount() {
      return (expandedMake, model, key) => {
        let count = 0;
        if (
          Object.keys(this.cachedCount).length !== 0 &&
          this.cachedCount &&
          this.cachedCount[expandedMake] &&
          this.cachedCount[expandedMake][model] &&
          this.cachedCount[expandedMake][model][key]
        ) {
          count = this.cachedCount[expandedMake][model][key].__count;
        }
        return count;
      };
    },
  },
  async created() {
    await this.initialize({});
  },
  methods: {
    ...mapMutations("srp", [
      "setFilterSelected",
      "setMakeModelTrimFilterSelected",
      "setResetSelectedFacetItems",
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setMakeModelTrimFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    async initialize(facetFilters) {
      if (!this.initialMmtFacet) {
        this.initialVehicleData = this.vehicleData;
      }
      if (
        facetFilters[facetMetaData.mmt.key] &&
        facetFilters[facetMetaData.mmt.key]?.facetValue
      ) {
        let selectedMmt = facetFilters[facetMetaData.mmt.key].facetValue;
        this.parseSelectedMakeModels(selectedMmt);

        this.makes = Object.keys(this.initialMmtFacet)
          .filter((x) => !this.containsNumbers(x))
          .map((key) => {
            let count = 0;
            Object.values(this.initialMmtFacet[key]).forEach((x) => {
              if (x.__count) {
                count += x.__count;
              }
            });
            let isSelected = false;
            if (selectedMmt[key] && selectedMmt[key].selected) isSelected = true;
            return {
              name: key,
              fullName: key,
              value: this.getMakeCount(key),
              selected: isSelected,
            };
          });
      } else {
        if (!this.initialMmtFacet) {
          await axios
            .get(config.vehicleApiEndpoint, {
              params: {},
            })
            .then((response) => {
              this.initialVehicleData = response.data;
            })
            .catch((error) => {
              console.error(error);
            });
        }

        this.makes = Object.keys(this.initialMmtFacet)
          .filter((x) => !this.containsNumbers(x))
          .map((key) => {
            return {
              name: key,
              fullName: key,
              value: this.getMakeCount(key),
              selected: false,
            };
          });
      }
    },
    parseSelectedMakeModels(mmtFacetValue) {
      Object.keys(mmtFacetValue).forEach((x) => {
        if (x.includes("|")) {
          let make = x.split("|")[0];
          let model = x.split("|")[1];
          if (!Object.keys(mmtFacetValue).includes(make)) {
            this.$set(mmtFacetValue, make, { selected: true });
          }
          if (!Object.keys(mmtFacetValue[make]).includes(model)) {
            this.$set(mmtFacetValue[make], model, { selected: true });
            this.$delete(mmtFacetValue, x);
          }
        }
      });
    },
    updateStore() {
      const data = {
        name: facetMetaData.mmt.key,
        value: this.mmtFacet,
      };
      this.$store.commit("srp/setHierarchicalFacetFilter", data);
      this.$root.$emit("filter-updated-srp");
    },
    updateMmtFacet(selectedItems, target) {
      selectedItems.forEach((item) => {
        if (!target[item] || !target[item].selected) {
          this.$set(target, item, { selected: true });
        }
      });

      for (let key in target) {
        if (key !== "selected" && !selectedItems.includes(key)) {
          delete target[key];
        }
      }
    },
    async loadModels(make) {
      if (this.searchPhrase) {
        this.searchInteraction = true;
        this.mmtInteraction = true;
      }

      let selectedMmt = this.facetFilters[facetMetaData.mmt.key].facetValue;

      let parent = null;
      if (!Object.keys(this.initialMmtFacet).includes(make)) {
        Object.keys(this.initialMmtFacet).forEach((key) => {
          if (Object.keys(this.initialMmtFacet[key]).includes(make)) {
            parent = key;
            return;
          }
        });
      }

      const initialCollection =
        parent == null ? this.initialMmtFacet[make] : this.initialMmtFacet[parent][make];
      this.models = Object.keys(initialCollection)
        .filter((x) => x !== "selected" && x !== "__count")
        .map((key) => {
          let isSelected = false;
          if (!parent) {
            if (selectedMmt[make][key] && selectedMmt[make][key].selected)
              isSelected = true;
          } else {
            if (
              selectedMmt[parent] &&
              selectedMmt[parent][make] &&
              selectedMmt[parent][make][key] &&
              selectedMmt[parent][make][key].selected
            )
              isSelected = true;
          }

          return {
            name: key,
            fullName: `${make}|${key}`,
            value: this.getModelCount(parent, make, key),
            selected: isSelected,
            lastChild: parent != null,
          };
        });

      this.selectModel();
      this.expandedMake = make;
    },
    async loadTrims(model) {
      let selectedMmt = this.facetFilters[facetMetaData.mmt.key].facetValue;
      this.trims = Object.keys(this.initialMmtFacet[this.expandedMake][model])
        .filter((x) => x !== "selected" && x !== "__count")
        .map((key) => {
          let isSelected = false;
          if (
            selectedMmt[this.expandedMake][model][key] &&
            selectedMmt[this.expandedMake][model][key].selected
          )
            isSelected = true;

          return {
            name: key,
            fullName: `${model}|${key}`,
            value: this.getTrimCount(this.expandedMake, model, key),
            selected: isSelected,
          };
        });

      this.selectTrim();
      this.expandedModel = model;
    },
    selectMake() {
      this.makeSelected = true;
      this.modelSelected = false;
      this.trimSelected = false;
      this.expandedMake = null;
    },
    selectModel() {
      this.modelSelected = true;
      this.makeSelected = false;
      this.trimSelected = false;
      this.expandedModel = null;
    },
    selectTrim() {
      this.trimSelected = true;
      this.makeSelected = false;
      this.modelSelected = false;
    },
    containsNumbers(inputString) {
      const regex = /\d/;
      return regex.test(inputString);
    },
    triggerDelayedSearch(e) {
      let that = this;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(function () {
        that.selectMake();
        that.searchMakeModelTrim(e);
      }, 300);
    },
    searchMakeModelTrim(event) {
      const input = event.target.value.toLowerCase();

      const processFacet = (facet) => {
        const newMakesArray = [];

        Object.keys(this.initialMmtFacet)
          .filter((x) => !this.containsNumbers(x))
          .forEach((key) => {
            const makeCount = this.getCount(this.initialMmtFacet[key]);

            const isMakeSelected = facet[key] && facet[key].selected;
            const hideMake = !key
              .split(" ")
              .some((y) => y.toLowerCase().startsWith(input));

            let makeAdded = 0;
            if (!hideMake) {
              newMakesArray.push({
                name: key,
                fullName: key,
                value: makeCount,
                selected: isMakeSelected,
                hidden: false,
              });
            } else {
              newMakesArray.push({
                name: key,
                fullName: key,
                value: makeCount,
                selected: isMakeSelected,
                hidden: true,
              });
            }

            Object.keys(this.initialMmtFacet[key])
              .filter((x) => x !== "__count")
              .forEach((subKey) => {
                const modelCount = this.getCount(this.initialMmtFacet[key][subKey]);
                const isModelSelected =
                  facet[key] && facet[key][subKey] && facet[key][subKey].selected;
                const hideModel = !subKey
                  .split(" ")
                  .some((y) => y.toLowerCase().startsWith(input));

                if (!hideModel) {
                  if (makeAdded === 0) {
                    let parentMake = newMakesArray.find((x) => x.fullName === key);
                    if (parentMake) {
                      parentMake.hidden = false;
                      this.$set(parentMake, "parent", true);
                    }
                    makeAdded++;
                  }

                  newMakesArray.push({
                    name: subKey,
                    fullName: `${key}|${subKey}`,
                    value: modelCount,
                    selected: isModelSelected,
                    hidden: false,
                    subMenu: "Trim",
                    descendant: true,
                  });
                }
              });
          });

        this.makes = newMakesArray;
      };

      if (input.length > 1) {
        const selectedMmt =
          this.facetFilters[facetMetaData.mmt.key] &&
          this.facetFilters[facetMetaData.mmt.key].facetValue;
        selectedMmt ? processFacet(selectedMmt) : processFacet({});
      } else {
        this.searchInteraction = false;
        this.mmtInteraction = false;
        this.initialize(this.facetFilters);
        this.selectMake();
      }
    },
    getCount(obj) {
      let count = 0;

      Object.values(obj).forEach((x) => {
        if (x.__count) {
          count += x.__count;
        }
      });

      return count;
    },
    resetSearchPhrase() {
      this.searchPhrase = "";
      this.searchInteraction = false;
      this.mmtInteraction = false;
      this.initialize(this.facetFilters);
      this.selectMake();
    },
    resetVisibility(items) {
      items.forEach((x) => {
        this.$set(x, "hidden", false);
      });
    },
  },
};
</script>

<style scoped>
.selected-filter {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.active-crumb {
  color: #006fa6;
  cursor: pointer;
}

.active-crumb:hover {
  text-decoration: underline;
}

.crumb-container {
  cursor: default;
}

.mmt-search {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-top: 1px solid #e8e9eb;
  border-bottom: 1px solid #e8e9eb;
  background: #f4f5f7;
}

.search-input {
  flex: 1 0 0;
  color: #666b70;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  background: #f4f5f7;
}

.facet-list {
  width: 100%;
  max-height: calc(100vh - 215px);
}

.list-item {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.mmt-body {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 100%;
}

/* slide-* styling for vue transition animation */
.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transition: all 0.3s ease-in;
}

.slide-leave-active {
  transition: all 0.1s ease-in;
}

.slide-leave-to {
  transform: translateX(-100%);
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 8px;
  border: 1px solid #ffffff;
}

::placeholder {
  color: #666b70;
  font-feature-settings: "clig" off, "liga" off;
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #666b70;
  font-feature-settings: "clig" off, "liga" off;
}
</style>
