import https from 'https';
import * as facetManager from "@util/facetFiltersFactory.js";

const VehicleSearchClient = function (axios, baseUrl) {
    const __baseUrl = baseUrl;
    const __searchParameters = {};
    const __searchFacets = facetManager.FacetFiltersManager();

    const addParameter = function (name, value) {
        __searchParameters[name] = value;
    }

    const addFacetFilter = function (name, value) {
        const facetFilter = __searchFacets.createFacetFilter(name, value);
        __searchFacets.addFacetFilter(facetFilter);
    }

    /**
     * @param {number} zip
     */
    const addZip = function (zip) {
        addParameter('zip', zip);
    }

    /**
     * @param {number} radius
     */
    const addRadius = function (radius) {
        if (radius > 0) {
            addParameter('radius', radius);
        }
    }

    /**
     * @param [] dealers
     */
    const addDealers = function (dealers) {
        addParameter('dealer', dealers);
    }

    /**
     * @param {number} pageTake
     */
    const setPageSize = function (pageTake) {
        addParameter('take', pageTake);
    }

    /**
     * @param {number} pageNumber
     */
    const setPageNumber = function (pageNumber) {
        addParameter('page', pageNumber);
    }

    /**
     * @param {boolean} shippable - False when value = 0.
     */
    const setShippable = function (shippable) {
        if(shippable){
            addParameter('ship', shippable);
        }
    }

    /**
     * @param {number} storeId
     */
    const setDealershipIdAsLocation = function (storeId) {
        addParameter('storeloc', storeId);
    }

    /**
     * @param {string} dealershipName
     */
    const setDealershipNameAsLocation = function (dealershipName) {
        addParameter('dealerloc', dealershipName);
    }

    /**
     * @param {string} sortBy
     */
    const setSortBy = function (sortBy) {
        addParameter('sortby', sortBy);
    }

    /**
     * @param {string} sortDirection
     */
    const setSortDirection = function (sortDirection) {
        addParameter('sortdir', sortDirection);
    }

    const execute = function () {
        for (const key in __searchParameters) {
            if (Array.isArray(__searchParameters[key])) {
                __searchParameters[key] = __searchParameters[key].join('|');
            }
        }

        return axios.get(__baseUrl, {
            params: {
                ...__searchParameters,
                facets: __searchFacets.toQueryString()
            },
            httpsAgent: new https.Agent({
                rejectUnauthorized: false
              })
        });
    }

    return {
        execute,
        addRadius,
        addZip,
        addDealers,
        setPageSize,
        setPageNumber,
        setShippable,
        setDealershipIdAsLocation,
        setDealershipNameAsLocation,
        addFacetFilter,
        setSortBy,
        setSortDirection
    };
};

export default VehicleSearchClient;



