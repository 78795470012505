<template>
  <div class="zipchange-header" data-component-name="zip-location-edit">
    <div class="ziplocation-header-top">
      <template v-if="showClose">
        <span class="zipLocation_flyout_close">
          <img src="@icons/icons-menu-close.svg" alt="Close" @click="handleClose()" />
        </span>
      </template>
      <div class="location-info-text mb-2">
        <sc-text :field="fields.titleFlyout" />
        <sc-image class="icon-location-header-flyout" :media="fields.iconLocationFlyout" />
        <div class="zip-styling">{{ value }}</div>
        <div class="small-info-text">
          <sc-text :field="fields.descriptionFlyout" />
        </div>
      </div>
    </div>

    <div class="zipInput-wrapper">
      <input
        v-model="currentZip"
        :placeholder="$t('Zip')"
        autocomplete="postal-code"
        @keyup.enter="changeZip"
        @input="handleInput"
        maxlength="5"
        data-for="new-zip-code"
      />
      <button type="button" class="change-zip-button" role="button" @click="changeZip()" data-action="commit-zip-code-change">
        {{ $t('Change') }}
      </button>
    </div>

    <div class="zip-flyout-validation">
      <span class="field-validation-error" v-if="zipValidationFailed">
        {{ $t('SRP2.ValidationMessages.ValidZipCode') }}
      </span>
      <span class="field-validation-success" v-if="zipUpdated">
        {{ $t('SRP2.ValidationMessages.ChangeZipSuccessMessage') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import zipService from '@services/zipService.js';

export default {
  name: 'ZipLocationEdit',

  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    value: { type: String },
    showClose: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      zipValidationFailed: false,
      zipUpdated: false,
      currentZip: '',
    };
  },

  components: {
    ScText: Text,
    ScImage: Image,
  },

  methods: {
    ...mapMutations('common', ['setZipFlyout']),
    ...mapMutations('srp', ['setFilterZipFlyout']),
    async changeZip() {
      const isValid = await zipService.isValidZip(this.currentZip);
      this.zipValidationFailed = !isValid;
      if (isValid) {
        this.$emit('input', this.currentZip); // Emit the zip code if it's valid
        this.currentZip = '';
        this.zipUpdated = true;
      } else {
        this.zipUpdated = false;
      }
    },
    handleInput(event) {
      this.currentZip = event.target.value.replace(/\D/g, '');
    },
    handleClose() {
      this.setZipFlyout(false);
      this.resetInput();
    },
    resetInput() {
      this.zipUpdated = false;
      this.zipValidationFailed = false;
      this.currentZip = '';
    },
  },
};
</script>

<style scoped>
.icon-location-header-flyout {
  height: 16px;
}

.zipchange-header {
  width: 100%;
  display: inline-block;
  color: var(--park-gray-0);
  font-feature-settings: 'clig' off, 'liga' off;
  /* Paragraph / Body LG Light */
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}

.ziplocation-header-top {
  display: flex;
  padding-bottom: 8px;
}

.zip-styling {
  display: inline-block;
  color: var(--park-gray-0);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: 4px;
}

.zipLocation_flyout_close {
  min-width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 24px;
}

.location-info-text {
  display: inline-block;
  color: var(--park-gray-0);
  font-feature-settings: 'clig' off, 'liga' off;
  /* Paragraph / Body LG Light */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}

.small-info-text {
  width: 241px;
  color: var(--park-gray-0);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.5px; /* 170.833% */
}

.zipInput-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 4px;
  background-color: var(--base-white-100);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--park-gray-20);
}

.zipInput-wrapper input {
  padding: 4px 150px 4px 15px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--park-gray-20);
  width: 100%;
  height: 48px;
  background-color: transparent;
  border-radius: 28px;
  border: solid 1px var(--park-gray-80);
}

.change-zip-button {
  border-radius: 28px;
  background-color: var(--green-50);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: var(--base-white-100);
  padding: 12px 24px;
  width: 113.615px;
  height: 40px;
  position: absolute;
  right: 4px;
  top: 4px;
  border: 0;
}

.change-zip-button:hover {
  background-color: var(--green-40);
  color: var(--base-white-100);
  border-color: transparent;
}

.zip-flyout-validation {
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: 0.2px;
}

.field-validation-error {
  color: var(--supplementary-red-50);
}

.field-validation-success {
  color: var(--green-50);
}
</style>
