const API_URL = 'https://jsonplaceholder.typicode.com/posts';

export default {
    getPost(id) {
        return fetch(`${API_URL}/${id}`)
            .then(response => response.json())
            .catch(error => console.error(error));
    },
    createPost(data) {
        return fetch(API_URL, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .catch(error => console.error(error));
    }
}
