<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import { resolveUrl } from "@util/routeBuilder.js";
import {
  fetchSrpVehicles,
  fetchSrpDealerships,
  fetchFacetCounts,
  deepEqual,
  arraysEqualShallow,
} from "@util/searchControllerUtils.js";

export default {
  name: "SearchController",
  metaInfo() {
    if (!this.hasMounted) {
      this.$root.$emit("header-data-changed", {
        title: this.pageTitle,
        description: this.pageDescription,
      });
    }
    return { title: this.pageTitle };
  },
  props: [],
  data() {
    return {
      srpFacetFilters: null,
      hasMounted: false,
    };
  },
  computed: {
    ...mapState("srp", {
      shopByStore: (state) => state.shopByStore,
      shipToStore: (state) => state.shipToStore,
      shipToStoreRadius: (state) => state.shipToStoreRadius,
      zip: (state) => state.zip,
      radius: (state) => state.radius,
      page: (state) => state.queryParams?.page,
      queryParams: (state) => state.queryParams,
      take: (state) => state.take,
      includedDealerships: (state) => state.includedDealerships,
      maxRadius: (state) => state.maxRadius,
      includedBodyStyles: (state) => state.includedBodyStyles,
      facetFilters: (state) => state.facetFilters || {},
      sortType: (state) => state.sortType,
      facets: (state) => state.srpVehiclesData?.facets || {},
      storeId: (state) => state.storeId,
      urlCount: (state) => state.urlCount,
    }),
    ...mapGetters("srp", [
      "metaDataContent",
      "metaDataLocation",
      "vehicleCount",
    ]),
    urlProperties() {
      return {
        facetFilters: this.facetFilters,
        queryParams: this.queryParams,
        includedDealerships: this.includedDealerships,
        storeId: this.storeId,
        shipToStore: this.shipToStore,
        radius: this.radius,
        sortType: this.sortType,
      };
    },
    itemPath() {
      return this.$jss?.sitecoreContext()?.itemPath;
    },
    layoutPath() {
      return this.$jss?.sitecoreContext()?.layoutPath;
    },
    pageTitle() {
      const content = this.$jss?.routeData();
      const defaultResult =
        content?.fields?.pageTitle?.value ?? "Used cars | EchoPark ";
      const seoItem = 
        this.$jss?.store?.state?.sitecoreContext?.seoItem;
      if (
        seoItem && seoItem["Meta Title"]?.value !== undefined
      ) {
        return seoItem["Meta Title"].value;
      }
      if (this.metaDataContent && this.metaDataLocation) {
        return (
          this.$t("SrpMetaTitleWithLocation")
            .replace("##facets##", this.metaDataContent)
            .replace("##location##", this.metaDataLocation) ?? defaultResult
        );
      }
      if (this.metaDataContent) {
        return (
          this.$t("SrpMetaTitle").replace("##facets##", this.metaDataContent) ??
          defaultResult
        );
      }

      return defaultResult;
    },
    pageDescription() {
      const content = this.$jss?.routeData();
      const seoItem = 
        this.$jss?.store?.state?.sitecoreContext?.seoItem;
      if (
        seoItem && seoItem["Meta Description"]?.value !== undefined
      ) {
        return seoItem["Meta Description"].value;
      }
      if (this.metaDataContent && this.metaDataLocation) {
        return this.$t("SrpMetaDescriptionWithLocation")
          .replace("##facets##", this.metaDataContent)
          .replace("##location##", this.metaDataLocation);
      }
      if (this.metaDataContent) {
        return this.$t("SrpMetaDescription").replace(
          "##facets##",
          this.metaDataContent
        );
      }
      if (content?.fields?.["Meta Description"]?.value) {
        return content.fields["Meta Description"].value;
      }

      if (!this.metaDataContent) {
        return this.$t("SrpMetaDefaultDescription").replace(
          "##count##",
          this.vehicleCount
        );
      }

      return null;
    },
  },
  mounted() {
    const events = [
      "pagination-fetch-srp",
      "fetch-cars-srp",
      "filter-updated-srp",
      "dealership-list-fetch-srp",
      "sort-updated-srp",
      "fetch-facet-count",
      "route-update",
    ];
    events.forEach((event) => {
      this.$root.$on(
        event,
        async (...args) => await this.handleEvent(event, ...args)
      );
    });
    this.hasMounted = true;
    // set this to avoid watcher not triggering on page load with preset filters NP 7/1/2024
    const currentFacetFilters = this.facetFilters;
    this.setFacetFilters(currentFacetFilters);
  },
  methods: {
    ...mapMutations("srp", [
      "setStringFacetFilter",
      "setNumericFacetFilter",
      "setHierarchicalFacetFilter",
      "setPage",
      "setSrpVehiclesData",
      "setFacetCounts",
      "setDealerships",
      "setSrpNearestDealership",
      "setShipToStore",
      "setShipToStoreRadius",
      "setShopByStore",
      "resetStoreDefaults",
      "setClearFacets",
      "initializeZip",
      "setFacetFilters",
      "setIncludedDealerships",
      "initializeRadius",
      "setSortType",
      "setLoading",
      "decrementUrlCount",
      "setDefaultRadius",
    ]),
    resolveUrl() {
      const params = {
        facetFilters: this.facetFilters,
        queryParams: this.queryParams,
        includedDealerships: this.includedDealerships,
        ship: this.shipToStore,
        sortType: this.sortType,
        itemPath: this.itemPath,
        layoutPath: this.layoutPath,
        radius: this.radius,
        shopByStore: this.shopByStore,
        root: this.$root,
      };

      resolveUrl(this.$router, params);
    },
    applySitecoreContext(context) {
      let stateChanged = false;
      const contextZip = context?.location?.zip;
      const contextFacetFilters = context?.appliedFacetFilters?.facetFilters;
      const contextPage = context?.appliedFacetFilters?.page ?? 0;
      const contextShip = context?.appliedFacetFilters?.ship ?? true;
      const contextDealerships = context?.appliedFacetFilters?.dealers ?? [];
      const contextRadius = context?.appliedFacetFilters?.radius;
      const contextSort = context?.appliedFacetFilters?.sort ?? {
        sortBy: "distance",
        sortDirection: "asc",
        sortName: "Distance: Nearest",
      };

      const checkAndUpdate = (
        contextValue,
        currentStateValue,
        updateFunction
      ) => {
        if (contextValue !== undefined && contextValue !== currentStateValue) {
          stateChanged = true;
          updateFunction(contextValue);
        }
      };

      checkAndUpdate(contextZip, this.zip, this.initializeZip.bind(this));
      checkAndUpdate(contextPage, this.page, this.setPage.bind(this));
      checkAndUpdate(contextShip, this.shipToStore, (value) => {
        this.setShipToStore(value);
        this.setShipToStoreRadius(value);
      });
      checkAndUpdate(
        contextRadius,
        this.radius,
        this.initializeRadius.bind(this)
      );

      if (!deepEqual(contextSort, this.sortType)) {
        stateChanged = true;
        this.setSortType(contextSort);
      }

      if (!deepEqual(contextFacetFilters, this.facetFilters)) {
        stateChanged = true;
        this.setFacetFilters(contextFacetFilters);
      }

      if (!arraysEqualShallow(contextDealerships, this.includedDealerships)) {
        stateChanged = true;
        this.setIncludedDealerships(contextDealerships);

        if (contextDealerships.length === 0) {
          this.setShopByStore(false);
          if (this.shipToStoreRadius !== contextShip) {
            this.setShipToStore(this.shipToStoreRadius);
            this.setShipToStoreRadius(this.shipToStoreRadius);
          }
        }
      }

      return stateChanged;
    },
    async fetchInitialData() {
      try {
        await this.fetchSrpVehicles();
        await this.fetchSrpDealerships();
        await this.fetchFacetCounts();
      } catch (e) {
        console.error(e);
      }
    },
    async handleEvent(event, ...args) {
      switch (event) {
        case "fetch-cars-srp":
        case "filter-updated-srp":
        case "sort-updated-srp":
          if (typeof window !== "undefined") {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }
          this.setPage(0);
          await this.fetchSrpVehicles();
          break;
        case "pagination-fetch-srp":
          if (typeof window !== "undefined") {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }
          await this.fetchSrpVehicles();
          break;
        case "dealership-list-fetch-srp":
          await this.fetchSrpDealerships();
          this.$root.$emit("all-dealerships-list-updated");
          break;
        case "fetch-facet-count":
          const [facetToRemove] = args;
          await this.fetchFacetCounts(facetToRemove);
          break;
        case "route-update":
          const sitecoreContext = this.$jss?.store?.state?.sitecoreContext;

          if (this.urlCount > 1) {
            this.decrementUrlCount();
          } else if (this.urlCount === 1) {
            this.applySitecoreContext(sitecoreContext);
            this.decrementUrlCount();
          } else {
            let stateChanged = this.applySitecoreContext(sitecoreContext);
            if (stateChanged) {
              await this.fetchSrpVehicles();
            }
          }

          break;
      }
    },
    async fetchSrpVehicles() {
      await fetchSrpVehicles(this);
    },
    async fetchSrpDealerships() {
      await fetchSrpDealerships(this);
    },
    async fetchFacetCounts(facetToRemove) {
      await fetchFacetCounts(this, facetToRemove);
    },
  },
  watch: {
    urlProperties: {
      handler() {
        this.resolveUrl();
      },
      deep: true
    },
  },
};
</script>
