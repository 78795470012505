<template>
	<div class="vehicleCardPaper">
		<div class="container">
			<div class="row">
				<div class="placeholder wave">
					<div class="square"></div>
					<div class="line"></div>
					<div class="square-2"></div>
					<div class="line-2"></div>
					<div class="line-3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SkeletonLoading",
};
</script>

<style scoped>
.vehicleCardPaper {
	width: 100%;
	height: 460px;
	flex-shrink: 0;
	border-radius: 16px;
	border: solid 1px #e8e9eb;
	background-color: #fff;
	overflow: hidden;
	margin-bottom: 16px;
	min-width: 280px;
	padding: 16px;
	height: 500px;
}

.placeholder {
	border-radius: 5px;
}

.placeholder.wave {
	position: relative;
	width: 100%;
}

.placeholder.pulse div {
	animation: pulse 1s infinite ease-in-out;
	-webkit-animation: pulse 1s infinite ease-in-out;
}

.placeholder.wave div {
	animation: wave 1s infinite linear forwards;
	-webkit-animation: wave 1s infinite linear forwards;
	background: #f6f7f8;
	background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
	background-size: 800px 104px;
}

.placeholder div {
	background: #e8e8e8;
}

.square {
	width: 100%;
	height: 294px;
	box-sizing: border-box;
	border-radius: 16px 16px 0px 0px;
}

.rectangle {
	float: left;
	width: 87.458px;
	height: 40px;
	border-radius: 4px;
}

.line {
	max-width: 142.373px;
	height: 16px;
	margin: 32px 201.36px 8px 16.27px;
	border-radius: 4px;
	justify-content: left;
}

.line-2 {
	max-width: 113.898px;
	height: 16px;
	border-radius: 4px;
	margin: 8px 229.83px 0px 16.27px;
}

.line-3 {
	max-width: 197.288px;
	height: 16px;
	border-radius: 4px;
	margin: 46px 0px 0px 16.27px;
}

.square-2 {
	position: absolute;
	max-width: 100%;
	height: 40px;
	left: 256px;
	top: 326px;
	right: 16px;
	border-radius: 4px;
}

.circle {
	float: left;
	width: 40px;
	height: 40px;
	margin: 0 15px 10px 0;
	border-radius: 40px;
}

@keyframes pulse {
	0% {
		background-color: #DDDDDD
	}

	50% {
		background-color: #EEEEEE;
	}

	100% {
		background-color: #DDDDDD
	}
}

@-webkit-keyframes pulse {
	0% {
		background-color: #DDDDDD
	}

	50% {
		background-color: #EEEEEE;
	}

	100% {
		background-color: #DDDDDD
	}
}

@keyframes wave {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

@-webkit-keyframes wave {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}
</style>
