<template>
  <div id="root">
    <!-- 'router-view' is a "global" component that is injected into the Vue component registry by vue-router. -->
    <router-view />
  </div>
</template>

<script>

import Cookies from "js-cookie";

export default {
  name: "AppRoot",
  data() {
    return {
      languageIsChanging: false,
    };
  },
  methods: {
    changeAppLanguage(language) {
      // Changing languages is an async action, therefore the `languageIsChanging` property can be used
      // to show a loading/switching screen when language is being changed.
      const i18n = this.$i18n.i18next;
      if (i18n.language !== language) {
        this.languageIsChanging = true;

        i18n.changeLanguage(language, () => {
          this.languageIsChanging = false;
        });
      }
    },
    checkAudioEye() {
      var checkExist = setInterval(() => {
        var aeLauncher = document.getElementById("ae_launcher");
        if (aeLauncher) {
            var audioEyeIsEnabled = Cookies.get("audioEyeIsEnabled");
            if (audioEyeIsEnabled === "true") {
                aeLauncher.style.display = "block";
            } else {
                aeLauncher.remove();
            }

            clearInterval(checkExist);
        }
      }, 100);
    },
  },
  provide() {
    // Use Vue's provide/inject capabilities to "provide" functions to
    // any descendant component that want to use/"inject" the functions.
    return {
      changeAppLanguage: this.changeAppLanguage,
    };
  },
  mounted() {
    try {
      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute("src", "https://cdn.optimizely.com/js/20473601091.js");
      document.head.appendChild(recaptchaScript);
    } catch (error) {
      console.log(error);
    }

    if (document.readyState !== 'loading') {
      this.checkAudioEye();
    } else {
        document.addEventListener('DOMContentLoaded', this.checkAudioEye);
    }
  }
};
</script>
