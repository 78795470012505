<template>
  <div class="w-100">
    <span :class="shipToStoreContainer">
      <label>{{ $t("Ship to store") }}</label>
      <RoundCheckToggleSwitch
        v-bind:value="shipToStore"
        v-on:update="shipToStore = $event"
        :disabled="shopByStore"
        componentName="shipToStoreContainer"
      />
    </span>
    <span class="show-me-subtext">{{
      $t("Show me cars that can ship to a nearby store")
    }}</span>
  </div>
</template>

<script>
import { Store, mapState, mapMutations } from "vuex";
import RoundCheckToggleSwitch from "@components-vue/Toggle/RoundCheckToggleSwitch.vue";

export default {
  name: "ShipToStoreToggle",
  props: ["filterName", "fields"],
  data() {
    return {
      shipToStoreContainer: "d-flex justify-content-between",
    };
  },
  computed: {
    ...mapState("srp", {
      shopByStore: (state) => state.shopByStore,
    }),

    shipToStore: {
      get() {
        return this.$store.state.srp.shipToStore;
      },
      set(value) {
        this.setShipToStore(value);
        this.setShipToStoreRadius(value); // preserve state of ship to store on shop by radius when toggling to shop by store which defaults it to off
        this.$root.$emit("filter-updated-srp");
      },
    },
  },
  components: {
    RoundCheckToggleSwitch,
  },
  methods: {
    ...mapMutations("srp", ["setShipToStoreRadius", "setShipToStore"]),
  },
};
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 27px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 3px;
  bottom: 3.2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5c9c08;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  background-image: url(../../../../../assets/icons/toggleOn.svg);
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.show-me-subtext {
  color: #666b70;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
</style>
