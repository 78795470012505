<template>
  <button @click="$emit('click')" :id="id" tabindex="-1">
    <slot name="value">
      <span class="btnText" tabindex="0">{{ value }}</span>
    </slot>
    <span
      v-if="isCloseable === true"
      class="closeBtn"
      @click.stop="$emit('close')"
      @keyup.stop.enter="$emit('close')"
      tabindex="0"
    >
      &times;
    </span>
  </button>
</template>

<script>
export default {
  name: "FilterChipItem",
  props: {
    id: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    isCloseable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["click", "close"],
};
</script>

<style scoped>
.btnText {
  padding: 7px 0;
  margin: 7px 4px 7px 12px;
}

.btnText:active,
.btnText:focus,
.btnText:hover {
  text-decoration: underline;
}

.btnText:focus-visible {
  outline: none;
}

.closeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.closeBtn:active {
  background: #b2d4e1;
  border-color: #b2d4e1;
}

.closeBtn:focus,
.closeBtn:focus-visible {
  background: #c8e0e9;
  outline: 1px solid var(--supplementary-blue-80);
  outline-offset: -1px;
}

.closeBtn:hover {
  background: #c8e0e9;
  border-color: #c8e0e9;
}
</style>
